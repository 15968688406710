<template>
    <div>
        <div class="content">
            <div class="filter-wrapper" style="padding: 20px 0px 0px 20px">
                <input type="text" v-model="titleValue" placeholder="请输入政策标题关键字" class="el-input__inner" />
                <div class="global-search__btn" @click="search">
                    <i class="icon-search"></i>
                </div>
            </div>
            <div style="width: 90%;margin: 20px auto; text-align: right;" @click="dialogTableVisible = true">
                <div class="Subscription"><span>+</span>订阅</div>
            </div>
            <el-dialog title="订阅添加" :visible.sync="dialogTableVisible">
                <div class="filter-wrapper">
                    <div class="filter-item">
                        <div class="filter-item__label">政策订阅标签：</div>
                        <div class="filter-item__options" ref="type">
                            <div v-for="(item, index) in ServiceCategory" :key="index" :class="item.active"
                                @click="PolicyTypes(index)">
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="el-dialog__footer">
                    <el-button @click="dialogTableVisible = false">取消</el-button>
                    <el-button type="primary" style="background-color: #028DFB;color: #ffffff;"
                        @click="Subscription">确定</el-button>
                </div>
            </el-dialog>
            <div>
                <div class="content-details" v-if="PolicyDetails.length > 0">
                    <div>
                        <div class="zct-policy-item-comp" @click="ViewDetails(item.id)"
                            v-for="(item, index) in PolicyDetails" :key="index">
                            <div class="zct-policy-item-comp__title">
                                <div class="zct-policy-item-comp__title__level">
                                    【{{ item.rank }}】
                                </div>
                                <div class="zct-policy-item-comp__title__target">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div class="zct-policy-item-comp__line">
                                <i class="date-icon"></i>
                                <div>发布日期：</div>
                                <div class="zct-policy-item-comp__line__value">
                                    {{ item.releaseDate }}
                                </div>
                            </div>
                            <div class="zct-policy-item-comp__line">
                                <i class="unit-icon"></i>
                                <div>发文单位：</div>
                                <div class="zct-policy-item-comp__line__value">{{ item.unit }}</div>
                            </div>
                            <div class="zct-policy-item-comp__line">
                                <i class="el-icon-view"
                                    style=" width: 0.875rem;height: 0.9375rem;margin-right: 0.5rem;background-size: contain;"></i>
                                <div>浏览量：</div>
                                <div class="zct-policy-item-comp__line__value">{{ item.pv }}</div>
                            </div>
                        </div>
                    </div>
                    <div style="
          width: 900px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
                        <el-pagination background layout="prev, pager, next" :total="total"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                        <div>共{{ PolicyDetails.length }}条</div>
                    </div>
                </div>
                <div v-else style="width: 100%;text-align: center;line-height: 500px;color: #666666;">暂无数据，请先绑定政策标签</div>
            </div>
        </div>
        <siteFooter></siteFooter>
    </div>
</template>
<script>
import request from '@/api/request'
import siteFooter from "@/components/siteFooter";
export default {
    name: "policyinterpretation",
    components: {
        'siteFooter': siteFooter,
    },
    data() {
        return {
            titleValue: '',
            dialogTableVisible: false,
            ServiceCategory: [],
            policyOrdered: [],
            PolicyDetails: [],
            pageSize: 10,
            total: 0,
        }
    },
    async created() {
        await this.getOptions();
        await this.getPolicyList();
    },
    methods: {
        getOptions() {
            if (!localStorage.getItem("USERID")) {
                this.$message({
                    message: '还未登录，请先登录',
                    type: "error",
                    duration: 2000
                });
                setTimeout(() => {
                    this.$router.push('/login')
                }, 2000)
            }
            request.post("/api/Policy/GetSubscribe").then((res) => {
                if(res.data.Data&&res.data){
                    var options = [...res.data.Data.Allsubscribe];
                this.policyOrdered = res.data.Data.Mysubscribe.map((item) => {
                    return {
                        active: 'active',
                        id: item.Id,
                        name: item.Name
                    };
                })
                let arr = options.filter((x) => {
                    return !this.policyOrdered.find(prop => {
                        return x.Id == prop.id
                    })
                }).map((item) => {
                    return {
                        active: '',
                        id: item.Id,
                        name: item.Name
                    };
                });
                this.ServiceCategory = [
                    { active: "active", id: 0, name: "全选" },
                    ...arr,
                ].concat(this.policyOrdered);
                // console.log(this.policyOrdered);
                if (this.policyOrdered.length < options.length) {
                    this.ServiceCategory[0].active = "";
                } else {
                    this.ServiceCategory[0].active = "active";
                }
                }
                // console.log(this.policyOrdered)
                // console.log(this.ServiceCategory)
            });
        },
        getPolicyList() {
            var ids = this.ServiceCategory.filter((item) => item.active == "active").map((item) => item.id)
            let url = "api/Policy/GetSubscribeList";
            let reqObj = {
                keys: this.titleValue,
                keyword: ids.join(','),
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
            };
            // console.log(reqObj)
            request.post(url, reqObj).then((res) => {
                // console.log(res)
                if (res.data.Status == 1 && res.data.Data) {
                    this.total = res.data.Data.recordCount1;
                    this.PolicyDetails = [
                        ...res.data.Data.articleList.map((item) => {
                            return {
                                id: item.Id,
                                rank: item.Jibie,
                                title: item.Title,
                                releaseDate: item.AddTime,
                                unit: item.leibie,
                                pv: item.click
                            };
                        }),
                    ]
                }

                this.loadingisshow = false;
                // console.log(this.PolicyDetails)
            });
        },
        async PolicyTypes(index) {
            await this.Filtrate(index, this.ServiceCategory);
        },
        Subscription() {
            var ids = this.ServiceCategory.filter((item) => item.active == "active").map((item) => item.id)
            // console.log(ids)
            // console.log(ids.join(','))
            let url = "api/Policy/UpdateSubscribe";
            let reqObj = {
                subscribe: ids.join(',')
            };
            request.post(url, reqObj).then((res) => {
                // console.log(res)
                if (res.data.Status == 1) {
                    this.pageIndex = 1;
                    this.PolicyDetails = [];
                    this.getPolicyList();
                    this.dialogTableVisible = false;
                }
                // console.log(this.PolicyDetails)
            });
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getPolicyList();
        },
        Filtrate(index, Arrayname) {
            if (index == 0) {
                if (Arrayname[0].active == "active") {
                    Arrayname[0].active = "";
                    for (let i = 1; i < Arrayname.length; i++) {
                        Arrayname[i].active = "";
                    }
                } else {
                    Arrayname[0].active = "active";
                    for (let i = 1; i < Arrayname.length; i++) {
                        Arrayname[i].active = "active";
                    }
                }
            } else if (Arrayname[index].active == "active") {
                Arrayname[index].active = "";
                Arrayname[0].active = "";
            } else {
                Arrayname[0].active = "";
                Arrayname[index].active = "active";
                var JudgeArrayname = Arrayname.filter((item) => item.active == "" && item.name != "全选");
                // console.log(JudgeArrayname);
                if (JudgeArrayname.length > 0) {
                    Arrayname[0].active = "";
                }
                else {
                    Arrayname[0].active = "active";
                }
            }
        },
        formatDayTime(v) {
            return v.replace(/[a-zA-Z]/g, " ");
        },
        search() {
            this.pageIndex = 1;
            this.PolicyDetails = [];
            this.getPolicyList();
        },
        ViewDetails(id) {
            this.$router.push("/PolicyCalculation/PolicyDetails/" + id);
        },
    }
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
}

.content {
    background-color: #ffffff;
    width: 950px;
    min-height: 500px;
    margin: 20px auto;
    padding-bottom: 20px;
    z-index: 99;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.content-details {
    width: 950px;
    margin: 20px auto;
    padding: 20px 0px;
    background-color: #ffffff;
    z-index: 99;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.active {
    background-color: #028DFB !important;
}

.filter-wrapper .el-input input {
    border-color: #cecece;
    border-radius: 0.125rem;
    padding-right: 2.5rem;
}

.el-input__inner {
    width: 60%;
    border-radius: 1.25rem;
    height: 2.5rem;
    float: left;
}

.titleinput {
    width: 60%;
    height: 2.5rem;
    border-radius: 1.25rem;
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.2);
    float: left;
}

.Subscription {
    color: #ffffff;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #5393FF;
}

.Subscription span {
    padding: 0px 10px;
}

.el-dialog {
    /* background: rgba(0, 0, 0, .2) !important; */
    background: rgba(0, 0, 0, 0.2) !important;
    border: rgb(8, 255, 243) 1px solid;
    backdrop-filter: blur(3px);
}

/deep/ .el-dialog__title {
    color: #fff;
    line-height: 5.625rem;
}

/deep/ .el-dialog__header {
    min-height: 102px;
    padding: 0;
    height: 10rem;
    background: linear-gradient(90deg, #3a7bd5 0%, #00d2ff 100%);
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

/deep/ .el-dialog__header::before {
    content: '';
    display: block;
    width: 105%;
    position: absolute;
    background: #fff;
    height: 11.25rem;
    border-radius: 50%;
    bottom: -6.25rem;
}

.filter-wrapper {
    width: 95%;
    margin: 0px auto;
    border-radius: 0;
    padding: 10px 0px 30px 0px;
}

.filter-wrapper .filter-item {
    width: 100%;
    text-align: left;
    margin-bottom: 0.625rem;
    width: 100%;
}

.filter-wrapper .filter-item__label {
    font-size: 1rem;
    color: #333;
    margin-right: 0.25rem;
    margin-bottom: 0.625rem;
    white-space: nowrap;
    margin-top: 10px;
}

.filter-wrapper .filter-item__label1 {
    font-size: 0.8rem;
    margin-right: 1.25rem;
    margin-bottom: 0.625rem;
    margin-top: 15px;
    color: #028dfb;
}

.filter-wrapper .filter-item__options {
    width: 100%;
    overflow: hidden;
}

.filter-wrapper .filter-item__options div {
    justify-content: space-around;
    float: left;
    margin: 9px 4px 0px 4px;
    border-radius: 1.25rem !important;
    padding: 0.3375rem 0.9375rem;
    color: #fff;
    font-size: 0.875rem;
    background-color: #a3c8e5;
    user-select: none;
}

.el-dialog__footer {
    padding: 1.25rem;
    padding-top: 0.625rem;
    text-align: right;
    box-sizing: border-box;
}

.el-dialog__footer button {
    margin: 0px 5px;
}

.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 0.0625rem solid #DCDFE6;
    border-color: #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
}

.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 0.0625rem solid #DCDFE6;
    border-color: #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
}

.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 0.0625rem solid #DCDFE6;
    border-color: #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
}

.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 0.0625rem solid #DCDFE6;
    border-color: #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
}

.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 0.0625rem solid #DCDFE6;
    border-color: #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
}

button {
    font-family: inherit;
}

.zct-policy-item-comp {
    position: relative;
    width: 90%;
    margin: 20px auto;
    text-align: left;
    padding: 20px;
    border: 0.0625rem solid #c6d3e5;
}

.zct-policy-item-comp__title {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.6875rem;
    font-weight: 600;
    width: 90%;
}

.zct-policy-item-comp__title__level {
    flex: none;
    color: #028dfb;
    margin-right: 0.5625rem;
}

.zct-policy-item-comp__title__target {
    color: #333;
    margin-right: 0.5625rem;
}

.zct-policy-item-comp__line:not(:last-child) {
    margin-bottom: 0.375rem;
}

.zct-policy-item-comp__line {
    display: flex;
    font-size: 0.875rem;
    color: #666;
    align-items: center;
}

.zct-policy-item-comp__line__value {
    text-align: left;
    flex: 1;
}

.zct-policy-item-comp__line:nth-child(3) {
    /* border-bottom: 0.0625rem dashed #c6d3e5; */
    /* padding-bottom: 0.625rem; */
}

.zct-policy-item-comp__line:not(:last-child) {
    margin-bottom: 0.375rem;
}

.zct-policy-item-comp__line {
    display: flex;
    font-size: 0.875rem;
    color: #666;
    align-items: center;
}

.zct-policy-item-comp__line__value {
    flex: 1;
}

.date-icon {
    width: 0.875rem;
    height: 0.9375rem;
    margin-right: 0.5rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAAA7ElEQVQ4je3TPyuGURjH8c9zuycbsbBYrJLRjjegFCV5AxaLbF6AwYBBVj2TbM+uTAzKbMIgMVgkT+mqc+p4/Mlt9qurzrl+53yvznXOabU7FwrtYBnb2PJRLRxhDpvYzW4J6cczKjxhsAcyips0vsRkNqpU8R4TaZ7z83gsYqwAhr+KWyzGZAPDGMdJWtTGFAaKGMJZ8o8xjRGsB6RORvShgxlcYa3nONGDQ8ziFSspX9fFoqi053tF1YOv3OqHTb/WP+SzyttZwHmDvUv5a5SQO1w3gDzkQUC66MNpA0CpbvQkHtjbHwEv2H8H2awpYU6LD0gAAAAASUVORK5CYII=) no-repeat;
    background-size: contain;
}

.unit-icon {
    width: 0.875rem;
    height: 0.9375rem;
    margin-right: 0.5rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAABN0lEQVQ4jb3UvStGYRgG8J+PiJIikiQymEhZFIOBwVeZLP4Q+StMSllk8q4GSRkkm4/BIGGxsTD4CL3oqUc9vR2d9zW46tS5r+fc13Od6zznrirsnojYwZTK8IphXFQnbV0VigQ0oDXcVGcs7qEDLWjDFs4SLlwrpU21GUI3uEvqazSVcFelTVmOajK4qpw609EC6vAe16ejo80YbhCZKEfoEht4Qj060Yd1vETHjejNEzrFQVIfo4jDhDvCYtqUldGfkOUoZLKMz5jHPNqxFJ2FV5spJ6M3PCRCb1EgZPYRnymWI7SPtaTuwQBWEy64Gv+3jEbjWXqOn3kE3ZiL5yhsPlmO0CAKGfx2pY5SnMcT/oOhX36hXKFZ3Cb1I5p/EwqDKSDMllKM4T5n4/6QXZiQXzmu8oFvVdc3lSaHQ68AAAAASUVORK5CYII=) no-repeat;
    background-size: contain;
}

.blue-round-icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABWUlEQVQ4ja2VQUrDQBSGv06ilWhFuunGChYPIkiv4U68gxfwDuIJPEJ17w3caRd1XaSVYsW28uSNTIeZiVP8IYS89/9/JsmbP43T8zkRVEAb2AO2gRL4Aj6Bd2AMzELSMlBrAofAQaC3pccu0AHegFdgbWW+6T7QA4rY8j3IjVvACzCxLeNwhHCSYWhRqO73yaypPPIx0Mg0tGiofsc1PfJWvQlE37Wmlb7L/4D4VEbHJoU+cAc86rlfw2+XOocxnAHXTq/nXN9HNC2jgx3DZaR+kdA0TWQDWHQz64JCTJcJwiizLlgYf4t5uInUbxOauZhOE4QH4AoYapgM9XqQ0ExLTZtOgjSoMfExNhpfkwxRCuIzs1tzVPPB/oKl/bDW9EPf12pDw5XqxWctRCRwJRdzVyx80Yn+B34ySePJJdQgyA/tJpnbZ+cfJckueSthvNC+jGH4HwV8AyYIQ+PE3PJ4AAAAAElFTkSuQmCC) no-repeat;
    width: 0.9375rem;
    height: 0.9375rem;
    background-size: contain;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: sub;
}

.zct-policy-item-comp__rect {
    position: absolute;
    top: 0;
    font-size: 1rem;
    color: #fff;
    background: url("../../assets/PolicyCalculation/orange-flag.png") no-repeat;
    z-index: 0;
    height: 4.625rem;
    width: 2.25rem;
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
    align-items: baseline;
    right: 0.9375rem;
    padding-top: 0.625rem;
    line-height: 1.2;
    background-size: cover;
}
</style>