<template>
  <div>
    <div class="content">
      <div class="filter-wrapper" style="padding: 10px 0px 0px 0px">
        <input type="text" v-model="titleValue" placeholder="请输入政策标题关键字" class="el-input__inner" />
        <div class="global-search__btn" @click="search">
          <i class="icon-search"></i>
        </div>
      </div>
      <div class="filter-wrapper">
        <div class="filter-item">
          <div class="filter-item__label">政策类型：</div>
          <div class="filter-item__options" ref="type">
            <div v-for="(item, index) in ServiceCategory" :key="index" :class="item.active" @click="PolicyTypes(index)" style="cursor: pointer !important;">
              {{ item.name }}
            </div>
          </div>
          <!-- <div class="filter-item__label1" ref="unfold" @click="Unfold">{{ textOpenFlag ? '展开' : '收起' }}</div> -->
        </div>
        <div class="filter-item">
          <div class="filter-item__label">政策级别：</div>
          <div class="filter-item__options" ref="qwer">
            <div :class="item.active" v-for="(item, index) in InstitutionalType" :key="index" @click="PolicyLevel(index)" style="cursor: pointer !important;">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="filter-item">
          <div class="filter-item__label">发文部门：</div>
          <div class="filter-item__options">
            <div :class="item.active" v-for="(item, index) in InstitutionalArea" :key="index"  style="cursor: pointer !important;"
              @click="IssuingDepartment(index)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- <div class="filter-item">
                    <div class="filter-item__label">申报日期：</div>
                    <div class="block">
                        <el-date-picker :v-model="value1" type="datetimerange" start-placeholder="开始日期"
                            end-placeholder="结束日期" :default-time="['12:00:00']">
                        </el-date-picker>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="content-details">
      <div>
        <div class="zct-policy-item-comp" @click="ViewDetails(item.id)" v-for="(item, index) in PolicyDetails"
          :key="index">
          <div class="zct-policy-item-comp__title">
            <div class="zct-policy-item-comp__title__level">
              【{{ item.rank }}】
            </div>
            <div class="zct-policy-item-comp__title__target">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="zct-policy-item-comp__line">
            <i class="date-icon"></i>
            <div>发布日期：</div>
            <div class="zct-policy-item-comp__line__value">
              {{ item.releaseDate }}
            </div>
          </div>
          <div class="zct-policy-item-comp__line">
            <i class="unit-icon"></i>
            <div>发文单位：</div>
            <div class="zct-policy-item-comp__line__value">{{ item.unit }}</div>
          </div>
          <div class="zct-policy-item-comp__line">
            <i class="el-icon-view"
              style=" width: 0.875rem;height: 0.9375rem;margin-right: 0.5rem;background-size: contain;"></i>
            <div>浏览量：</div>
            <div class="zct-policy-item-comp__line__value">{{ item.pv }}</div>
          </div>
          <!-- <div class="zct-policy-item-comp__line"><i class="blue-round-icon"></i>
                        <div>申报时间：</div>
                        <div class="zct-policy-item-comp__line__value">2024-03-11至2024-03-21</div>
                    </div> -->
          <!-- <div class="zct-policy-item-comp__line"><i class="blue-round-icon"></i>
                        <div>扶持资金：</div>
                        <div class="zct-policy-item-comp__line__value">根据实际情况给予扶持</div>
                    </div>
                    <div class="zct-policy-item-comp__line baseline"><i class="blue-round-icon"></i>
                        <div>政策标签：</div>
                        <div class="zct-policy-item-comp__line__value">创新示范、示范项目、产学研、资助优惠、知识产权、成果转化</div>
                    </div>  -->
          <!-- <div class="zct-policy-item-comp__rect is-grey">非申报期</div>  -->
        </div>
      </div>
      <div style="
          width: 900px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
        <div>共{{ PolicyDetails.length }}条</div>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>
<script>
import request from "@/api/request";

import PolicyDetails from "./PolicyDetails.vue";
import siteFooter from "@/components/siteFooter";
export default {
  name: "policyinterpretation",
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      ServiceCategory: [],
      InstitutionalType: [],
      InstitutionalArea: [],
      textOpenFlag: false,
      PolicyDetails: [],
      pageSize: 10,
      titleValue: "",
      total: 0,
    };
  },
  async created() {
    await this.getOptions();
    await this.getPolicyList(
      this.ServiceCategory.filter(
        (item) => item.active == "active" && item.name != "不限"
      ).map((item) => item.id),
      this.InstitutionalType.filter(
        (item) => item.active == "active" && item.name != "不限"
      ).map((item) => item.id),
      this.InstitutionalArea.filter(
        (item) => item.active == "active" && item.name != "不限"
      ).map((item) => item.id),
      this.titleValue,
      this.pageSize,
      1
    );
  },
  methods: {
    getOptions() {
      request.get("/api/Policy/GetColumn").then((res) => {
        var options = [...res.data.Data];
        let arr = options
          .filter((item) => item.parent_id == 30)
          .map((item) => {
            return {
              active: "",
              id: item.id,
              name: item.title,
            };
          });
        this.ServiceCategory = [
          { active: "active", id: 0, name: "不限" },
          ...arr,
        ];

        arr = options
          .filter((item) => item.parent_id == 31)
          .map((item) => {
            return {
              active: "",
              id: item.id,
              name: item.title,
            };
          });
        this.InstitutionalType = [
          { active: "active", id: 0, name: "不限" },
          ...arr,
        ];

        arr = options
          .filter((item) => item.parent_id == 32)
          .map((item) => {
            return {
              active: "",
              id: item.id,
              name: item.title,
            };
          });
        this.InstitutionalArea = [
          { active: "active", id: 0, name: "不限" },
          ...arr,
        ];
      });
    },
    getPolicyList(typeitem, rankitem, unititem, title, pageSize, pageIndex) {
      let url = "/api/Policy/GetPolicy";
      let reqObj = {
        zclb: typeitem.join(','),
        zcjb: rankitem.join(','),
        qtbm: unititem.join(','),
        keyword: title,
        begintime: '',
        endtime: '',
        pageSize: pageSize,
        pageIndex: pageIndex,
      };

      request.post(url, reqObj).then((res) => {
        this.total = res.data.Data.total;
        this.PolicyDetails = [
          ...res.data.Data.data.map((item) => {
            return {
              id: item.id,
              rank: item.Jibie,
              title: item.title,
              releaseDate: this.formatDayTime(item.add_time),
              unit: item.leibie,
              pv: item.click
            };
          }),
        ];
      });
    },
    search() {
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalArea.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.titleValue,
        this.pageSize,
        1
      );
    },
    handleCurrentChange(val) {
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalArea.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.titleValue,
        this.pageSize,
        val
      );
    },
    async PolicyTypes(index) {
      await this.Filtrate(index, this.ServiceCategory);
      await this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalArea.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.titleValue,
        this.pageSize,
        1
      );
    },
    PolicyLevel(index) {
      for (let i = 0; i < this.InstitutionalType.length; i++) {
        if (i == index) {
          this.InstitutionalType[index].active = "active";
        } else {
          this.InstitutionalType[i].active = "";
        }
      }
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalArea.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.titleValue,
        this.pageSize,
        1
      );
    },
    IssuingDepartment(index) {
      for (let i = 0; i < this.InstitutionalArea.length; i++) {
        if (i == index) {
          this.InstitutionalArea[index].active = "active";
        } else {
          this.InstitutionalArea[i].active = "";
        }
      }
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalArea.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.titleValue,
        this.pageSize,
        1
      );
    },
    Filtrate(index, Arrayname) {
      if (index == 0) {
        Arrayname[0].active = "active";
        for (let i = 1; i < Arrayname.length; i++) {
          Arrayname[i].active = "";
        }
      } else if (Arrayname[index].active == "active") {
        Arrayname[index].active = "";
      } else {
        Arrayname[0].active = "";
        Arrayname[index].active = "active";
      }
    },
    formatDayTime(v) {
      return v.replace(/[a-zA-Z]/g, " ");
    },
    ViewDetails(id) {
      this.$router.push("/PolicyCalculation/PolicyDetails/" + id);
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  background-color: #ffffff;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.active {
  background-color: #028dfb !important;
}

.content-details {
  width: 950px;
  margin: 20px auto;
  padding: 20px 0px;
  background-color: #ffffff;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.el-input__inner {
  width: 60%;
  border-radius: 1.25rem;
  height: 2.5rem;
  float: left;
}

.titleinput {
  width: 60%;
  height: 2.5rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.2);
  float: left;
}

.filter-wrapper .el-input input {
  border-color: #cecece;
  border-radius: 0.125rem;
  padding-right: 2.5rem;
}

.el-input__suffix {
  position: relative;
  height: 2.5rem;
  left: -43px;
  z-index: 99;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}

.global-search__btn {
  position: relative;
  left: -43px;
  cursor: pointer;
  width: 4.6875rem;
  height: 2.5rem;
  background: #028dfb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
}

.global-search__btn .icon-search {
  height: 1.5625rem;
  width: 1.5625rem;
  background-size: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACUklEQVRIibWWyWtUQRDGfzO4kBAXTIQouBJEk4hHCZ7cIl7EkydjvEk86dU/IeYoiqDoyWNAVARFPLghigpC4kkRM2bUS1wyGtR80tAPKu3rt8Txgx66p+rV11Wv+utXkUQEa4ABYBfQDawAGsA74DEwCtwCZmMBUuEIg9Eu6YKkn8rHmKT+lBjRERq2S5ooQGQxK2lYUqUsoSP7FgR7IemkpG2SVkraKOmgpMuSZgLfs2UIOyTVzMPTko6aXbekZNAl6UFAOlSU8GJA1uf/3yTpuqTfku6lBFgs6aZ59qukzjzC9UGDHPHGfh8gQT0SZJmkN8ZvOI/wlHF+4g09PlOHhqQRSRsyAg2YGJNZDeR+7hjnE95w25D1Ze3YlNZWozfmWwW2mPN5H2gBdvv1GeBRgeM8Azw3680xR0e43KzfAz88yUfgfAkNqZt5R8xpAfDFZ+XQ5sQH2FGCKMFCM5+OObkMJ8y6ex5ECXrNvJZF+MysD/wDWZef/wpizoWk/aa7XFeuLSPGflwxMa7lncOqV/0EDyUtKkF2OJC3vUWkbZ9X/QRXJS0pQHYoEPHPRaQtGaeDnb6VNCipNeVBd3uMKh1jWaT2xq8A54BjwWtu+Cb44I9PD7Aup4nG/ZdC/S9Lyi6OBzKVh6kymcZqvcqr/utIMHe73JC0xwcdL0qa9RGVwJVwK7AU+A5MAk+BKePTCdyNaOjc8s7jzMVGoUybSZhH+tJ1fJGSlkVWeYeqzWbz72on8CrF1tbskobltZL5SdLq/1FSi1ZgEGgHLgG1P/CqcmvAyTXiAAAAAElFTkSuQmCC);
}

.filter-wrapper {
  width: 95%;
  margin: 0px auto;
  border-radius: 0;
  padding: 10px 0px 30px 0px;
}

.filter-wrapper .filter-item {
  display: flex;
  text-align: center;
  margin-bottom: 0.625rem;
  width: 100%;
}

.filter-wrapper .filter-item__label {
  font-size: 1rem;
  color: #333;
  margin-right: 0.25rem;
  margin-bottom: 0.625rem;
  white-space: nowrap;
  margin-top: 10px;
}

.filter-wrapper .filter-item__label1 {
  font-size: 0.8rem;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 15px;
  color: #028dfb;
}

.filter-wrapper .filter-item__options {
  width: 850px;
  overflow: hidden;
}

.filter-wrapper .filter-item__options div {
  justify-content: space-around;
  float: left;
  margin: 9px 4px 0px 4px;
  border-radius: 1.25rem !important;
  padding: 0.3375rem 0.9375rem;
  color: #fff;
  font-size: 0.875rem;
  background-color: #a3c8e5;
  user-select: none;
}

.zct-policy-item-comp {
  position: relative;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  border: 0.0625rem solid #c6d3e5;
  cursor: pointer
}

.zct-policy-item-comp__title {
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.6875rem;
  font-weight: 600;
  width: 90%;
}

.zct-policy-item-comp__title__level {
  flex: none;
  color: #028dfb;
  margin-right: 0.5625rem;
}

.zct-policy-item-comp__title__target {
  color: #333;
  margin-right: 0.5625rem;
}

.zct-policy-item-comp__line:not(:last-child) {
  margin-bottom: 0.375rem;
}

.zct-policy-item-comp__line {
  display: flex;
  font-size: 0.875rem;
  color: #666;
  align-items: center;
}

.zct-policy-item-comp__line__value {
  flex: 1;
}

.zct-policy-item-comp__line:nth-child(3) {
  /* border-bottom: 0.0625rem dashed #c6d3e5; */
  /* padding-bottom: 0.625rem; */
}

.zct-policy-item-comp__line:not(:last-child) {
  margin-bottom: 0.375rem;
}

.zct-policy-item-comp__line {
  display: flex;
  font-size: 0.875rem;
  color: #666;
  align-items: center;
}

.zct-policy-item-comp__line__value {
  flex: 1;
}

.date-icon {
  width: 0.875rem;
  height: 0.9375rem;
  margin-right: 0.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAAA7ElEQVQ4je3TPyuGURjH8c9zuycbsbBYrJLRjjegFCV5AxaLbF6AwYBBVj2TbM+uTAzKbMIgMVgkT+mqc+p4/Mlt9qurzrl+53yvznXOabU7FwrtYBnb2PJRLRxhDpvYzW4J6cczKjxhsAcyips0vsRkNqpU8R4TaZ7z83gsYqwAhr+KWyzGZAPDGMdJWtTGFAaKGMJZ8o8xjRGsB6RORvShgxlcYa3nONGDQ8ziFSspX9fFoqi053tF1YOv3OqHTb/WP+SzyttZwHmDvUv5a5SQO1w3gDzkQUC66MNpA0CpbvQkHtjbHwEv2H8H2awpYU6LD0gAAAAASUVORK5CYII=) no-repeat;
  background-size: contain;
}

.unit-icon {
  width: 0.875rem;
  height: 0.9375rem;
  margin-right: 0.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAABN0lEQVQ4jb3UvStGYRgG8J+PiJIikiQymEhZFIOBwVeZLP4Q+StMSllk8q4GSRkkm4/BIGGxsTD4CL3oqUc9vR2d9zW46tS5r+fc13Od6zznrirsnojYwZTK8IphXFQnbV0VigQ0oDXcVGcs7qEDLWjDFs4SLlwrpU21GUI3uEvqazSVcFelTVmOajK4qpw609EC6vAe16ejo80YbhCZKEfoEht4Qj060Yd1vETHjejNEzrFQVIfo4jDhDvCYtqUldGfkOUoZLKMz5jHPNqxFJ2FV5spJ6M3PCRCb1EgZPYRnymWI7SPtaTuwQBWEy64Gv+3jEbjWXqOn3kE3ZiL5yhsPlmO0CAKGfx2pY5SnMcT/oOhX36hXKFZ3Cb1I5p/EwqDKSDMllKM4T5n4/6QXZiQXzmu8oFvVdc3lSaHQ68AAAAASUVORK5CYII=) no-repeat;
  background-size: contain;
}

.blue-round-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABWUlEQVQ4ja2VQUrDQBSGv06ilWhFuunGChYPIkiv4U68gxfwDuIJPEJ17w3caRd1XaSVYsW28uSNTIeZiVP8IYS89/9/JsmbP43T8zkRVEAb2AO2gRL4Aj6Bd2AMzELSMlBrAofAQaC3pccu0AHegFdgbWW+6T7QA4rY8j3IjVvACzCxLeNwhHCSYWhRqO73yaypPPIx0Mg0tGiofsc1PfJWvQlE37Wmlb7L/4D4VEbHJoU+cAc86rlfw2+XOocxnAHXTq/nXN9HNC2jgx3DZaR+kdA0TWQDWHQz64JCTJcJwiizLlgYf4t5uInUbxOauZhOE4QH4AoYapgM9XqQ0ExLTZtOgjSoMfExNhpfkwxRCuIzs1tzVPPB/oKl/bDW9EPf12pDw5XqxWctRCRwJRdzVyx80Yn+B34ySePJJdQgyA/tJpnbZ+cfJckueSthvNC+jGH4HwV8AyYIQ+PE3PJ4AAAAAElFTkSuQmCC) no-repeat;
  width: 0.9375rem;
  height: 0.9375rem;
  background-size: contain;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: sub;
}

.zct-policy-item-comp__rect {
  position: absolute;
  top: 0;
  font-size: 1rem;
  color: #fff;
  background: url("../../assets/PolicyCalculation/orange-flag.png") no-repeat;
  z-index: 0;
  height: 4.625rem;
  width: 2.25rem;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  align-items: baseline;
  right: 0.9375rem;
  padding-top: 0.625rem;
  line-height: 1.2;
  background-size: cover;
}
</style>