<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "map-view",
  props: ["mapkey"],
  data() {
    return {
      features: ["bg", "point", "road"],
      bounds: [121.37308349609375, 31.643118831385902, 122.02746826171875, 32.16933267766434],
      points: [
        { id: 1, name: '海工船舶工业园(寅阳镇)', jindu: 121.807564, weidu: 31.73521 },
        { id: 2, name: '惠萍镇', jindu: 121.76139, weidu: 31.76422 },
        { id: 3, name: '东海镇', jindu: 121.78430, weidu: 31.79802 },
        { id: 4, name: '高新技术产业开发区(近海镇)', jindu: 121.7845, weidu: 31.915451 },
        { id: 5, name: '海复镇', jindu: 121.684982, weidu: 31.985773 },
        { id: 6, name: '吕四港镇', jindu: 121.602631, weidu: 32.060261 },
        { id: 7, name: '合作镇', jindu: 121.634041, weidu: 31.92255 },
        { id: 8, name: '王鲍镇', jindu: 121.576952, weidu: 31.905498 },
        { id: 9, name: '南阳镇', jindu: 121.707802, weidu: 31.871801 },
        { id: 10, name: '北新镇', jindu: 121.507301, weidu: 31.823812 },
        { id: 11, name: '汇龙镇', jindu: 121.671562, weidu: 31.803322 },
        { id: 12, name: '启东经济开发区', jindu: 121.62593, weidu: 31.80536 },
        { id: 13, name: '吕四港经济开发区', jindu: 121.698944, weidu: 32.027111 },
        { id: 14, name: '圆陀角旅游度假区', jindu: 121.896446, weidu: 31.6987 },
        { id: 15, name: '江海澜湾旅游度假区', jindu: 121.788128, weidu: 31.968225 },
        { id: 16, name: '启隆镇', jindu: 121.456329, weidu: 31.7742 },
        { id: 17, name: '生命健康科技园', jindu: 121.59512, weidu: 31.784956 },
        { id: 18, name: '生命健康产业园', jindu: 121.490768, weidu: 31.808372 }
      ]
    };
  },
  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      var options = {
        areas: [
          {
            //围栏1
            //visible:false,//是否可见
            rejectTexture: true, //是否屏蔽自定义地图的纹理
            path: [
              [
                [121.431488, 31.76926],
                [121.44539, 31.764301],
                [121.449745, 31.761662],
                [121.455574, 31.759342],
                [121.464143, 31.757138],
                [121.476811, 31.756145],
                [121.48775, 31.753419],
                [121.488827, 31.754098],
                [121.48974, 31.753389],
                [121.498559, 31.753262],
                [121.513043, 31.743696],
                [121.514984, 31.742871],
                [121.52669, 31.740215],
                [121.528354, 31.738351],
                [121.539424, 31.735498],
                [121.540125, 31.733314],
                [121.549504, 31.726969],
                [121.551385, 31.727385],
                [121.565021, 31.716706],
                [121.578538, 31.710528],
                [121.592266, 31.706483],
                [121.59325, 31.705378],
                [121.599666, 31.703117],
                [121.600911, 31.706995],
                [121.602754, 31.706939],
                [121.611758, 31.704288],
                [121.615645, 31.702483],
                [121.627335, 31.697769],
                [121.6292, 31.697749],
                [121.633272, 31.696162],
                [121.642656, 31.69746],
                [121.715263, 31.673844],
                [121.81781, 31.652027],
                [121.887611, 31.636379],
                [121.975185, 31.617039],
                [121.96996, 31.671095],
                [121.967709, 31.703908],
                [121.970297, 31.718876],
                [121.966948, 31.729878],
                [121.960146, 31.736851],
                [121.940992, 31.77657],
                [121.897446, 31.853537],
                [121.889155, 31.866623],
                [121.874834, 31.902108],
                [121.87031, 31.928281],
                [121.865047, 31.940366],
                [121.856005, 31.955224],
                [121.772395, 32.033219],
                [121.767121, 32.04027],
                [121.761842, 32.056122],
                [121.759074, 32.059395],
                [121.729627, 32.06919],
                [121.592571, 32.112924],
                [121.544486, 32.123027],
                [121.537037, 32.103911],
                [121.532687, 32.089359],
                [121.530507, 32.082524],
                [121.531507, 32.074183],
                [121.533611, 32.066348],
                [121.533704, 32.063454],
                [121.529648, 32.055825],
                [121.526636, 32.04984],
                [121.524173, 32.045538],
                [121.523667, 32.043012],
                [121.524412, 32.026137],
                [121.523395, 32.024223],
                [121.522411, 31.998068],
                [121.523075, 31.994187],
                [121.523015, 31.980219],
                [121.523118, 31.979224],
                [121.517251, 31.974584],
                [121.515359, 31.97186],
                [121.516137, 31.97047],
                [121.515974, 31.969621],
                [121.512451, 31.968337],
                [121.510499, 31.966846],
                [121.507052, 31.965425],
                [121.505877, 31.965466],
                [121.505638, 31.964141],
                [121.506307, 31.959653],
                [121.507459, 31.959187],
                [121.508356, 31.954021],
                [121.507024, 31.952055],
                [121.507166, 31.949365],
                [121.506845, 31.948339],
                [121.507606, 31.947221],
                [121.507753, 31.945371],
                [121.506285, 31.942049],
                [121.508574, 31.940725],
                [121.505078, 31.930597],
                [121.501843, 31.920488],
                [121.497787, 31.907455],
                [121.4982, 31.906332],
                [121.499739, 31.906038],
                [121.505725, 31.906954],
                [121.505725, 31.905563],
                [121.503909, 31.902584],
                [121.493747, 31.896994],
                [121.48507, 31.894536],
                [121.482743, 31.894207],
                [121.479921, 31.884003],
                [121.474859, 31.879313],
                [121.470194, 31.869629],
                [121.468128, 31.863157],
                [121.46585, 31.857535],
                [121.462881, 31.856659],
                [121.463327, 31.853795],
                [121.46417, 31.850586],
                [121.465344, 31.839735],
                [121.46368, 31.832254],
                [121.460141, 31.8206],
                [121.445124, 31.793465],
                [121.443041, 31.787332],
                [121.438257, 31.777826],
                [121.431488, 31.76926],
              ],
            ],
          },
        ],
      };
      var markers = [];
      AMapLoader.load({
        key: this.mapkey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            // 设置地图容器id
            zoom: 10, // 初始化地图级别
            center: [121.655, 31.891], // 初始化地图中心点位置
            mapStyle: "amap://styles/dce73840e80487f0b84bfc7ad8cec413",
            resizeEnable: true,
            rotatEnable: true,
            showIndoorMap: false,
            buildingAnimation: true,
            expandZoomRange: true,
            features: this.features,
            layers: [new AMap.TileLayer({})],
            bounds: this.bounds
          });
          var outer = [
            new AMap.LngLat(-360, 90, true),
            new AMap.LngLat(-360, -90, true),
            new AMap.LngLat(360, -90, true),
            new AMap.LngLat(360, 90, true),
          ];

          var pathArray = [outer];
          pathArray.push.apply(pathArray, options.areas[0].path);

          new AMap.Polygon({
            bubble: true,
            fillOpacity: 1.2,
            strokeWeight: 2,
            strokeColor: 'red',
            fillColor: '#E3EBF8',//改变颜色目前粉红
            path: pathArray,
            map: this.map
          })

          var carparkIcon1 = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(35, 35),
            // 图标所用图片大小
            imageSize: new AMap.Size(15, 15),
            imageOffset: new AMap.Pixel(5, 20)
          });
          this.points.forEach(item => {
            var marker1 = new AMap.Marker({
              map: this.map,
              icon: carparkIcon1,
              position: [item.jindu, item.weidu],
              zooms: [10, 17],
              visable: true,
              opacity: 1,
              offset: new AMap.Pixel(-10, -28)
            });
            marker1.setLabel({
              offset: new AMap.Pixel(-7, 11),//设置文本标注偏移量
              content: item.name,//设置文本标注内容
              direction: 'top' //设置文本标注方位
            });
            let that=this;
            // console.log(AMap.Event)
            AMap.Event.addListener(marker1, 'click', function (e) {
              that.$emit('selectqyList',item.name)
              // console.log(e, item.name);
            });
            markers.push(marker1);
          })
          setTimeout(function () {
            // console.log(markers);
          }, 1000);
        })
        .catch((e) => {
          // console.log(e);
        });
    },
  },
};
</script>
<style scoped>
#container {
  width: 100%;
  min-height: 593px;
}
</style>
