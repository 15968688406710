<template>
    <div>
        <demandsHeader></demandsHeader>
        <declareBanner></declareBanner>
        <RouterView />
    </div>
</template>
<script>
import demandsHeader from "@/components/demandsHeader";
import declareBanner from "@/view/declare/components/pageBanner";
import { RouterView } from "vue-router";
export default {
    components: {
        'demandsHeader': demandsHeader,
        'declareBanner': declareBanner,
    },

}
</script>