<template>
  <div>
    <div style="width: 950px; margin: 30px auto; background-color: #ffffff">
      <el-table :data="projects.data" style="padding: 20px">
        <el-table-column align="center" type="index" label="编号" />
        <el-table-column label="政策名称" prop="title" />
        <el-table-column align="center" label="发文单位" prop="unit" />
        <el-table-column align="center" label="申报截止时间" prop="endtime" />
        <el-table-column align="center" label="浏览量" prop="click" width="92"/>
        <el-table-column align="center" label="申报入口">
          <template #default="scope">
            <el-button :type="scope.row.type" @click="signUp(scope.row.id)" size="small">
              {{ scope.row.text }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="
          width: 900px;
          margin: 10px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="projects.total"
          :page-size="pageSize" :current-page="currentPage">
        </el-pagination>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import request from "@/api/request";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      projects: [],
    };
  },
  created() {
    this.initData();
  },
  mounted() {
  },
  methods: {
    initData() {
      request
        .post("/api/Project/GetProjectList", {
          keyword: "",
          pagesize: this.pageSize,
          pageindex: this.currentPage,
        })
        .then((response) => {
          // console.log(response)
          this.projects = response.data.Data;
          this.projects.data.forEach((project) => {
            if (Date.now() > Date.parse(project.endtime)) {
              project["text"] = "已结束";
              project["type"] = "danger";
            } else if (Date.now() < Date.parse(project.begintime)) {
              project["text"] = "未开始";
              project["type"] = "success";
            } else {
              project["text"] = "立即申报";
              project["type"] = "primary";
            }
            project.endtime = project.endtime.replace(/[a-zA-Z]/g, " ");
          });
          // console.log(response.data);
        });
    },
    signUp(_id) {
      // console.log(_id);
      this.$router.push("/PolicyCalculation/policySearchDetail/" + _id);
    },
    // 当当前页发生变化时的回调
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initData(); // 重新查询数据
    },
  },
};
</script>

<style scoped></style>
