<template>
  <div>
    <demands-header></demands-header>
    <div class="banner">
      <div class="global-search">
        <div class="global-search__inner">
          <div class="global-search__input el-input el-input--suffix">
            <input
              type="text"
              autocomplete="off"
              placeholder="请输入关键词"
              class="el-input__inner"
              style="padding-left: 20px"
              v-model="searchValue"
            />
            <span class="el-input__suffix">
              <span class="el-input__suffix-inner">
                <div class="global-search__btn" @click="initData">
                  <i class="icon-search"></i>
                </div>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="tag">
        <div
          class="submenu-item"
          v-for="(item, index) in arrayitem"
          :key="index"
          @click="chooseContent(index)"
        >
          <div class="round" :style="item.bgcolor">
            <div
              class="circle"
              :class="{ animmate: index == isactive }"
              :style="item.bgcolor"
            ></div>
            <div class="submenu-item__icon" :class="item.icon"></div>
          </div>
          <div class="submenu-item__title" style="color: #ffffff">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div style="background: linear-gradient(to bottom, #9bb7e6, #ffffff)">
      <div style="width: 1000px; margin: auto; background-color: white">
        <div>
          <div
            v-if="
              isactive == 0 &&
              policyList.length <= 0 &&
              picExplainList.length <= 0 &&
              activityList.length <= 0
            "
          >
            <el-empty description="暂无数据"></el-empty>
          </div>
          <div v-else-if="isactive == 0">
            <div
              v-if="policyList.length > 0"
              style="
                display: inline-block;
                text-align: center;
                padding: 20px;
                padding-bottom: 0;
              "
            >
              <div style="inline-block">
                <div class="line-blue"></div>
                <div class="txt">政策文件</div>
                <span
                  style="margin-left: 760px; color: #666; cursor: pointer"
                  @click="getMorePolicy"
                  >查看更多</span
                >
              </div>
            </div>
            <!-- <div v-else>
              <el-empty description="暂无数据"></el-empty>
            </div> -->
            <div
              class="zct-policy-item-comp"
              style="cursor: pointer"
              @click="policyDetail(item.id)"
              v-for="item in policyList"
              :key="item.id"
            >
              <div class="zct-policy-item-comp__title">
                <div class="zct-policy-item-comp__title__level">
                  【{{ item.Jibie }}】
                </div>
                <div class="zct-policy-item-comp__title__target">
                  <span>{{ item.title }}</span>
                </div>
              </div>
              <div class="zct-policy-item-comp__line">
                <i class="date-icon"></i>
                <div>发布日期：</div>
                <div class="zct-policy-item-comp__line__value">
                  {{ item.add_time }}
                </div>
              </div>
              <div class="zct-policy-item-comp__line">
                <i class="unit-icon"></i>
                <div>发文单位：</div>
                <div class="zct-policy-item-comp__line__value">
                  {{ item.leibie }}
                </div>
              </div>
              <div class="zct-policy-item-comp__line">
                <i
                  class="el-icon-view"
                  style="
                    width: 0.875rem;
                    height: 0.9375rem;
                    margin-right: 0.5rem;
                    background-size: contain;
                  "
                ></i>
                <div>浏览量：</div>
                <div class="zct-policy-item-comp__line__value">
                  {{ item.click }}
                </div>
              </div>
            </div>

            <div
              v-if="picExplainList.length > 0"
              style="display: inline-block; text-align: center; padding: 20px"
            >
              <div style="inline-block">
                <div class="line-blue"></div>
                <div class="txt">政策解读</div>
                <span
                  style="margin-left: 760px; color: #666; cursor: pointer"
                  @click="getMorePicExplain"
                  >查看更多</span
                >
              </div>
            </div>
            <!-- <div v-else>
              <el-empty description="暂无数据"></el-empty>
            </div> -->
            <div style="display: inline-block; padding: 0 20px">
              <div
                class="el-col el-col-6"
                style="padding-left: 10px; padding-right: 10px"
                v-for="item in picExplainList"
                :key="item.id"
                @click="picExplainDetail(item.id)"
              >
                <div class="topic-item">
                  <div :title="item.title" class="title">{{ item.title }}</div>
                  <div class="cover">
                    <img
                      :src="item.img_url"
                      style="width: 100%"
                      height="100%"
                    />
                  </div>
                  <div class="flex row-between p-b-10 bottom-warp">
                    <span class="light"
                      ><i class="el-icon-view m-r-5"></i
                      >{{ item.click }}次阅读</span
                    >
                    <span class="light"
                      ><i class="el-icon-date m-r-5"></i
                      >{{ item.add_time | formatDate }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="activityList.length > 0"
              style="
                display: inline-block;
                text-align: center;
                padding: 20px;
                padding-bottom: 0;
              "
            >
              <div style="inline-block">
                <div class="line-blue"></div>
                <div class="txt">活动路演</div>
                <span
                  style="margin-left: 760px; color: #666; cursor: pointer"
                  @click="getMoreActivity"
                  >查看更多</span
                >
              </div>
            </div>
            <!-- <div v-else>
              <el-empty description="暂无数据"></el-empty>
            </div> -->
            <div>
              <div
                class="zct-policy-item-comp"
                style="cursor: pointer"
                @click="activityDetail(item.id)"
                v-for="item in activityList"
                :key="item.id"
              >
                <div class="zct-policy-item-comp__title">
                  <div class="zct-policy-item-comp__title__target">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
                <div class="zct-policy-item-comp__line">
                  <div>活动时间：</div>
                  <div class="zct-policy-item-comp__line__value">
                    {{ item.begin_time | formatDateTime }} 至
                    {{ item.end_time | formatDateTime }}
                  </div>
                </div>
                <div class="zct-policy-item-comp__line">
                  <div>活动地址：</div>
                  <div class="zct-policy-item-comp__line__value">
                    {{ item.address }}
                  </div>
                </div>
                <div class="zct-policy-item-comp__line">
                  <div>浏览量：</div>
                  <div class="zct-policy-item-comp__line__value">
                    {{ item.click }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isactive == 1">
            <div
              v-if="policyList.length > 0"
              style="
                display: inline-block;
                text-align: center;
                padding: 20px;
                padding-bottom: 0;
              "
            >
              <div style="inline-block">
                <div class="line-blue"></div>
                <div class="txt">政策文件</div>
                <span
                  style="margin-left: 760px; color: #666; cursor: pointer"
                  @click="getMorePolicy"
                  >查看更多</span
                >
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div
              class="zct-policy-item-comp"
              style="cursor: pointer"
              @click="policyDetail(item.id)"
              v-for="item in policyList"
              :key="item.id"
            >
              <div class="zct-policy-item-comp__title">
                <div class="zct-policy-item-comp__title__level">
                  【{{ item.Jibie }}】
                </div>
                <div class="zct-policy-item-comp__title__target">
                  <span>{{ item.title }}</span>
                </div>
              </div>
              <div class="zct-policy-item-comp__line">
                <i class="date-icon"></i>
                <div>发布日期：</div>
                <div class="zct-policy-item-comp__line__value">
                  {{ item.add_time }}
                </div>
              </div>
              <div class="zct-policy-item-comp__line">
                <i class="unit-icon"></i>
                <div>发文单位：</div>
                <div class="zct-policy-item-comp__line__value">
                  {{ item.leibie }}
                </div>
              </div>
              <div class="zct-policy-item-comp__line">
                <i
                  class="el-icon-view"
                  style="
                    width: 0.875rem;
                    height: 0.9375rem;
                    margin-right: 0.5rem;
                    background-size: contain;
                  "
                ></i>
                <div>浏览量：</div>
                <div class="zct-policy-item-comp__line__value">
                  {{ item.click }}
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="isactive == 2">
            <div
              v-if="picExplainList.length > 0"
              style="display: inline-block; text-align: center; padding: 20px"
            >
              <div style="inline-block">
                <div class="line-blue"></div>
                <div class="txt">政策解读</div>
                <span
                  style="margin-left: 760px; color: #666; cursor: pointer"
                  @click="getMorePicExplain"
                  >查看更多</span
                >
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div style="display: inline-block; padding: 0 20px">
              <div
                class="el-col el-col-6"
                style="padding-left: 10px; padding-right: 10px"
                v-for="item in picExplainList"
                :key="item.id"
                @click="picExplainDetail(item.id)"
              >
                <div class="topic-item">
                  <div :title="item.title" class="title">{{ item.title }}</div>
                  <div class="cover">
                    <img
                      :src="item.img_url"
                      style="width: 100%"
                      height="100%"
                    />
                  </div>
                  <div class="flex row-between p-b-10 bottom-warp">
                    <span class="light"
                      ><i class="el-icon-view m-r-5"></i
                      >{{ item.click }}次阅读</span
                    >
                    <span class="light"
                      ><i class="el-icon-date m-r-5"></i
                      >{{ item.add_time | formatDate }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="isactive == 3">
            <div
              v-if="activityList.length > 0"
              style="
                display: inline-block;
                text-align: center;
                padding: 20px;
                padding-bottom: 0;
              "
            >
              <div style="inline-block">
                <div class="line-blue"></div>
                <div class="txt">活动路演</div>
                <span
                  style="margin-left: 760px; color: #666; cursor: pointer"
                  @click="getMoreActivity"
                  >查看更多</span
                >
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div>
              <div
                class="zct-policy-item-comp"
                style="cursor: pointer"
                @click="activityDetail(item.id)"
                v-for="item in activityList"
                :key="item.id"
              >
                <div class="zct-policy-item-comp__title">
                  <div class="zct-policy-item-comp__title__target">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
                <div class="zct-policy-item-comp__line">
                  <div>活动时间：</div>
                  <div class="zct-policy-item-comp__line__value">
                    {{ item.begin_time | formatDateTime }} 至
                    {{ item.end_time | formatDateTime }}
                  </div>
                </div>
                <div class="zct-policy-item-comp__line">
                  <div>活动地址：</div>
                  <div class="zct-policy-item-comp__line__value">
                    {{ item.address }}
                  </div>
                </div>
                <div class="zct-policy-item-comp__line">
                  <div>浏览量：</div>
                  <div class="zct-policy-item-comp__line__value">
                    {{ item.click }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer></site-footer>
  </div>
</template>

<script>
import request from "../../api/request";
import demandsHeader from "@/components/demandsHeader";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    demandsHeader: demandsHeader,
    siteFooter: siteFooter,
  },
  data() {
    const arrayitem = [
      {
        icon: "menu1",
        bgcolor: "background-color:rgb(246, 175, 63);",
        name: "综合",
      },
      {
        icon: "menu2",
        bgcolor: "background-color:rgb(163, 213, 95);",
        name: "政策文件",
      },
      {
        icon: "menu3",
        bgcolor: "background-color:rgb(0, 191, 215);",
        name: "政策图解",
      },
      {
        icon: "menu4",
        bgcolor: "background-color:rgb(103, 201, 187);",
        name: "活动路演",
      },
      // , { url: '', icon: 'menu5', bgcolor: 'background-color:#00A0E9;', name: '政策订阅' }
    ];
    return {
      arrayitem: arrayitem,
      isactive: 0,
      policyList: [],
      picExplainList: [],
      activityList: [],
      searchValue: "",
      isLoaded: false,
      status: 0,
    };
  },
  created() {
    if (this.$route.params.status) {
      this.status = this.$route.params.status;
    }
    this.searchValue = this.$route.params.searchValue;
    this.isactive = this.$route.params.status;
    // console.log(this.status, this.searchValue);
    this.initData();
  },
  filters: {
    formatDate(datetime) {
      if (!datetime) return "";
      return datetime.slice(0, 10);
    },
    formatDateTime(datetime) {
      if (!datetime) return "";
      return datetime.slice(0, 16).replace(/[a-zA-Z]/g, " ");
    },
  },
  methods: {
    initData() {
      // console.log(this.status, this.searchValue);
      this.isactive = this.status;
      request
        .post("/api/Policy/GetAll", {
          status: this.status,
          keyword: this.searchValue,
          pagesize: 8,
          pageindex: 1,
        })
        .then((res) => {
          // console.log(res);
          this.policyList = [];
          this.picExplainList = [];
          this.activityList = [];
          if (this.status == 0) {
            this.policyList = res.data.Data.articlenewList;
            this.picExplainList = res.data.Data.articlezcjdList;
            this.activityList = res.data.Data.activityList;
            // console.log(this.policyList);
          } else if (this.status == 1) {
            this.policyList = res.data.Data.articlenewList;
          } else if (this.status == 2) {
            this.picExplainList = res.data.Data.articlezcjdList;
          } else if (this.status == 3) {
            this.activityList = res.data.Data.activityList;
          }
        });
    },

    chooseContent(index) {
      this.isactive = index;
      request
        .post("/api/Policy/GetAll", {
          status: index,
          keyword: this.searchValue,
          pagesize: 8,
          pageindex: 1,
        })
        .then((res) => {
          // console.log(res);
          this.policyList = [];
          this.picExplainList = [];
          this.activityList = [];
          if (index == 0) {
            this.policyList = res.data.Data.articlenewList;
            this.picExplainList = res.data.Data.articlezcjdList;
            this.activityList = res.data.Data.activityList;
          } else if (index == 1) {
            this.policyList = res.data.Data.articlenewList;
          } else if (index == 2) {
            this.picExplainList = res.data.Data.articlezcjdList;
          } else if (index == 3) {
            this.activityList = res.data.Data.activityList;
          }
        });
    },

    policyDetail(id) {
      this.$router.push("/PolicyCalculation/PolicyDetails/" + id);
    },
    picExplainDetail(id) {
      this.$router.push("/PolicyCalculation/policyInterpretationDeteils/" + id);
    },
    activityDetail(_id) {
      this.$router.push({
        path: "/activityInfo",
        query: {
          id: _id,
        },
      });
    },

    getMorePolicy() {
      this.$router.push("/PolicyCalculation/PolicySearch");
    },
    getMorePicExplain() {
      this.$router.push("/PolicyCalculation/policyInterpretation");
    },
    getMoreActivity() {
      this.$router.push("/activity");
    },
  },
};
</script>

<style scoped>
@import url("../../../public/layui/css/layui.css");
.banner {
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/professionals-banner.jpg");
}

.line-blue {
  width: 8px;
  height: 30px;
  border-radius: 10px;
  background-color: #409eff;
  display: inline-block;
  vertical-align: middle;
}

.txt {
  margin-left: 10px;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.global-search {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 200px; /* 与.banner高度一致 */
  position: relative; /* 相对定位，用于绝对定位子元素 */
}

.global-search__inner {
  width: 43.125rem;
  position: relative; /* 相对定位，用于绝对定位子元素 */
}

.global-search__input.el-input--suffix {
  position: relative; /* 相对定位，以便绝对定位子元素参照 */
  display: flex;
  align-items: center; /* 垂直居中 */
}

.el-input--suffix .el-input__inner {
  border-radius: 1.25rem;
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.2);
  height: 2.5rem;
  padding-right: 3.875rem; /* 调整输入框右侧空白 */
}

.el-input__suffix {
  position: absolute;
  height: 100%;
  right: 0.3125rem;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}

.global-search__btn {
  position: absolute;
  right: 0.3125rem;
  top: 0;
  cursor: pointer;
  width: 4.6875rem;
  height: 100%;
  background: #028dfb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
}

* {
  margin: 0;
  padding: 0;
}

a,
li {
  color: #000;
  list-style: none;
  text-decoration: none;
}

.animmate {
  transform: scale(1.2);
  opacity: 0.5;
}

.banner {
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/policycalculation-banner.jpg");
}

.box {
  display: flex;
  flex-direction: column;
  width: 64.375rem;
  margin: 0 auto;
}

.submenu-banner-title {
  font-size: 2.25rem;
  font-weight: bold;
  color: #fff;
  text-align: left;
  text-shadow: 0 0.3125rem 0.25rem rgba(0, 54, 114, 0.6);
}

.large {
  font-style: normal;
  font-size: 3.375rem;
  margin-right: 0.625rem;
}

.line {
  width: 18.125rem;
  height: 0.0625rem;
  background-color: #fff;
  opacity: 0.5;
  margin: 0.3125rem 0 0.625rem;
}

.submenu-banner-entitle {
  font-size: 0.875rem;
  color: #fff;
  text-align: left;
  letter-spacing: 0.125rem;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 62vh;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  background: linear-gradient(180deg, rgba(110, 150, 216, 0.5) 0%, #fff 75%);
  overflow: hidden;
  overflow-y: auto;
  z-index: -1;
}

.submenu-item {
  font-size: 1.125rem;
  margin: 0 4.375rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.round {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.circle {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
  position: absolute;
  border-radius: 50%;
  z-index: -2;
}

.submenu-item__icon {
  width: 1.875rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;
}

.menu1 {
  background-image: url("../../assets/searchListIcon/zonghe.png");
}

.menu2 {
  background-image: url("../../assets/PolicyCalculation/menu2.png");
}

.menu3 {
  background-image: url("../../assets/searchListIcon/tujie.png");
}

.menu4 {
  background-image: url("../../assets/searchListIcon/huodong.png");
}

.submenu-item {
  font-size: 1.125rem;
  margin: 0 4.375rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submenu-item__title {
  margin-top: 0.625rem;
  text-shadow: 0 0.1875rem 0.4375rem rgba(0, 0, 0, 0.24);
}

.tag {
  width: 64.375rem;
  display: flex;
  position: relative;
  margin: -40px auto 0px auto;
  text-align: center;
  justify-content: center;
}

.zct-policy-item-comp {
  position: relative;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  border: 0.0625rem solid #c6d3e5;
}

.zct-policy-item-comp__title {
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.6875rem;
  font-weight: 600;
  width: 90%;
}

.zct-policy-item-comp__title__level {
  flex: none;
  color: #028dfb;
  margin-right: 0.5625rem;
}

.zct-policy-item-comp__title__target {
  color: #333;
  margin-right: 0.5625rem;
}

.zct-policy-item-comp__line:not(:last-child) {
  margin-bottom: 0.375rem;
}

.zct-policy-item-comp__line {
  display: flex;
  font-size: 0.875rem;
  color: #666;
  align-items: center;
}

.zct-policy-item-comp__line__value {
  flex: 1;
}

.zct-policy-item-comp__line:not(:last-child) {
  margin-bottom: 0.375rem;
}

.zct-policy-item-comp__line {
  display: flex;
  font-size: 0.875rem;
  color: #666;
  align-items: center;
}

.zct-policy-item-comp__line__value {
  flex: 1;
}

.date-icon {
  width: 0.875rem;
  height: 0.9375rem;
  margin-right: 0.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAAA7ElEQVQ4je3TPyuGURjH8c9zuycbsbBYrJLRjjegFCV5AxaLbF6AwYBBVj2TbM+uTAzKbMIgMVgkT+mqc+p4/Mlt9qurzrl+53yvznXOabU7FwrtYBnb2PJRLRxhDpvYzW4J6cczKjxhsAcyips0vsRkNqpU8R4TaZ7z83gsYqwAhr+KWyzGZAPDGMdJWtTGFAaKGMJZ8o8xjRGsB6RORvShgxlcYa3nONGDQ8ziFSspX9fFoqi053tF1YOv3OqHTb/WP+SzyttZwHmDvUv5a5SQO1w3gDzkQUC66MNpA0CpbvQkHtjbHwEv2H8H2awpYU6LD0gAAAAASUVORK5CYII=)
    no-repeat;
  background-size: contain;
}

.unit-icon {
  width: 0.875rem;
  height: 0.9375rem;
  margin-right: 0.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAABN0lEQVQ4jb3UvStGYRgG8J+PiJIikiQymEhZFIOBwVeZLP4Q+StMSllk8q4GSRkkm4/BIGGxsTD4CL3oqUc9vR2d9zW46tS5r+fc13Od6zznrirsnojYwZTK8IphXFQnbV0VigQ0oDXcVGcs7qEDLWjDFs4SLlwrpU21GUI3uEvqazSVcFelTVmOajK4qpw609EC6vAe16ejo80YbhCZKEfoEht4Qj060Yd1vETHjejNEzrFQVIfo4jDhDvCYtqUldGfkOUoZLKMz5jHPNqxFJ2FV5spJ6M3PCRCb1EgZPYRnymWI7SPtaTuwQBWEy64Gv+3jEbjWXqOn3kE3ZiL5yhsPlmO0CAKGfx2pY5SnMcT/oOhX36hXKFZ3Cb1I5p/EwqDKSDMllKM4T5n4/6QXZiQXzmu8oFvVdc3lSaHQ68AAAAASUVORK5CYII=)
    no-repeat;
  background-size: contain;
}

.zct-policy-item-comp__rect {
  position: absolute;
  top: 0;
  font-size: 1rem;
  color: #fff;
  background: url("../../assets/PolicyCalculation/orange-flag.png") no-repeat;
  z-index: 0;
  height: 4.625rem;
  width: 2.25rem;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  align-items: baseline;
  right: 0.9375rem;
  padding-top: 0.625rem;
  line-height: 1.2;
  background-size: cover;
}

.active {
  background-color: #028dfb !important;
}

.filter-wrapper {
  width: 95%;
  margin: 0px auto;
  border-radius: 0;
  padding: 10px 0px 30px 0px;
}

.filter-wrapper .filter-item {
  display: flex;
  text-align: center;
  margin-bottom: 0.625rem;
  width: 100%;
}

.filter-wrapper .filter-item__label {
  font-size: 1rem;
  color: #333;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  white-space: nowrap;
  margin-top: 10px;
}

.filter-wrapper .filter-item__options {
  width: 800px;
  overflow: hidden;
}

.topic-item {
  color: #333;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.11);
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
  padding-top: 0.9375rem;
}

.topic-item .title {
  width: 80%;
  height: 2.6875rem;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .filter-wrapper .filter-item__options div {
  justify-content: space-around;
  float: left;
  margin: 9px 8px 0px 10px;
  border-radius: 1.25rem !important;
  padding: 0.3375rem 0.9375rem;
  color: #fff;
  font-size: 0.875rem;
  background-color: #a3c8e5;
  user-select: none;
} */

.policy-decode__list {
  width: 95%;
  margin: 0 auto;
  margin-top: 0.625rem;
}

.cover {
  height: 12rem;
  margin-bottom: 0.9375rem;
  border: 0.0625rem solid #f2f2f2;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 0.9375rem;
}

.topic-item .bottom-warp {
  font-size: 0.8125rem;
}

.p-b-10 {
  padding-bottom: 0.625rem;
}

.row-between {
  justify-content: space-between;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topic-item .light {
  color: #c9c9c9;
  margin: 0 0.625rem;
}
</style>
