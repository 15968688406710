<template>
  <div>
    <el-dialog title="提示" :visible.sync="isshow" width="30%">
      <span>还未登录，请先登录系统</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isshow = false" style="line-height: 40px;padding: 0px 20px;">取 消</el-button>
        <el-button type="primary" @click="isshow = false" style="line-height: 40px;padding: 0px 20px;">确
          定</el-button>
      </span>
    </el-dialog>
    <div class="content">
      <div class="policy-info-detail-page__main__top">
        <div style="border-bottom: 0.0625rem solid #eee;">
          <div class="policy-info-detail-page__main__top__title"><span class="m-l-10 m-r-10">{{ ProjectInfo.title
          }}</span>
          </div>
          <div class="el-row">
            <div class="el-col el-col-2">&nbsp;</div>
            <div class="flex el-col"><i class="blue-round-icon"></i>
              <div class="field-item__line">项目标签：</div>
              <div class="field-item__value">
                {{ ProjectInfo.Lables }}
              </div>
            </div>
            <div class="el-col el-col-2">&nbsp;</div>
            <div class="flex el-col"><i class="blue-round-icon"></i>
              <div class="field-item__line">扶持金额：</div>
              <div class="field-item__value">
                {{ ProjectInfo.Support }}
              </div>
            </div>
            <div class="el-col el-col-2">&nbsp;</div>
            <div class="flex el-col"><i class="blue-round-icon"></i>
              <div class="field-item__line">关联政策：</div>
              <div class="field-item__value">
                {{ ProjectInfo.Policy }}
              </div>
            </div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col"><i class="blue-round-icon"></i>
            <div class="field-item__line">开始时间：</div>
            <div class="field-item__value">
              {{ ProjectInfo.BeginTime }}
            </div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col"><i class="blue-round-icon"></i>
            <div class="field-item__line">结束时间：</div>
            <div class="field-item__value">
              {{ ProjectInfo.EndTime }}
            </div>
          </div>
        </div>
        <div class="policy-info-detail-page__main__bottom"><!---->
          <div class="policy-info-detail-page__main__bottom__content" v-html="ProjectInfo.Content">
          </div>
        </div>
      </div>
      <div class="policy-info-detail-page__main__top">
        <div class="policy-info-detail-page__main__top__title"><span class="m-l-10 m-r-10">企业基本详情</span></div>
        <div class="el-row">
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col"><i class="blue-round-icon"></i>
            <div class="field-item__line">企业名称：</div>
            <div class="field-item__value">
              {{ Enterprise.Enterprisename }}
            </div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col"><i class="blue-round-icon"></i>
            <div class="field-item__line">所属区镇：</div>
            <div class="field-item__value">
              {{ Enterprise.ObjectTab }}
            </div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col"><i class="blue-round-icon"></i>
            <div class="field-item__line">产业类型：</div>
            <div class="field-item__value">
              {{ Enterprise.RelatedPolicy }}
            </div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col"><i class="blue-round-icon"></i>
            <div class="field-item__line">企业类型：</div>
            <div class="field-item__value">
              {{ Enterprise.Support }}
            </div>
          </div>
        </div>
      </div>
      <div class="policy-info-detail-page__main__top">
        <div class="policy-info-detail-page__main__top__title"><span class="m-l-10 m-r-10">资料附件</span></div>
        <div>
          <div>
            <el-upload v-loading="loading" :disabled="filedisabled" element-loading-text="努力上传中"
              element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
              class="upload-demo" drag action="" :on-preview="handlePreview" :on-remove="handleRemove"
              :file-list="fileList" list-type="picture" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="policy-info-detail-page__main__top">
        <div style="width: 90%;padding: 10px 25px;">
          <div class="aui-timeLine b-line">
            <ul class="aui-timeLine-content">
              <span id="chuliliucheng"></span>
              <li class="aui-timeLine-content-item" v-for="(item, index) in projectflow" :key="index">
                <em class="aui-timeLine-content-icon"></em>
                <p style="font-size:18px;">{{ item.status }} <span style="margin-left: 10px;font-size:13px;">{{
                  item.posttime }}</span></p>

                <!-- <p style="margin-top: 10px;font-size:13px;">处理人：{{ item.name }}</p> -->
                <p style="margin-top: 10px;font-size:10px;">
                  {{ item.remark }}
                </p>
                <p style="margin-top: 10px;font-size:10px;">
                <p>附件：
                <p style="font-size: 12px;margin: 3px auto;" v-for="(item1, index1) in item.attachments" :key="index1" @click="handlePreviewproject(item1.file_path)">
                  {{ item1.file_name }}
                </p>
                </p>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>
<script>
import request from '@/api/request'
import siteFooter from "@/components/siteFooter";
export default {
  name: "PolicySearch",
  components: {
    'siteFooter': siteFooter,
  },
  data() {
    return {
      ProjectInfo: {
        title: '',
        Lables: '',
        Policy: '',
        Support: '',
        Content: '',
        BeginTime: '',
        EndTime: ''
      },
      Enterprise: {
        Enterprisename: '',
        ObjectTab: '',
        RelatedPolicy: '',
        Support: '',
      },
      filedisabled: true,
      isshow: false,
      fileList: [],
      loading: false,
      Detailid: 0,
      projectflow: []
    }
  },
  created() {
    this.getEnterpriseDetail();
    this.getPolicyList();
    this.getfilelist();
    this.Detailid = this.$route.params.id
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList;
      // console.log(fileList);
    },
    handlePreviewproject(file) {
      window.open(file, '_blank')
    },
    handlePreview(file) {
      window.open(file.path, '_blank')
    },
    getfilelist() {
      let url = "/api/Project/GetProjectLedgerDetail";
      let reqObj = {
        id: this.$route.params.id
      };
      // console.log(reqObj);
      request.post(url, reqObj).then((res) => {
        let iconurl = '';
        for (let i = 0; i < res.data.Data.Attaches.length; i++) {
          if (res.data.Data.Attaches[i].file_ext == 'doc' || res.data.Data.Attaches[i].file_ext == 'docx') {
            iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430144073.png';
          } else if (res.data.Data.Attaches[i].file_ext == 'xls' || res.data.Data.Attaches[i].file_ext == 'xlsx') {
            iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430335953.png';
          } else if (res.data.Data.Attaches[i].file_ext == 'pdf') {
            iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081429127427.png';
          } else {
            iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081428492318.png';
          }
          this.fileList.push({
            name: res.data.Data.Attaches[i].file_name,
            path: res.data.Data.Attaches[i].file_path,
            ext: res.data.Data.Attaches[i].file_ext,
            size: res.data.Data.Attaches[i].file_size,
            url: iconurl
          })
          // console.log(this.fileList)
        }
      });
    },
    getPolicyList() {
      let url = "/api/Project/GetProjectDetails";
      let reqObj = {
        id: this.$route.params.id
      };
      // console.log(reqObj);
      request.post(url, reqObj).then((res) => {
        // console.log(res);
        this.ProjectInfo = {
          title: res.data.Data.Project[0].Title,
          Lables: res.data.Data.Project[0].Lables.join('、'),
          Policy: res.data.Data.Project[0].Policy,
          Support: res.data.Data.Project[0].Support,
          Content: res.data.Data.Project[0].Content,
          BeginTime: res.data.Data.Project[0].BeginTime.replace('T', ' '),
          EndTime: res.data.Data.Project[0].EndTime.replace('T', ' ')
        };
        this.projectflow = res.data.Data.Liucheng
        // // let iconurl = '';
        // // // this.fileList = [...res.data.Data.Attaches.map(item => { return { name: item.file_name, path: item.file_path, ext: item.file_ext, size: item.file_size } })];
        // // for (let i = 0; i < res.data.Data.Attaches.length; i++) {
        // //   if (res.data.Data.Attaches[i].file_ext == 'doc' || res.data.Data.Attaches[i].file_ext == 'docx') {
        // //     iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430144073.png';
        // //   } else if (res.data.Data.Attaches[i].file_ext == 'xls' || res.data.Data.Attaches[i].file_ext == 'xlsx') {
        // //     iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430335953.png';
        // //   } else if (res.data.Data.Attaches[i].file_ext == 'pdf') {
        // //     iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081429127427.png';
        // //   } else {
        // //     iconurl = 'https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081428492318.png';
        // //   }
        // //   this.fileList.push({
        // //     name: res.data.Data.Attaches[i].file_name,
        // //     path: res.data.Data.Attaches[i].file_path,
        // //     ext: res.data.Data.Attaches[i].file_ext,
        // //     size: res.data.Data.Attaches[i].file_size,
        // //     url: iconurl
        // //   })
        // //   console.log(this.fileList)
        // }
      });
    },
    getEnterpriseDetail() {
      let url = "/api/Project/GetQyDetail";
      let reqObj = {
        id: localStorage.getItem("USERID")
      };
      request.get(url, reqObj).then((res) => {
        // console.log(res);
        if (res.data.Status == 0) {
          this.isshow = true;
        } else {
          this.Enterprise = {
            Enterprisename: res.data.Data.name,
            ObjectTab: res.data.Data.s2,
            RelatedPolicy: res.data.Data.s1,
            Support: res.data.Data.s3,
          }
        }
      });
    },
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  background-color: transparent !important;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
}

.policy-info-detail-page__main__top {
  padding: 1.375rem 0 0.75rem 0;
  position: relative;
  background-color: #ffffff;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.policy-info-detail-page__main__top__title {
  font-size: 1.25rem;
  color: #666;
  font-weight: bold;
  margin-bottom: 1.25rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.875rem;
}

.m-r-10 {
  margin-right: 0.625rem;
}

.m-l-10 {
  margin-left: 0.625rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.el-row {
  position: relative;
  box-sizing: border-box;
}

.flex.el-col {
  margin-bottom: 0.625rem;
  align-items: baseline;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-col-8 {
  width: 33.33333%;
}


.blue-round-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABWUlEQVQ4ja2VQUrDQBSGv06ilWhFuunGChYPIkiv4U68gxfwDuIJPEJ17w3caRd1XaSVYsW28uSNTIeZiVP8IYS89/9/JsmbP43T8zkRVEAb2AO2gRL4Aj6Bd2AMzELSMlBrAofAQaC3pccu0AHegFdgbWW+6T7QA4rY8j3IjVvACzCxLeNwhHCSYWhRqO73yaypPPIx0Mg0tGiofsc1PfJWvQlE37Wmlb7L/4D4VEbHJoU+cAc86rlfw2+XOocxnAHXTq/nXN9HNC2jgx3DZaR+kdA0TWQDWHQz64JCTJcJwiizLlgYf4t5uInUbxOauZhOE4QH4AoYapgM9XqQ0ExLTZtOgjSoMfExNhpfkwxRCuIzs1tzVPPB/oKl/bDW9EPf12pDw5XqxWctRCRwJRdzVyx80Yn+B34ySePJJdQgyA/tJpnbZ+cfJckueSthvNC+jGH4HwV8AyYIQ+PE3PJ4AAAAAElFTkSuQmCC) no-repeat;
  width: 0.9375rem;
  height: 0.9375rem;
  background-size: contain;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: sub;
}

.field-item__line {
  display: flex;
  font-size: 1rem;
  color: #666;
  width: 6.25rem;
}

.field-item__value {
  color: #333;
  font-size: 1rem;
  flex-basis: 73%;
  text-align: left;
}

.flex.el-col {
  margin-bottom: 0.625rem;
  align-items: baseline;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-col-14 {
  width: 58.33333%;
}

.policy-info-detail-page__main__top__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
}

.collect,
.share {
  border-radius: 1.875rem;
  border-color: #a9b8cd;
  color: #2664ca;
  border: 1px solid #a9b8cd;
  padding: 10px 30px;
}


.share-comp {
  cursor: pointer;
}

.policy-info-detail-page__main__bottom {
  text-align: left;
  text-indent: 2rem;
  padding: 1.25rem 1.25rem;
}

.policy-info-detail-page__main__bottom__space {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25rem 0;
}

.el-upload {
  display: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.el-upload-list__item:first-child {
  margin-top: 0.625rem;
}

.el-upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 0.875rem;
  color: #606266;
  line-height: 1.8;
  margin-top: 0.3125rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 100%;
}

.el-upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 2.5rem;
  overflow: hidden;
  padding-left: 0.25rem;
  text-overflow: ellipsis;
  transition: color .3s;
  white-space: nowrap;
}

.el-upload-list__item-status-label {
  position: absolute;
  right: 0.3125rem;
  top: 0;
  line-height: inherit;
  display: none;
}

.el-upload-list__item-name [class^="el-icon"] {
  height: 100%;
  margin-right: 0.4375rem;
  color: #909399;
  line-height: inherit;
}

.upload-demo {
  text-align: left;
  margin-left: 10px;
}
</style>

<style>
.el-upload-list--picture .el-upload-list__item {
  overflow: hidden;
  z-index: 0;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 10px;
  width: 45% !important;
  padding: 10px 10px 10px 90px;
  height: 92px;
  margin: 10px 30px 10px 0px;
  display: inline-block;
}

.aui-timeLine {
  background-color: #fff;
  font-size: 15px;
  color: #6e6e6e;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.aui-timeLine-content {
  margin-left: 16px;
  border-left: 1px solid #e4e5e9;
}

.aui-timeLine-content-item {
  padding: 16px 12px 16px 0;
  margin-left: 16px;
  position: relative;
  text-align: left;
  counter-increment: my-custom-counter -1;
}

.aui-timeLine-content-item:last-child {
  margin-top: 16px;
  padding-top: 0;
  color: #ff8785;
}

.aui-timeLine-content-item:last-child:before {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: -24px;
  background-color: #fbbfbf;
  border-radius: 99px;
}

.aui-timeLine-content-icon {
  width: 8px;
  height: 8px;
  border-radius: 99px;
  background-color: #e4e5e9;
  content: "";
  position: absolute;
  z-index: 1;
  left: -16px;
  display: block;
  top: 19px;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.aui-timeLine-content-item:last-child>.aui-timeLine-content-icon {
  top: 3px;
}

.aui-timeLine-content-item:last-child>.aui-timeLine-content-icon {
  background-color: #f23030;
  width: 10px;
  height: 10px;
}
</style>