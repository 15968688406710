<template>
  <div>
    <div style="width:950px;margin:30px auto;background-color:#ffffff;">
      <img src="../../img/policy/login-intro-top.0b5006e.png" style="width:100%;">
      <div class="enterprise-name">
        <div class="require">*</div>
        <div class="label">企业名称：</div>
        <div class="value">
          {{ CompanyName }}
        </div>
        <div class="raleted-box" @click="AffiliatedEnterprise" v-if="usertype!='ENTERPRISE'"><i class="el-icon-link"></i> <span>关联企业</span></div>
        <div class="raleted-box" @click="AffiliatedEnterprisetab" v-if="userid"><i class="el-icon-paperclip"></i> <span>选择企业标签</span></div>
      </div>
      <div>
        <el-table :data="PolicyActuarial.tableData">
          <el-table-column align="center" type="index" label="#"  :index="indexMethod" />
          <el-table-column align="center" label="政策名称" prop="Zcname" />
          <el-table-column align="center" label="匹配度" prop="PiPeiDu" />
          <el-table-column align="center" label="可能获得扶持资金" prop="Support" />
          <el-table-column align="center" label="状态" prop="Status" />
          <el-table-column align="center" label="操作" width="200">
            <template #default="scope">
              <el-button link type="primary" @click="policySearchDetail(scope.row.Id)">
                匹配情况
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="
          width: 900px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
        <el-pagination background layout="prev, pager, next" :total="PolicyActuarial.total"
          @current-change="handleCurrentChangeactivity">
        </el-pagination>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>

<script>
import demandsHeader from "@/components/demandsHeader";
import professionalsBanner from "@/view/PolicyCalculation/components/pageBanner";
import siteFooter from "@/components/siteFooter";
import request from '@/api/request'
export default {
  components: {
    'demandsHeader': demandsHeader,
    'professionalsBanner': professionalsBanner,
    'siteFooter': siteFooter,
  },
  data() {
    return {
      PolicyActuarial: {
        tableData: [], // 数据列表
        total: 0
      },
      pageSize: 10,
      CompanyName: '',
      usertype:'',
      userid:''
    };
  },

  created() {  //自动加载数据，挂在完成，子函数created也可以
    // this.getInitList()
    this.CompanyName = localStorage.getItem("COMPANYNAME");
    this.usertype=localStorage.getItem('USERTYPE');
    this.userid=localStorage.getItem("USERID");
    this.actuarialList()
  },
  methods: {
    indexMethod(index){
      return index+1;
    },
    policySearchDetail(id) {
      this.$router.push('/PolicyCalculation/policySearchDetail/' + id);
      // this.$router.push('/policySearchDetail')
    },
    AffiliatedEnterprise() {
      this.$router.push('/personalcenter/0');
    },
    AffiliatedEnterprisetab() {
      this.$router.push('/personalcenter/1');
    },
    async actuarialList(pageIndex) {
      request.post("/api/Policy/Matching",
        {
          pageSize: this.pageSize,
          pageIndex: pageIndex
        }
      )
        .then((result) => {  //代表成功获取的信息
          result.data.Data.data.forEach(item => {
            item.PiPeiDu = item.PiPeiDu;  // 将 yourField 替换为你需要处理的字段名
          });
          this.PolicyActuarial.tableData = result.data.Data.data;
          this.PolicyActuarial.total = result.data.Data.total;
          // this.pageSize = result.data.data.length;   //检测显示数据的长度是一页要展示的条数
          // this.handleCurrentChange(1);
        })
    },
    handleCurrentChangeactivity(val) {
      this.actuarialList(val);
    },
  },
};
</script>
<style scoped>
.enterprise-name {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: #EEEEEE;
}

.enterprise-name .require {
  font-size: 0.875rem;
  color: #cf0b0b;
  margin-right: 0.3125rem;
}

.enterprise-name .label {
  font-size: 0.875rem;
  color: #333;
}

.enterprise-name .value {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.enterprise-name .no-value {
  font-size: 0.875rem;
  color: #333;
}

.enterprise-name .raleted-box {
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 0.9375rem;
  color: #028dfb;
}

.enterprise-name .raleted-box i {
  font-size: 1.125rem;
  position: relative;
  top: 0.125rem;
}
</style>