<template>
  <div>
    <div class="header">
      <div class="header-banner">
        <div class="logo-box">
          <img src="../images/logo.png" alt="" srcset="" @click="goindex" />
        </div>
        <ul>
          <li :class="{ active: index == isactive }" v-for="(item, index) in systemNavigation" :key="index"
            @click="navigationSelect(item.url, index)">
            <a>{{ item.name }}</a>
          </li>
          <li v-if="!islogin">
            <a href="#">
              <span @click="login"><i class="el-icon-user"></i>登录</span>
              <span style="padding: 0px 5px; font-size: 17px">/</span>
              <span @click="Register"><i class="el-icon-edit-outline"></i>注册</span>
            </a>
          </li>
          <li class="UserSpacehover" v-if="islogin">
            <i class="el-icon-user"></i>用户空间
            <ul class="UserSpace">
              <li>
                <a @click="navigationSelect('/personalcenter/0', -1)">个人中心</a>
              </li>
              <li><a href="#" @click="Logout">退出</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "demandsHeader",
  components: {},
  data() {
    const systemNavigation = [
      { url: "/", name: "首页" },
      { url: "/PolicyCalculation/policyIndex", name: "政策计算" },
      { url: "/project/projectApply", name: "项目申报" },
      { url: "/demandsadd", name: "诉求直通" },
      { url: "/activity", name: "活动路演" },
      { url: "/declare/digitalMapOffer", name: "数字地图" },
      { url: "/professionals", name: "专业服务" },
    ];
    return {
      systemNavigation,
      isactive: -1,
      PageRouting: "",
      islogin: 0
    };
  },
  created() {
    this.PageRouting = window.location.href.substring(
      window.location.href.lastIndexOf("/")
    );
    this.isactive = this.systemNavigation.findIndex(
      (value) => value.url == this.PageRouting
    );
    this.checklogin();
  },
  methods: {
    login() {
      this.$router.push('/login');
    },
    Register() {
      this.$router.push('/register');
    },
    checklogin() {
      if (localStorage.getItem("USERID")) {
        this.islogin = 1
      } else {
        this.islogin = 0
      }
    },
    goindex(){
      this.$router.push('/');
    },
    navigationSelect(url, index) {
      this.isactive = index;
      this.$router.push(url);
    },
    Logout() {
      this.islogin = 0;
      localStorage.removeItem("USERID");
      localStorage.removeItem("USERTYPE");
      localStorage.removeItem("COMPANYNAME");
      localStorage.removeItem("QYID");
      localStorage.removeItem("TOKEN");
      this.$router.push('/');
    },
  },
};
</script>
<style scoped>
@import url("../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

a,
li {
  color: #000;
  list-style: none;
  text-decoration: none;
  user-select: none;
}

ul {
  white-space: nowrap;
}

.active a {
  color: #0775dd;
}

.active a:hover {
  color: #0775dd;
}

li a:hover {
  color: #333333;
}

.UserSpace {
  width: 112px;
  text-align: center;
  position: absolute;
  display: none;
  margin-top: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.UserSpace:before {
  content: "";
  width: 1px;
  height: 1px;
  border: 8px solid transparent;
  border-bottom-color: #cccccc;
  position: absolute;
  top: 0;
  right: 55%;
  margin-top: -17px;
}

.UserSpace:after {
  content: "";
  width: 1px;
  height: 1px;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
  position: absolute;
  top: 0;
  right: 55%;
  margin-top: -16px;
}

.element {
  width: 1px;
  height: 1px;
}

.UserSpace li {
  width: 80px;
  float: right;
  text-align: left;
  /* background-color: #E6F4FF; */
}

.UserSpace li a {
  width: 80px;
  display: inline-block;
  font-size: 0.9rem;
  padding: 15px 15px;
}

.UserSpace li:hover {
  color: #35a4fc;
  background-color: #e6f4ff;
}

.UserSpace li:hover a {
  color: #35a4fc;
}

.UserSpacehover:hover ul {
  display: block;
}

.header {
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding-bottom: 25px;
  justify-content: space-between;
}

.header-banner {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0px auto;
}

.header-banner ul li {
  color: #000;
  font-size: 1.125rem;
  padding: 0px 16px;
  display: inline-block;
}

.header-banner ul li,
a:hover {
  color: #000;
}

.header-banner ul li:last-child {
  color: #333;
  font-size: 0.9rem;
  padding: 0px 16px;
  display: inline-block;
}

.logo-box {
  width: 300px;
  height: 42px;
  text-align: center;
  /*  display: flex;
    position: relative; */
}

.logo-box img {
  width: 100%;
  height: 42px;
  line-height: 7rem;
}
</style>