var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"layout-default is-home",staticStyle:{"width":"1200px"}},[_c('div',[_c('div',{staticClass:"ai-qa-page"},[_c('div',{staticClass:"ai-qa-page-main",staticStyle:{"z-index":"2001"}},[_c('div',{attrs:{"aria-modal":"true"}},[_c('div',{},[_c('div',{staticClass:"ai-qa-content"},[_c('div',{staticClass:"ai-qa-content__chat"},[_c('div',{staticClass:"ai-qa-current-date",domProps:{"innerHTML":_vm._s(_vm.formatDate)}}),_vm._m(0),_c('div',{ref:"chatRecord",staticClass:"chat-record",attrs:{"id":"chatRecord"}},[_vm._m(1),_vm._m(2),_vm._l((_vm.questionList),function(item,index){return _c('div',{key:item.id,staticClass:"message-wrapper position-right"},[_c('div',{staticClass:"c2c-layout position-right"},[_c('div',{staticClass:"col-1"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.defaultMy}})]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"chat-bubble"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"message-content message-send"},[_c('div',{staticClass:"username",staticStyle:{"text-align":"right"}},[_vm._v(" 我 ")]),_c('div',{staticClass:"text-box right-text-box"},[_vm._v(" "+_vm._s(item)+" "),_c('div',{staticClass:"showMore"})])])])])])])]),(
                            _vm.answerList &&
                            _vm.answerList[index] &&
                            _vm.answerList[index].length > 0 &&
                            !_vm.answerListFlag[index]
                          )?_c('div',{staticClass:"message-wrapper position-left"},[_c('div',{staticClass:"c2c-layout position-left"},[_vm._m(3,true),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"chat-bubble"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"message-content message-received"},[_c('div',{staticClass:"username"},[_vm._v("小通")]),_c('div',{staticClass:"text-box"},[_c('div',[_vm._v("以下问题是您关心的吗？")]),_vm._l((_vm.answerList[index]),function(item){return _c('div',{key:item.id,staticClass:"question"},[_c('div',{staticClass:"item",on:{"click":_vm.handleItemClick}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("·")]),_vm._v("   "+_vm._s(item.content)+" "),_c('input',{attrs:{"type":"hidden"},domProps:{"value":item.id}}),_c('input',{attrs:{"type":"hidden"},domProps:{"value":item.content}})])])}),_c('div',{staticClass:"showMore"})],2)])])])])])])]):(
                            (!_vm.answerList ||
                              !_vm.answerList[index] ||
                              _vm.answerList[index].length === 0) &&
                            (!_vm.answerListFlag || !_vm.answerListFlag[index])
                          )?_c('div',{staticClass:"message-wrapper position-left"},[_vm._m(4,true)]):_vm._e(),(_vm.answerListFlag[index])?_c('div',[_c('div',{staticClass:"message-wrapper position-left"},[_c('div',{staticClass:"c2c-layout position-left"},[_vm._m(5,true),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"chat-bubble"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"message-content message-received"},[_c('div',{staticClass:"username"},[_vm._v("小通")]),_c('div',{staticClass:"text-box"},[_c('div',[_c('p',{staticStyle:{"text-indent":"43px","line-height":"2em"},domProps:{"innerHTML":_vm._s(
                                                _vm.answerList[index].answer
                                              )}})]),_c('div',{staticClass:"showMore",staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}})])])])])])])])])]):_vm._e()])})],2),_c('div',{staticClass:"send-box"},[_c('div',{staticClass:"send-content-container"},[_c('div',{staticClass:"el-textarea"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.q),expression:"q"}],staticClass:"el-textarea__inner",staticStyle:{"min-height":"32.3333px"},attrs:{"id":"txtKeyword","autocomplete":"off","rows":"4","placeholder":"请输入你要咨询的问题!"},domProps:{"value":(_vm.q)},on:{"keypress":_vm.handleKeyPress,"input":function($event){if($event.target.composing)return;_vm.q=$event.target.value}}})]),_c('div',{staticClass:"send-btn"},[_c('button',{staticClass:"el-button el-tooltip el-button--primary",attrs:{"id":"btnSend","type":"button","aria-describedby":"el-tooltip-9951","tabindex":"0"},on:{"click":_vm.btnSend}},[_c('span',[_vm._v("发送")])])])])])]),_c('div',{staticClass:"ai-qa-content__tool"},[_c('div',{staticClass:"common-problem"},[_c('div',{staticClass:"tool-title"},[_vm._v("常见问题")]),_c('div',{staticClass:"common-problem__list",attrs:{"id":"list"}},_vm._l((_vm.commonQuestionList),function(item,index){return _c('div',{key:item.id,staticClass:"common-problem-item",on:{"click":function($event){return _vm.handleListClick(item)}}},[_c('div',{staticClass:"item-seq",style:(_vm.getStyle(index))},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('span',{attrs:{"id":"contentText"}},[_vm._v(_vm._s(item.content))]),_c('input',{attrs:{"id":"contentId","type":"hidden"},domProps:{"value":item.id}})])}),0)]),_vm._m(6)])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ai-qa-customer"},[_c('div',{staticClass:"ai-qa-customer__container flex-column-center"},[_c('img',{attrs:{"src":require("../../img/custService/kf.05491b4.png")}}),_c('div',{staticClass:"item-text",staticStyle:{"color":"rgb(51, 51, 51)","font-weight":"600"}},[_vm._v(" 智能政务助理 ")]),_c('div',{staticClass:"item-text"},[_vm._v("智能助理")]),_c('div',{staticClass:"customer-info"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-wrapper position-left"},[_c('div',{staticClass:"c2c-layout position-left"},[_c('div',{staticClass:"col-1"},[_c('img',{attrs:{"src":require("../../img/custService/kf.05491b4.png")}})]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"chat-bubble"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"message-content message-received"},[_c('div',{staticClass:"username"},[_vm._v("小通")]),_c('div',{staticClass:"text-box"},[_c('div',[_vm._v(" 您好！我是E企通平台小通，很高兴为您服务！请问有什么可以帮您？ ")]),_c('div',{staticClass:"showMore"})])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qa-shortcut-key"},[_c('div',{staticClass:"guess-you"},[_c('div',{staticClass:"guess-you__title"},[_c('span',{staticClass:"text-special"},[_vm._v("猜")]),_vm._v("你"),_c('br'),_vm._v("想"),_c('span',{staticClass:"text-special"},[_vm._v("问")])]),_c('div',{staticClass:"guess-you__content"},[_c('div',{staticClass:"guess-item"},[_vm._v("奖励")]),_c('div',{staticClass:"guess-item"},[_vm._v("科技小巨人")]),_c('div',{staticClass:"guess-item"},[_vm._v("绿色供应链")])])]),_c('div',{staticClass:"qa-keyword"},[_c('img',{attrs:{"src":require("../../img/custService/政策.png")}}),_c('div',[_vm._v("政策")])]),_c('div',{staticClass:"qa-keyword"},[_c('img',{attrs:{"src":require("../../img/custService/申报.png")}}),_c('div',[_vm._v("申报")])]),_c('div',{staticClass:"qa-keyword"},[_c('img',{attrs:{"src":require("../../img/custService/诉求.png")}}),_c('div',[_vm._v("诉求")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1"},[_c('img',{attrs:{"src":require("../../img/custService/kf.05491b4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c2c-layout position-left"},[_c('div',{staticClass:"col-1"},[_c('img',{attrs:{"src":require("../../img/custService/kf.05491b4.png")}})]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"chat-bubble"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"message-content message-received"},[_c('div',{staticClass:"username"},[_vm._v("小通")]),_c('div',{staticClass:"text-box"},[_c('div',[_vm._v(" 不好意思，暂时不能理解您的问题，请转接人工客服：0513-83311717 ")]),_c('div',{staticClass:"showMore"})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1"},[_c('img',{attrs:{"src":require("../../img/custService/kf.05491b4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-information"},[_c('div',{staticClass:"tool-title"},[_vm._v("全国统一政务服务热线")]),_c('div',{staticClass:"contact-information-item"},[_vm._v("12345")]),_c('div',{staticClass:"tool-title p-t-10"},[_vm._v("服务热线")]),_c('div',{staticClass:"contact-information-item"},[_vm._v(" 0513-83111717 ")])])
}]

export { render, staticRenderFns }