<template>
  <div>
    <div>
      <div class="policy-box" :style="bgcStyle[index1 % 3]" v-for="(item1, index1) in policyList" :key="item1.Id">
        <div class="title">{{ item1.Title }}</div>
        <div class="little">
          <div v-for="item2 in item1.Children.slice(0, 3)" :key="item2.Id" class="policy-detail-box">
            <div class="detail-title">
              <span style="display: inline-block; padding-top: 30px">{{
                item2.Title
              }}</span>
            </div>
            <el-table :data="item2.Children" stripe style="width: 100%; overflow: hidden" max-height="280">
              <el-table-column prop="Title" label="项目名称" min-width="75%">
              </el-table-column>
              <el-table-column prop="Amount" label="奖励金额" min-width="25%">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div style="text-align: center" v-if="item1.Children.length > 3">
          <el-button style="margin-bottom: 20px;background-color: #0366CB;color: #fff;border: none;" type="info" plain @click="showMore(item1.Id, index1)">查看更多</el-button>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import request from "@/api/request";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      title: "",
      policyList: [],
      bgcStyle: [
        "background: linear-gradient(to bottom, #2e8ff2, #57bff8)",
        "background: linear-gradient(to bottom, #2DB3F1, #63DEF2)",
        "background: linear-gradient(to bottom, #2AC983, #53E5D1)",
      ],
    };
  },
  mounted() {
    this.initAllList();
  },
  methods: {
    projectPublicity() {
      this.$router.push("/projectPublicity");
    },
    initAllList() {
      request
        .post("/api/Policy/GetTrial", { id: 0 })
        .then((response) => {
          // 请求成功时处理数据
          this.policyList = [...response.data.Data.data];
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },
    showMore(_id, _index) {
      this.$router.push({
        path: "/project/freeToEnjoyDetail",
        query: {
          id: _id,
          index: _index,
        },
      });
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  background-color: #adc4eb;
}

.policy-box {
  width: 80%;
  border-radius: 30px;
  margin: 20px auto 20px auto;
  /* background: linear-gradient(to bottom, #2e8ff2, #57bff8); */
}

.little {
  display: flex;
  /* 将 .little 元素设置为 flex 容器 */
  flex-wrap: wrap;
  /* 允许子元素换行排列 */
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: calc(10% - 37.5px);
  padding-right: 20px;
}

.policy-detail-box {
  width: 30%;
  height: 360px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* 添加阴影效果 */
  overflow: hidden;
  position: relative;
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.title {
  font-size: 40px;
  color: white;
  text-align: center;
  /* 文字居中 */
  padding-top: 20px;
}

.detail-title {
  background: #d5efff;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  color: #2d8ef1;
  text-align: center;
}

/* .el-table--scrollable-y ::-webkit-scrollbar {
  display: none; 
} */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 0px;
  /*滚动条的背景区域的圆角*/
  background-color: white;
  /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: #e3e3e3;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /*滚动条的圆角*/
  background-color: #8b8b8b;
  /*滚动条的背景颜色*/
}

.base-header-wrapper .submenu-wrapper[data-v-581d1334] {
  z-index: 0;
  position: absolute;
  top: 5.9375rem;
  left: 0;
  width: 100%;
}

.base-header-wrapper .submenu-wrapper .submenu-banner[data-v-581d1334] {
  background-repeat: no-repeat;
  background-size: cover;
}

.base-header-wrapper .submenu-wrapper .submenu-banner .box[data-v-581d1334] {
  display: flex;
  flex-direction: column;
  width: 64.375rem;
  margin: 0 auto;
}

.base-header-wrapper .submenu-wrapper .submenu-banner-title[data-v-581d1334] {
  font-size: 2.25rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0.3125rem 0.25rem rgba(0, 54, 114, 0.6);
}

.base-header-wrapper .submenu-wrapper .submenu-banner-title .large[data-v-581d1334] {
  font-style: normal;
  font-size: 3.375rem;
  margin-right: 0.625rem;
}

.base-header-wrapper .submenu-wrapper .submenu-banner-title[data-v-581d1334] {
  font-size: 2.25rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0.3125rem 0.25rem rgba(0, 54, 114, 0.6);
}

.base-header-wrapper .submenu-wrapper .submenu-banner .line[data-v-581d1334] {
  width: 18.125rem;
  height: 0.0625rem;
  background-color: #fff;
  opacity: 0.5;
  margin: 0.3125rem 0 0.625rem;
}

.base-header-wrapper .submenu-wrapper .submenu-banner-entitle[data-v-581d1334] {
  font-size: 0.875rem;
  color: #fff;
  letter-spacing: 0.125rem;
}

.base-header-wrapper .submenu-wrapper .submenu-list[data-v-581d1334] {
  position: absolute;
  bottom: 0.875rem;
  left: 0;
  right: 0;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner[data-v-581d1334] {
  width: 64.375rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item[data-v-581d1334] {
  font-size: 1.125rem;
  margin: 0 4.375rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round[data-v-581d1334] {
  width: 5rem;
  height: 5rem;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .circle[data-v-581d1334] {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round.animate .circle[data-v-581d1334] {
  transform: scale(1.2);
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round .circle[data-v-581d1334] {
  transform: scale(1);
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item__icon[data-v-581d1334] {
  width: 1.875rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item__title[data-v-581d1334] {
  margin-top: 0.625rem;
  text-shadow: 0 0.1875rem 0.4375rem rgba(0, 0, 0, 0.24);
}

.base-header-wrapper .submenu-wrapper .submenu-banner[data-v-581d1334] {
  background-repeat: no-repeat;
  background-size: cover;
}

.base-header-wrapper .submenu-wrapper .submenu-banner .box[data-v-581d1334] {
  display: flex;
  flex-direction: column;
  width: 64.375rem;
  margin: 0 auto;
}

.base-header-wrapper .submenu-wrapper .submenu-banner-title[data-v-581d1334] {
  font-size: 2.25rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0.3125rem 0.25rem rgba(0, 54, 114, 0.6);
}

.base-header-wrapper .submenu-wrapper .submenu-banner-title .large[data-v-581d1334] {
  font-style: normal;
  font-size: 3.375rem;
  margin-right: 0.625rem;
}

.base-header-wrapper .submenu-wrapper .submenu-banner .line[data-v-581d1334] {
  width: 18.125rem;
  height: 0.0625rem;
  background-color: #fff;
  opacity: 0.5;
  margin: 0.3125rem 0 0.625rem;
}

.base-header-wrapper .submenu-wrapper .submenu-banner-entitle[data-v-581d1334] {
  font-size: 0.875rem;
  color: #fff;
  letter-spacing: 0.125rem;
}

.round {
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.round .circle {
  transform: scale(1.2);
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round[data-v-581d1334] {
  width: 5rem;
  height: 5rem;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round.animate .circle[data-v-581d1334] {
  transform: scale(1.2);
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round .circle[data-v-581d1334] {
  transform: scale(1);
}

.circle {
  position: absolute;
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  z-index: -2;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .circle[data-v-581d1334] {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round.animate .circle[data-v-581d1334] {
  transform: scale(1.2);
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round .circle[data-v-581d1334] {
  transform: scale(1);
}

.base-header-wrapper .submenu-wrapper .submenu-list__inner .submenu-item .round.animate .circle[data-v-581d1334] {
  transform: scale(1.2);
}

.custom-table .el-table__body-wrapper {
  display: flex;
}

.custom-table .el-table__body {
  flex: 1;
}

.custom-table .el-table__body-wrapper .el-table__body {
  width: 70%;
}

.custom-table .el-table__fixed-body-wrapper {
  flex: none;
  width: 30%;
}
</style>
