<template>
  <div>
    <div style="width:950px;margin:30px auto;background-color:#ffffff;padding:30px 0px;">
      <el-table :data="declareTodays.tableData">
        <el-table-column align="center" label="标题">
          <template #default="scope">
            <span style="cursor: pointer !important;" >{{ scope.row.Title }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="匹配度" prop="PiPeiDu" />
        <el-table-column align="center" label="级别" prop="Sort" />
        <el-table-column align="center" label="发布日期" prop="Addtime" />
        <el-table-column align="center" label="申报截止时间" prop="RemainderDay" />
        <el-table-column align="center" label="申报入口">
          <template #default="scope">
            <el-button style="background-color: #409EFF;color: #ffffff;" :type="scope.row.type"
              @click="signUp(scope.row.Id)" size="small">
              立即申报
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="
          width: 900px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
        <el-pagination background layout="prev, pager, next" :total="declareTodays.total"
          @current-change="handleCurrentChangeactivity">
        </el-pagination>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>

<script>
import request from '@/api/request';
import siteFooter from "@/components/siteFooter";
export default {
  name: "policyinterpretation",
  components: {
    'siteFooter': siteFooter,
  },
  data() {
    return {
      declareTodays: {
        tableData: [], // 数据列表
        total: 0
      },
      pageSize: 10,
    };
  },

  created() {  //自动加载数据，挂在完成，子函数created也可以
    // this.getInitList()
    this.actuarialList()
  },
  methods: {
    async actuarialList(pageIndex) {
      request.post("/api/Policy/Matchingnow",
        {
          pageSize: this.pageSize,
          pageIndex: pageIndex
        }
      )
        .then((result) => {  //代表成功获取的信息
          // console.log(result)
          result.data.Data.data.forEach(item => {
            item.PiPeiDu = item.PiPeiDu;
            item.RemainderDay = item.RemainderDay + '天'
          });
          this.declareTodays.tableData = result.data.Data.data;
          this.declareTodays.total = result.data.Data.total;
        })
    },
    signUp(_id) {
      // console.log(_id);
      this.$router.push("/PolicyCalculation/policySearchDetail/" + _id);
    },
    //页码切换
    handleCurrentChangeactivity(val) {
      this.actuarialList(val);
    },
  },
};
</script>