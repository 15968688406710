<template>
  <div>
    <demandsHeader></demandsHeader>
    <div class="top-header"></div>
    <div class="content">
      <div class="content-all">
        <div
          class="content-p"
          style="background: linear-gradient(42deg, #245eff 1%, #80a2ff 96%)"
        >
          <div style="margin-top: 20px">创新型中小</div>
          <div>企业自测</div>
          <div class="content-button" @click="newspecialindex(1)">立即测评</div>
          <div
            style="
              width: 80%;
              margin: 0 auto;
              font-size: 1rem;
              text-indent: 2em;
              text-align: left;
              margin-top: 10px;
            "
          >
            创新型中小企业具有较高专业化水平较强创新能力和发展潜力，是优质中小企业的基础力量。
          </div>
        </div>
        <div
          class="content-p"
          style="background: linear-gradient(34deg, #14a0d8 7%, #37eab7 92%)"
        >
          <div style="margin-top: 20px">专精特新中小</div>
          <div>企业自测</div>
          <div class="content-button" @click="newspecialindex(2)">立即测评</div>
          <div
            style="
              width: 80%;
              margin: 0 auto;
              font-size: 1rem;
              text-indent: 2em;
              text-align: left;
              margin-top: 10px;
            "
          >
            专精特新中小企业实现专业化、精细化、特色化发展，创新能力强、质量效益好，是优质中小企业的中坚力量。
          </div>
        </div>
        <div
          class="content-p"
          style="background: linear-gradient(30deg, #8058f7 7%, #80a6ff 93%)"
        >
          <div style="margin-top: 20px">专精特新“小巨人”</div>
          <div>企业自测</div>
          <div class="content-button" @click="newspecialindex(3)">立即测评</div>
          <div
            style="
              width: 80%;
              margin: 0 auto;
              font-size: 1rem;
              text-indent: 2em;
              text-align: left;
              margin-top: 10px;
            "
          >
            专精特新“小巨人”企业位于产业基础核心领域、产业链关键环节，创新能力突出、掌握核心技术、细分市场占有率高、质量效益好，是优质中小企业的核心力量。
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import demandsHeader from "@/components/demandsHeader";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    demandsHeader: demandsHeader,
    siteFooter: siteFooter,
  },
  methods: {
    newspecialindex(_id) {
      this.$router.push({
        path: "/newspecialindex",
        query: {
          id: _id,
        },
      });
    },
  },
};
</script>

<style scoped>
.top-header {
  height: 15.625rem;
  background: url(../../images/zjtop.png);
  margin-bottom: 1.5rem;
  width: 100%;
}

.content {
  margin-bottom: 0.9375rem;
  width: 75rem;
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
}
.content-all {
  margin-top: 1.125rem;
  background: #fff;
  min-height: 6.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-p {
  width: 20rem;
  font-size: 2rem;
  color: #fff;
  height: 20rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0.9375rem;
  text-align: center;
}
.content-button {
  cursor: pointer;
  width: 6.75rem;
  height: 2.5rem;
  border-radius: 2.75rem;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #fff;
  margin-left: 105px;
  margin-top: 20px;
}
</style>