<template>
  <div>
    <div class="content" style="margin: 20px auto;text-align: center;background-color: #ffffff;">
      <div class="policy-page-computed-model" @click="rapidActuarial" data-v-0d594a84>
        <div class="el-image" data-v-0d594a84>
          <img src="../../img/policy/left.964d0ad.png" alt="为您量身定制个性化政策" class="el-image__inner">
          <!-- <div class="el-image__placeholder"></div> -->
        </div> <button type="button" class="el-button btn el-button--primary"
          data-v-0d594a84><!----><!----><span>快速精算</span></button>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>
<script>
import siteFooter from "@/components/siteFooter";
export default {
  name: "PolicySearch",
  components: {
    'siteFooter': siteFooter,
  },
  data() {
    return {
      input: "",
      select: "",
    };
  },
  methods: {
    rapidActuarial() {
      this.$router.push('/PolicyCalculation/rapidActuarial')
    },
    declareToday() {
      this.$router.push('/declareToday')
    },
    policySearch() {
      this.$router.push('/policySearch')
    },
    policyInterpretation() {
      this.$router.push('/policyInterpretation')
    },
    policySubscription() {
      this.$router.push('/policySubscription')
    }
  },
};
</script>
