<template>
  <div>
    <div v-if="visible" class="overlay"></div>
    <div class="content">
      <div class="topic-info">
        <div class="topic-title">
          {{ Services.title }}
        </div>
        <div class="topic-attribute">
          <div class="topic-item">
            政策类型：
            {{ Services.type }}
          </div>
          <div class="topic-item">
            政策级别：
            {{ Services.rank }}
          </div>
        </div>
        <div class="collect">
          <div class="collect-content" @click="Collect">
            <span v-if="Services.iscollection == 0"><i class="el-icon-star-off">收藏</i></span>
            <span v-if="Services.iscollection == 1"><i class="el-icon-star-on" style="font-weight: bold;">已收藏</i></span>
            <!-- <span ><i class="el-icon-star-off">收藏</i></span> -->
          </div>
          <div class="collect-content" @click="Share"><i class="el-icon-share">分享</i>
          </div>
        </div>
        <div class="topic-content" v-html="Services.content"></div>
      </div>
    </div>
    <div class="sharecontent" v-if="sharecontent">
      <div class="sharebg">
        <div class="sharebgcontent">
          <div style="width: 95%;max-height: 300px;margin: 0px auto;overflow: hidden;overflow-y: auto;">
            <div class="topic-info">
              <div class="topic-title">
                {{ Services.title }}
              </div>
              <div class="topic-attribute">
                <div class="topic-item">
                  政策类型：
                  {{ Services.type }}
                </div>
                <div class="topic-item">
                  政策级别：
                  {{ Services.rank }}
                </div>
              </div>
              <div class="topic-content" v-html="Services.content"></div>
            </div>
          </div>
        </div>
        <div style="margin-top: 30px;">
          <img :src="shareqrcodeimg" alt="" srcset="" style="width: 200px;height: 200px;">
        </div>
      </div>
      <button v-if="shareqrcodeimg" @click="downloadshareqrcodeimg">下载图片</button>
      <div style="font-size: 29px;color: #ffffff;" @click="sharecontentclose"><i class="el-icon-circle-close"></i></div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>
<script>
import request from "@/api/request";
import siteFooter from "@/components/siteFooter";
export default {
  name: "policyinterpretation",
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      Detailid: 0,
      visible: false,
      sharecontent: false,
      shareqrcodeimg: '',
      Services: {
        title: "",
        type: "",
        rank: "",
        content: "",
        iscollection: 0
      },
    };
  },
  created() {
    this.Detailid = this.$route.params.id;
    // console.log(this.Detailid);
    this.getDetails(this.Detailid);
  },
  methods: {
    downloadshareqrcodeimg() {
      this.downloadIamge(this.shareqrcodeimg, '政策分享');
    },
    downloadIamge(imgsrc, name) { //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    sharecontentclose() {
      this.visible = false;
      this.sharecontent = false;
    },
    Share() {
      request.post('/api/Mp/GetQrcode', {
        id: this.Detailid,
        type: 'policyInterpretation'
      })
        .then(response => {
          // console.log(response)
          if (response.data.Status == 1) {
            this.shareqrcodeimg = response.data.Data
            this.visible = true;
            this.sharecontent = true;
          } else {
            this.$message({
              message: '分享失败',
              type: "error"
            });
          }

        }, error => {
          // console.log('错误', error.message)
        })
    },
    Collect() {
      if (!localStorage.getItem("USERID")) {
        this.$message({
          message: '还未登录，请先登录',
          type: "error",
          duration: 2000
        });
        setTimeout(() => {
          this.$router.push('/login')
        }, 2000)
      } else if (this.Services.iscollection == 0) {
        request.post('/api/Policy/Gocollection', {
          typeid: 1,
          specificid: this.Detailid
        })
          .then(response => {
            // console.log(response)
            this.getDetails(this.Detailid);
            this.$message({
              message: response.data.Msg,
              type: "success"
            });

          }, error => {
            // console.log('错误', error.message)
          })
      } else if (this.Services.iscollection == 1) {
        request.post('/api/Policy/Deletecollection', {
          typeid: 1,
          specificid: this.Detailid
        })
          .then(response => {
            // console.log(response)
            this.getDetails(this.Detailid);
            this.$message({
              message: response.data.Msg,
              type: "success"
            });

          }, error => {
            // console.log('错误', error.message)
          })
      }
    },
    getDetails(id) {
      request.post("/api/Policy/GetDetailsjd", { id: id }).then(
        (response) => {
          // console.log(response);
          var iscollection = 0;
          if (response.data.Data.collection == '登录未收藏') {
            iscollection = 0
          } else if (response.data.Data.collection == '已收藏') {
            iscollection = 1
          }
          this.Services = {
            title: response.data.Data.title,
            type: response.data.Data.zclx,
            rank: response.data.Data.zcjb,
            content: response.data.Data.Content,
            iscollection: iscollection
          };
        },
        (error) => {
          // console.log("错误", error.message);
        }
      );
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  background-color: #ffffff;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.topic-info {
  background-color: #fff;
  padding: 1.25rem;
  box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.11);
}

.global-max-width {
  width: 64.375rem;
  margin: 2em auto 1.25rem;
}

.topic-info .topic-title {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}

.topic-info .topic-attribute {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.25rem 0;
}

.topic-info .topic-content {
  text-align: center;
}

.topic-item {
  color: #333;
  cursor: pointer;
  background: #fff;
  margin-right: 1.875rem;
  box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.11);
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
  padding-top: 0.9375rem;
}

.collect-content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #028dfb;
  border-radius: 1.875rem;
  border: 1px solid #a9b8cd;
  border-color: #a9b8cd;
  color: #2664ca;
  padding: 10px 30px;
  margin: 10px 20px;
}

.collect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust transparency as needed */
  z-index: 9999;
  /* Ensure it sits on top of other content */
}

.sharecontent {
  width: 38.4375rem;
  min-height: 580px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0px auto;
  text-align: center;
  z-index: 9999999999;
  /* Ensure it sits on top of other content */
}

.sharebg {
  width: 100%;
  min-height: 580px;
  background: url('@/images/poster-bg.png') no-repeat 100% 100%;
  background-size: 100% 100%;
  border-radius: 0.5625rem;
}

.sharecontent button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: red !important;
  color: #ffffff;
  border: none;
  margin: 20px auto;
}

.sharebgcontent {
  position: relative;
  top: 20px;
  width: 90%;
  border: 1rpx solid black;
  max-height: 380px;
  padding: 20px 10px;
  margin: 0px auto;
  border-radius: 20px !important;
  background-color: #ffffff;
}

.sharebgcontent>>>img {
  width: 100% !important;
}
</style>