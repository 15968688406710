<template>
  <div>
    <div v-if="visible" class="overlay"></div>
    <div class="content">
      <div class="policy-info-detail-page__main__top">
        <div class="policy-info-detail-page__main__top__title">
          <span class="m-l-10 m-r-10">{{ Services.title }}</span>
        </div>
        <div class="el-row">
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col el-col-8">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">政策文号：</div>
            <div class="field-item__value">
              {{ Services.numbering }}
            </div>
          </div>
          <div class="flex el-col el-col-14">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">发文单位：</div>
            <div class="field-item__value">
              {{ Services.unit }}
            </div>
          </div>
        </div>
        <div class="el-row">
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col el-col-8">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">政策级别：</div>
            <div class="field-item__value">
              {{ Services.label }}
            </div>
          </div>
          <div class="flex el-col el-col-14">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">政策标签：</div>
            <div class="field-item__value">
              {{ Services.labellist }}
            </div>
          </div>
        </div>
        <div class="el-row">
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col el-col-8">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">发布日期：</div>
            <div class="field-item__value">
              {{ Services.publicDate }}
            </div>
          </div>
          <div class="flex el-col el-col-14">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">扶持金额：</div>
            <div class="field-item__value">
              {{ Services.support }}
            </div>
          </div>
        </div>
        <div class="collect">
          <div class="collect-content" @click="Collect">
            <span v-if="Services.iscollection == 0"><i class="el-icon-star-off">收藏</i></span>
            <span v-if="Services.iscollection == 1"><i class="el-icon-star-on" style="font-weight: bold;">已收藏</i></span>
            <!-- <span ><i class="el-icon-star-off">收藏</i></span> -->
          </div>
          <div class="collect-content" @click="Share"><i class="el-icon-share">分享</i>
          </div>
        </div>
        <el-button style="width: 100px; line-height: 40px" :type="Services.type" :disabled="Services.disable"
          @click="gotoSignUp(Services.id)">{{ Services.text }}</el-button>
      </div>
      <div>
        <el-tabs>
          <el-tab-pane label="政策标签">
            <!-- 这里放置“政策指标”相关的内容 -->
            <!-- 政策检索详情 -->
            <!-- v-loading="loading"-->
            <el-table :data="tableData">
              <el-table-column align="center" label="标签" prop="name" />
              <el-table-column align="center" label="匹配情况">
                <template #default="scope">
                  <div style="display: block; align-items: center">
                    <div style="display: inline-block" v-html="scope.row.icon"></div>
                    <span>{{ scope.row.PiPeiDu }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="政策详情">
            <!-- 这里放置“政策详情”相关的内容 -->
            <div v-html="content"></div>
          </el-tab-pane>
          <el-tab-pane label="附件下载">
            <!-- 这里放置“政策详情”相关的内容 -->
            <div v-for="item in Services.fileList" :key="item.id" @click="downloadFile(item.id)"
              style="text-align: left;">
              <a style="color: blue;" :href="item.file_path" :download="item.file_name">{{ item.file_name }}</a>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="sharecontent" v-if="sharecontent">
      <div class="sharebg">
        <div class="sharebgcontent">
          <div style="width: 95%;max-height: 300px;margin: 0px auto;overflow: hidden;overflow-y: auto;">
            <div class="policy-info-detail-page__main__top">
              <div class="policy-info-detail-page__main__top__title">
                <span class="m-l-10 m-r-10">{{ Services.title }}</span>
              </div>
              <div class="el-row">
                <div class="el-col el-col-2">&nbsp;</div>
                <div class="flex el-col el-col-8">
                  <i class="blue-round-icon"></i>
                  <div class="field-item__line">政策文号：</div>
                  <div class="field-item__value">
                    {{ Services.numbering }}
                  </div>
                </div>
                <div class="flex el-col el-col-14">
                  <i class="blue-round-icon"></i>
                  <div class="field-item__line">发文单位：</div>
                  <div class="field-item__value">
                    {{ Services.unit }}
                  </div>
                </div>
              </div>
              <div class="el-row">
                <div class="el-col el-col-2">&nbsp;</div>
                <div class="flex el-col el-col-8">
                  <i class="blue-round-icon"></i>
                  <div class="field-item__line">政策级别：</div>
                  <div class="field-item__value">
                    {{ Services.label }}
                  </div>
                </div>
                <div class="flex el-col el-col-14">
                  <i class="blue-round-icon"></i>
                  <div class="field-item__line">政策标签：</div>
                  <div class="field-item__value">
                    {{ Services.labellist }}
                  </div>
                </div>
              </div>
              <div class="el-row">
                <div class="el-col el-col-2">&nbsp;</div>
                <div class="flex el-col el-col-8">
                  <i class="blue-round-icon"></i>
                  <div class="field-item__line">发布日期：</div>
                  <div class="field-item__value">
                    {{ Services.publicDate }}
                  </div>
                </div>
                <div class="flex el-col el-col-14">
                  <i class="blue-round-icon"></i>
                  <div class="field-item__line">扶持金额：</div>
                  <div class="field-item__value">
                    {{ Services.support }}
                  </div>
                </div>
              </div>
              <el-button style="width: 100px; line-height: 20px" :type="Services.type" :disabled="Services.disable"
                @click="gotoSignUp(Services.id)">{{ Services.text }}</el-button>
            </div>
            <div>
              <el-tabs>
                <el-tab-pane label="政策标签">
                  <!-- 这里放置“政策指标”相关的内容 -->
                  <!-- 政策检索详情 -->
                  <!-- v-loading="loading"-->
                  <el-table :data="tableData">
                    <el-table-column align="center" label="标签" prop="name" />
                    <el-table-column align="center" label="匹配情况">
                      <template #default="scope">
                        <div style="display: block; align-items: center">
                          <div style="display: inline-block" v-html="scope.row.icon"></div>
                          <span>{{ scope.row.PiPeiDu }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="政策详情">
                  <!-- 这里放置“政策详情”相关的内容 -->
                  <div v-html="content"></div>
                </el-tab-pane>
                <el-tab-pane label="附件下载">
                  <!-- 这里放置“政策详情”相关的内容 -->
                  <div v-for="item in Services.fileList" :key="item.id" @click="downloadFile(item.id)"
                    style="text-align: left;">
                    <a style="color: blue;" :href="item.file_path" :download="item.file_name">{{ item.file_name }}</a>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div style="margin-top: 30px;">
          <img :src="shareqrcodeimg" alt="" srcset="" style="width: 200px;height: 200px;">
        </div>
      </div>
      <button v-if="shareqrcodeimg" @click="downloadshareqrcodeimg">下载图片</button>
      <div style="font-size: 29px;color: #ffffff;" @click="sharecontentclose"><i class="el-icon-circle-close"></i></div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>

<script>
import request from "@/api/request";
import demandsHeader from "@/components/demandsHeader";
import professionalsBanner from "@/view/PolicyCalculation/components/pageBanner";
import siteFooter from "@/components/siteFooter";

export default {
  components: {
    demandsHeader: demandsHeader,
    professionalsBanner: professionalsBanner,
    siteFooter: siteFooter,
  },
  data() {
    return {
      Services: {
        title: "",
        numbering: "",
        unit: "",
        label: "",
        labellist: "",
        publicDate: "",
        support: "",
        iscollection: 0
      },
      shareqrcodeimg: '',
      content: "",
      icon: "",
      tableData: [],
      visible: false,
      sharecontent: false,
    };
  },
  created() {
    this.Detailid = this.$route.params.id;
    //自动加载数据，挂在完成，子函数created也可以
    // this.getInitList()
    this.actuarialList(this.$route.params.id);
  },
  methods: {
    downloadshareqrcodeimg() {
      this.downloadIamge(this.shareqrcodeimg, '政策分享');
    },
    downloadIamge(imgsrc, name) { //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    sharecontentclose() {
      this.visible = false;
      this.sharecontent = false;
    },
    Share() {
      request.post('/api/Mp/GetQrcode', {
        id: this.Detailid,
        type: 'policydetail'
      })
        .then(response => {
          // console.log(response)
          if (response.data.Status == 1) {
            this.shareqrcodeimg = response.data.Data
            this.visible = true;
            this.sharecontent = true;
          } else {
            this.$message({
              message: '分享失败',
              type: "error"
            });
          }

        }, error => {
          // console.log('错误', error.message)
        })
    },
    Collect() {
      if (!localStorage.getItem("USERID")) {
        this.$message({
          message: '还未登录，请先登录',
          type: "error",
          duration: 2000
        });
        setTimeout(() => {
          this.$router.push('/login')
        }, 2000)
      } else if (this.Services.iscollection == 0) {
        request.post('/api/Policy/Gocollection', {
          typeid: 3,
          specificid: this.$route.params.id
        })
          .then(response => {
            // console.log(response)
								this.tableData=[];
            this.actuarialList(this.$route.params.id);
            this.$message({
              message: response.data.Msg,
              type: "success"
            });

          }, error => {
            // console.log('错误', error.message)
          })
      } else if (this.Services.iscollection == 1) {
        request.post('/api/Policy/Deletecollection', {
          typeid: 3,
          specificid: this.$route.params.id
        })
          .then(response => {
            // console.log(response)
								this.tableData=[];
            this.actuarialList(this.$route.params.id);
            this.$message({
              message: response.data.Msg,
              type: "success"
            });

          }, error => {
            // console.log('错误', error.message)
          })
      }
    },
    gotoSignUp(_id) {
      if (localStorage.getItem("QYID") == 0) {
        this.$confirm('请先关联企业', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          this.$router.push('/personalcenter/0');
        }).catch(() => {
          return;
        });
      } else {
        this.$router.push("/project/ProjectReport/" + _id);
      }
    },
    downloadFile() { },
    async actuarialList(routerId) {
      request
        .post("/api/Policy/GetContent", {
          id: this.$route.params.id,
        })
        .then((result) => {
          //代表成功获取的信息
          // console.log(result);
          var type = "";
          var text = "";
          var disable = true;
          var iscollection = 0;
          if (result.data.Data.collection == '登录未收藏') {
            iscollection = 0
          } else if (result.data.Data.collection == '已收藏') {
            iscollection = 1
          }
          if (Date.now() > Date.parse(result.data.Data.endtime)) {
            text = "已结束";
            type = "danger";
          } else if (Date.now() < Date.parse(result.data.Data.begintime)) {
            text = "未开始";
            type = "success";
          } else {
            text = "立即申报";
            type = "primary";
            disable = false;
          }
          this.Services = {
            id: result.data.Data.id,
            title: result.data.Data.title,
            fileList: result.data.Data.fileList,
            numbering: result.data.Data.numbering,
            unit: result.data.Data.unit,
            label: result.data.Data.label,
            labellist: result.data.Data.labellist,
            publicDate: result.data.Data.publicDate,
            support: result.data.Data.support,
            type: type,
            text: text,
            disable: disable,
            iscollection: iscollection
          };
          this.content = result.data.Data.content;
          if (typeof result.data.Data.matchedLabels == "object") {
            result.data.Data.matchedLabels.forEach((item) => {
              item.PiPeiDu = "已匹配";
              const icon =
                '<span style="color: green; font-size: 24px;"><i class="el-icon-success"></i></span>';
              this.tableData.push({
                ...item,
                icon: icon,
              });
            });
          }
          if (typeof result.data.Data.unMatchedLabels == "object") {
            result.data.Data.unMatchedLabels.forEach((item) => {
              item.PiPeiDu = "未匹配";
              const icon =
                '<span style="color: red; font-size: 24px;"><i class="el-icon-error"></i></span>';
              this.tableData.push({
                ...item,
                icon: icon,
              });
            });
          }
        });
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  background-color: #ffffff;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.policy-info-detail-page__main__top {
  border-bottom: 0.0625rem solid #eee;
  padding: 1.375rem 0 0.75rem 0;
  position: relative;
}

.policy-info-detail-page__main__top__title {
  font-size: 1.25rem;
  color: #666;
  font-weight: bold;
  margin-bottom: 1.25rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.875rem;
}

.m-r-10 {
  margin-right: 0.625rem;
}

.m-l-10 {
  margin-left: 0.625rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.el-row {
  position: relative;
  box-sizing: border-box;
}

.flex.el-col {
  margin-bottom: 0.625rem;
  align-items: baseline;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-col-8 {
  width: 33.33333%;
}

.blue-round-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABWUlEQVQ4ja2VQUrDQBSGv06ilWhFuunGChYPIkiv4U68gxfwDuIJPEJ17w3caRd1XaSVYsW28uSNTIeZiVP8IYS89/9/JsmbP43T8zkRVEAb2AO2gRL4Aj6Bd2AMzELSMlBrAofAQaC3pccu0AHegFdgbWW+6T7QA4rY8j3IjVvACzCxLeNwhHCSYWhRqO73yaypPPIx0Mg0tGiofsc1PfJWvQlE37Wmlb7L/4D4VEbHJoU+cAc86rlfw2+XOocxnAHXTq/nXN9HNC2jgx3DZaR+kdA0TWQDWHQz64JCTJcJwiizLlgYf4t5uInUbxOauZhOE4QH4AoYapgM9XqQ0ExLTZtOgjSoMfExNhpfkwxRCuIzs1tzVPPB/oKl/bDW9EPf12pDw5XqxWctRCRwJRdzVyx80Yn+B34ySePJJdQgyA/tJpnbZ+cfJckueSthvNC+jGH4HwV8AyYIQ+PE3PJ4AAAAAElFTkSuQmCC) no-repeat;
  width: 0.9375rem;
  height: 0.9375rem;
  background-size: contain;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: sub;
}

.field-item__line {
  display: flex;
  font-size: 0.8rem;
  color: #666;
  width: 6.25rem;
}

.field-item__value {
  color: #333;
  font-size: 0.8rem;
  flex-basis: 73%;
  text-align: left;
}

.flex.el-col {
  margin-bottom: 0.625rem;
  align-items: baseline;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-col-14 {
  width: 58.33333%;
}

.policy-info-detail-page__main__top__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
}

.policy-info-detail-page__main__bottom {
  padding: 1.25rem 1.25rem;
}

.policy-info-detail-page__main__bottom__space {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25rem 0;
}

.el-upload {
  display: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.el-upload-list__item:first-child {
  margin-top: 0.625rem;
}

.el-upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 0.875rem;
  color: #606266;
  line-height: 1.8;
  margin-top: 0.3125rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 100%;
}

.el-upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 2.5rem;
  overflow: hidden;
  padding-left: 0.25rem;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
}

.el-upload-list__item-status-label {
  position: absolute;
  right: 0.3125rem;
  top: 0;
  line-height: inherit;
  display: none;
}

.el-upload-list__item-name [class^="el-icon"] {
  height: 100%;
  margin-right: 0.4375rem;
  color: #909399;
  line-height: inherit;
}

.zct-upload {
  text-align: left;
}

.collect-content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #028dfb;
  border-radius: 1.875rem;
  border: 1px solid #a9b8cd;
  border-color: #a9b8cd;
  color: #2664ca;
  padding: 10px 30px;
  margin: 10px 20px;
}

.collect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust transparency as needed */
  z-index: 9999;
  /* Ensure it sits on top of other content */
}

.sharecontent {
  width: 38.4375rem;
  min-height: 580px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0px auto;
  text-align: center;
  z-index: 9999999999;
  /* Ensure it sits on top of other content */
}

.sharebg {
  width: 100%;
  min-height: 580px;
  background: url('@/images/poster-bg.png') no-repeat 100% 100%;
  background-size: 100% 100%;
  border-radius: 0.5625rem;
}

.sharecontent button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: red !important;
  color: #ffffff;
  border: none;
  margin: 20px auto;
}

.sharebgcontent {
  position: relative;
  top: 20px;
  width: 95%;
  border: 1rpx solid black;
  max-height: 380px;
  padding: 20px 10px;
  margin: 0px auto;
  border-radius: 20px !important;
  background-color: #ffffff;
}

.sharebgcontent>>>img {
  width: 100% !important;
}
</style>