<template>
    <div class="entirety">
        <div v-if="flag">
            <div class="windowRatelogo">
                <img src="../images/windowRatelogo.png" />
            </div>
            <div>
                <div v-if="isRate">
                    <div class="windowname">
                        <div class="wtitle" style="font-size: 30px;text-align: center;"> {{ windowsname }}</div>
                        <!-- <div class="wtitle" style="margin-top: 52px;">您对这次的服务满意吗</div> -->
                        <div class="wcontent"
                            style="display: flex;align-items: center;justify-content: space-around;margin-top: 52px;">
                            <div class="wcontentemoji" :class="{ active: index == isactive }"
                                v-for="(item, index) in emojiList" :key="index" @click="Evaluate(index, item.score)">
                                <div :class="item.url" style="width: 35px;height: 35px;margin: 10px auto;"></div>
                                <div>{{ item.title }}</div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;margin-top: 90px;">
                        <el-button type="primary" style="font-size: 16px; padding: 10px 80px;"
                            @click="SubmitForEvaluation">提交评价</el-button>
                    </div>
                </div>
                <div v-if="!isRate">
                    <div style="font-size: 25px;text-align: center;margin-top: 50px;color: lightgray;">暂无此窗口</div>
                </div>
            </div>
        </div>
        <div v-if="!flag">
            <div class="windowRatelogo">
                <img src="../images/windowRatelogo.png" />
            </div>
            <hr>
            <div style="width: 100%;text-align: center;">
                <div class="el-icon-success" style="font-size: 90px;color: green;margin-top: 30px;"></div>
                <div style="font-size: 20px;color: green;margin-top: 30px;">提交成功</div>
            </div>
        </div>
    </div>
</template>
<script>
import request from "@/api/request";
export default {
    data() {
        return {
            emojiList: [{
                url: 'bgimg',
                title: '非常满意',
                score: 5
            }, {
                url: 'bgimg1',
                title: '满意',
                score: 4
            }, {
                url: 'bgimg2',
                title: '基本满意',
                score: 3
            }, {
                url: 'bgimg3',
                title: '不满意',
                score: 2
            }],
            isactive: -1,
            flag: true,
            EvaluateScore: 0,
            windowsid: 0,
            windowsname: '',
            isRate: false,
        }
    },
    created() {
        this.windowsid = this.$route.params.id;
        // console.log(this.$route.params.id)
        this.getWindow(this.$route.params.id);

    },
    methods: {
        getWindow(id) {
            if (id) {
                request
                    .get("/api/Window/get?id=" + id)
                    .then((result) => {
                        // console.log(result);
                        if (result.data && result.data.Data) {
                            if (result.data.Status == 1) {
                                this.windowsname = result.data.Data.name
                                this.isRate = true;
                            } else {
                                this.isRate = false;
                            }
                        }
                    });
            } else {
                this.isRate = false;
            }
        },
        Evaluate(index, score) {
            this.isactive = index;
            this.EvaluateScore = score;
        },
        SubmitForEvaluation() {
            if (this.EvaluateScore == 0) {
                this.$message({
                    message: '请选择满意度',
                    type: "warning",
                });
            } else {
                request
                    .post("/api/Window/evaluate", {
                        winid: this.windowsid,
                        score: this.EvaluateScore
                    })
                    .then((result) => {
                        // console.log(result);
                        if (result.data && result.data.Data) {
                            if (result.data.Status == 1) {
                                this.flag = false;
                            } else {
                                this.$message({
                                    message: '评价失败',
                                    type: "warning",
                                });
                            }
                        }
                    });
            }

        }
    }
}
</script>
<style>
* {
    margin: 0;
    padding: 0;
}

.active {
    color: #FFD73A;
}

.active div:first-child {
    scale: 1.2;
}

.entirety {
    width: 100%;
}

.windowRatelogo {
    width: 130px;
    margin: 50px auto;
}

.windowRatelogo img {
    width: 100%;
}

.windowname {
    width: 90%;
    margin: 52px auto 0px auto;
}

.wtitle {
    font-weight: bold;
    font-size: 17px;
    margin-top: 20px;
}

.wcontent {
    margin-top: 10px;
    font-size: 16px;
}

.wcontentemoji {
    min-width: 52px;
    text-align: center;
}

.bgimg {
    background-image: url('../images/rate/Very-satisfied.png');
    background-size: 100% 100%;
}

.bgimg1 {
    background-image: url('../images/rate/satisfaction.png');
    background-size: 100% 100%;
}

.bgimg2 {
    background-image: url('../images/rate/Basic-satisfaction.png');
    background-size: 100% 100%;
}

.bgimg3 {
    background-image: url('../images/rate/dissatisfy.png');
    background-size: 100% 100%;
}
</style>