<template>
  <div>
    <div style="height: 550px;">
      <el-table :data="applyList" stripe max-height="400" style="width: 90%;height: 550px;margin: 0px auto;">
        <el-table-column type="index" label="#" :index="indexMethod"> </el-table-column>
        <el-table-column prop="projectname" label="项目名称" width="200"></el-table-column>
        <el-table-column prop="posttime" label="申报日期"> </el-table-column>
        <el-table-column prop="name" label="申报人"> </el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button link type="primary" size="mini" @click="gotoDetail(scope.row.id, scope.row.projectid)">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="
                width: 90%;
                margin: 20px auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;
              ">
      <el-pagination background layout="prev, pager, next" :pageSize="pageSize" :total="total"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
export default {
  name: "myApply",
  data() {
    return {
      dialogVisible: false,
      rating: 0,
      comment: "",
      applyList: [],
      pageSize: 6,
      total: null,
      hideOnSinglePage: true,
      id: null,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },
    initData(pageindex) {
      request
        .post("/api/project/GetMyProjectLedgerList", {
          pagesize: this.pageSize,
          pageindex: pageindex,
        })
        .then((response) => {
          // console.log(response)
          // 请求成功时处理数据     
          if (response.data.Data && response.data.Data.data) {
            this.applyList = [...response.data.Data.data];
            this.total = response.data.Data.total;
            // this.formateList();
            // console.log(this.applyList);
          }
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },
    gotoDetail(id, projectid) {
      this.$router.push('/project/DeclarationParticulars/' + id + '/' + projectid);
    },
    formateList() {
      var index = 0;
      this.applyList.forEach((apply) => {
        apply["time"] =
          apply.begin_time.replace(/[a-zA-Z]/g, " ") +
          "~" +
          apply.end_time.replace(/[a-zA-Z]/g, " ");
      });
    },
    // 当当前页发生变化时的回调
    handleCurrentChange(val) {
      this.initData(val); // 重新查询数据
    },
  },
};
</script>