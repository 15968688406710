<template>
  <div>
    <demandsHeader></demandsHeader>
    <professionalsBanner></professionalsBanner>
    <div class="content">
      <div>
        <div class="content-header">
          找到<span style="color: #008cff; padding: 0px 10px">"专业机构"</span>筛选 共<span style="color: #008cff">{{total}}</span>项服务
          <span class="el-tag el-tag--plain" v-for="(item, index) in MechanismOption" :key="index">{{ item.name }}
            <i class="el-icon-close deleteOption"
              @click="deleteOption(item.index, item.Group, item.arrayType)"></i></span>
        </div>
        <div></div>
        <div class="filter-wrapper">
          <div class="filter-item">
            <div class="filter-item__label">产业类型：</div>
            <div class="filter-item__options" ref="type">
              <div v-for="(item, index) in ServiceCategory" :key="index" :class="item.active"
                @click="ServiceCategorySelect(index)">
                {{ item.name }}
              </div>
            </div>
            <!-- <div class="filter-item__label1" ref="unfold" @click="this.Unfold">
              {{ textOpenFlag ? "展开" : "收起" }}
            </div> -->
          </div>
          <!-- <div class="filter-item">
            <div class="filter-item__label">所属区镇：</div>
            <div class="filter-item__options" ref="address">
              <div
                :class="item.active"
                v-for="(item, index) in InstitutionalType"
                :key="index"
                @click="InstitutionalTypeSelect(index)"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              class="filter-item__label1"
              ref="Typunfold"
              @click="this.TypUnfold"
            >
              {{ typetextOpenFlag ? "展开" : "收起" }}
            </div>
          </div>
          <div class="filter-item">
            <div class="filter-item__label">企业类型：</div>
            <div class="filter-item__options" ref="qwer">
              <div
                :class="item.active"
                v-for="(item, index) in InstitutionalHeadcount"
                :key="index"
                @click="InstitutionalHeadcountSelect(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="filter-item">
            <div class="filter-item__label">市场主体：</div>
            <div class="filter-item__options">
              <div
                :class="item.active"
                v-for="(item, index) in InstitutionalArea"
                :key="index"
                @click="InstitutionalAreaSelect(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="content-details">
      <div class="el-row">
        <div class="list-item el-col el-col-5" v-for="(item, index) in Organization" @click="ViewDetails(item.id)">
          <!---->
          <div class="list-item_pic">
            <img :src="item.url" style="width: 100%; height: 160px;
    object-fit: contain;" />
          </div>
          <div :title="item.name" class="text-overflow"
            style="color: rgb(51, 51, 51); font-size: 16px; font-weight: bold">
            {{ item.name }}
          </div>
          <!---->
          <div class="text-overflow blue">
            <i class="el-icon-view blue"></i>{{ item.pv }}次
          </div>
          <div class="text-overflow">
            <i class="el-icon-location-outline blue"></i>{{ item.location }}
          </div>
        </div>
      </div>
    </div>
    <div style="
        width: 950px;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
      ">
      <el-pagination background layout="prev, pager, next" :total="total" :pageSize="pageSize"
        @current-change="handleCurrentChange">
      </el-pagination>
      <div>共{{ Organization.length }}条</div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>
<script>
import demandsHeader from "@/components/demandsHeader";
import professionalsBanner from "@/view/professionals/components/pageBanner";
import siteFooter from "@/components/siteFooter";
import request from '@/api/request'
export default {
  name: "profesSionals",
  components: {
    'demandsHeader': demandsHeader,
    'professionalsBanner': professionalsBanner,
    'siteFooter': siteFooter,
  },
  data() {
    return {
      pageSize: 16,
      ServiceCategory: [],
      InstitutionalType: [],
      InstitutionalHeadcount: [],
      InstitutionalArea: [],
      Organization: [],
      textOpenFlag: false,
      typetextOpenFlag: false,
      isServiceCategory_active: 0,
      isInstitutionalType_active: 0,
      isInstitutionalHeadcount_active: 0,
      isInstitutionalArea_active: 0,
      MechanismOption: [],
      total: 0,
    }
  },
  async created() {
    //request.default.withCredentials = true;
    // this.$nextTick(() => {
    //     let height = this.$refs.type.offsetHeight;

    //     let height2 = this.$refs.address.offsetHeight;
    //     let height1 = this.$refs.qwer.offsetHeight;
    //     if (height > height1) {
    //         this.textOpenFlag = true;
    //         this.$refs.type.style.height = '41px';
    //     }
    //     if (height2 > height1) {
    //         this.typetextOpenFlag = true;
    //         this.$refs.address.style.height = '41px';
    //     }
    // })

    await request.get('/api/Map/GetQyType')
      .then(response => {
        this.ServiceCategory = [{ active: 'active', name: '不限' }, ...response.data.Data.cylx.map(item => { return { active: '', name: item } })];
        // this.InstitutionalType = [{ active: 'active', name: '不限' }, ...response.data.Data.ssqz.map(item => { return { active: '', name: item } })];
        // this.InstitutionalHeadcount = [{ active: 'active', name: '不限' }, ...response.data.Data.qylx.map(item => { return { active: '', name: item } })];
        // this.InstitutionalArea = [{ active: 'active', name: '不限' }, ...response.data.Data.sczt.map(item => { return { active: '', name: item } })];
      }, error => {
        // console.log('错误', error.message)
      })
    await this.getServeList(
      this.ServiceCategory.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
      // this.InstitutionalType.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
      // this.InstitutionalHeadcount.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
      // this.InstitutionalArea.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
      this.pageSize, 1);

  },
  methods: {
    getServeList(ServiceCategoryitem, pagesize, pageindex) {
      var urls = "/api/Map/GetQyList"
      let reqObj = {
        cylx: ServiceCategoryitem.join(','),
        // ssqz: InstitutionalTypeitem.join(','),
        // qylx: InstitutionalHeadcountitem.join(','),
        // sczt: InstitutionalAreaitem.join(','),
        pageSize: pagesize,
        pageIndex: pageindex
      };
      request.post(urls, reqObj)
        .then(response => {
          this.total = response.data.Data.total;
          this.Organization = [...response.data.Data.data.map(item => { return { id: item.id, url: item.image, name: item.name,pv:item.click, location: item.address } })];
        }, error => {
          // console.log('错误', error.message)
        })
    },
    handleCurrentChange(val) {
      this.getServeList(
        this.ServiceCategory.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        // this.InstitutionalType.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        // this.InstitutionalHeadcount.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        // this.InstitutionalArea.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.pageSize, val);
    },
    deleteOption(index, groupname, arraytype) {
      arraytype[index].active = "";
      //this.MechanismOption = this.MechanismOption.filter(item => item.Group == groupname && item.index != index);

      var unselectedId = this.MechanismOption.find(item => item.Group == groupname && item.index == index).id;
      this.MechanismOption = [...this.MechanismOption.filter(item => item.id != unselectedId)];

      var activearray = this.MechanismOption.filter(item => item.Group == groupname);
      if (activearray.length == 0) {
        arraytype[0].active = "active";
      }
    },
    Filtrate(index, groupname, Arrayname) {
      if (index == 0) {
        Arrayname[0].active = "active";
        for (let i = 1; i < Arrayname.length; i++) {
          Arrayname[i].active = "";
        }
        this.MechanismOption = this.MechanismOption.filter(item => item.Group != groupname);
      } else if (Arrayname[index].active == "active") {
        Arrayname[index].active = "";
        //this.MechanismOption = this.MechanismOption.filter(item => item.Group == groupname && item.index != index);

        var unselectedId = this.MechanismOption.find(item => item.Group == groupname && item.index == index).id;
        this.MechanismOption = [...this.MechanismOption.filter(item => item.id != unselectedId)];

        var activearray = this.MechanismOption.filter(item => item.Group == groupname);
        if (activearray.length == 0) {
          Arrayname[0].active = "active";
        }
        // this.ServiceCategory[0].active = "active"
        // this.MechanismOption = 

      } else {
        Arrayname[0].active = "";
        Arrayname[index].active = "active";
        this.MechanismOption.push({ id: this.uuid(), index: index, name: Arrayname[index].name, Group: groupname, arrayType: Arrayname });
      }
    },
    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },
    async ServiceCategorySelect(index) {
      await this.Filtrate(index, 'ServiceCategory', this.ServiceCategory);
      await this.getServeList(
        this.ServiceCategory.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        // this.InstitutionalType.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        // this.InstitutionalHeadcount.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        // this.InstitutionalArea.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.pageSize, 1);
    },
    async InstitutionalTypeSelect(index) {
      await this.Filtrate(index, 'InstitutionalType', this.InstitutionalType);
      await this.getServeList(
        this.ServiceCategory.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalType.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalHeadcount.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalArea.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.pageSize, 1);
    },
    async InstitutionalHeadcountSelect(index) {
      await this.Filtrate(index, 'InstitutionalHeadcount', this.InstitutionalHeadcount);
      await this.getServeList(
        this.ServiceCategory.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalType.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalHeadcount.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalArea.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.pageSize, 1);
    },
    async InstitutionalAreaSelect(index) {
      await this.Filtrate(index, 'InstitutionalArea', this.InstitutionalArea);
      await this.getServeList(
        this.ServiceCategory.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalType.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalHeadcount.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.InstitutionalArea.filter(item => item.active == 'active' && item.name != '不限').map(item => item.name),
        this.pageSize, 1);
    },
    Unfold() {
      let height1 = this.$refs.qwer.offsetHeight;
      if (this.textOpenFlag) {
        this.textOpenFlag = false;
        this.$refs.type.style.height = 'auto';
      }
      else {
        this.textOpenFlag = true;
        this.$refs.type.style.height = '41px';
      }
    },
    TypUnfold() {
      let height1 = this.$refs.qwer.offsetHeight;
      if (this.typetextOpenFlag) {
        this.typetextOpenFlag = false;
        this.$refs.address.style.height = 'auto';
      }
      else {
        this.typetextOpenFlag = true;
        this.$refs.address.style.height = '41px';
      }
    },
    ViewDetails(id) {
      this.$router.push('/profesdetails/' + id);
    }
  }
}
</script>
<style scoped>
@import url("../../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
}

.active {
  background-color: #028dfb !important;
}

.content-header {
  background: #ecf3ff;
  padding: 0.75rem 1.25rem;
}

.content {
  background-color: #ffffff;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.content-details {
  width: 950px;
  margin: 20px auto;
}

.content-header {
  text-align: left;
  font-size: 16px;
}

.filter-wrapper {
  width: 95%;
  margin: 0px auto;
  border-radius: 0;
  padding: 10px 0px 30px 0px;
}

.filter-wrapper .filter-item {
  display: flex;
  text-align: center;
  margin-bottom: 0.625rem;
  width: 100%;
}

.filter-wrapper .filter-item__label {
  font-size: 1rem;
  color: #333;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  white-space: nowrap;
  margin-top: 10px;
}

.filter-wrapper .filter-item__label1 {
  font-size: 0.8rem;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 15px;
  color: #028dfb;
}

.filter-wrapper .filter-item__options {
  width: 700px;
  overflow: hidden;
}

.filter-wrapper .filter-item__options div {
  justify-content: space-around;
  float: left;
  margin: 9px 8px 0px 10px;
  border-radius: 1.25rem !important;
  padding: 0.3375rem 0.9375rem;
  color: #fff;
  font-size: 0.875rem;
  background-color: #a3c8e5;
  user-select: none;
}

.el-tag--plain {
  border-style: dashed;
  background-color: #fff;
  border-color: #9ad1fd;
  color: #028dfb;
}

.deleteOption:hover {
  color: #ffffff;
  background-color: #028dfb;
}

.el-row {
  margin-right: -20px;
  margin-bottom: -20px;
}

.el-row::after {
  content: "";
  display: block;
  clear: both;
}

.list-item {
  width: 25%;
  margin-bottom: 40px;
}

.list-item_pic {
  height: 10rem;
  margin-right: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.list-item .text-overflow {
  width: 93%;
  color: #666;
  font-size: 0.875rem;
  margin-top: 0.3125rem;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blue {
  color: #088bfc;
  margin-right: 0.1875rem;
}

/* .Spin:active{
    animation: 3.9s rotatefresh 1;
}

@keyframes rotatefresh {
    from {
        transform: rotateY(-90deg);
    }

    to {
        transform: rotateY(90deg);
        transition-duration: 3.9s;
        transition: 3.9s;
    }
} */
</style>