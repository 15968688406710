<template>
  <div class="bb">
    <div class="background"></div>
    <div class="bj">
      <el-form ref="form" :rules="rules" :model="loginForm">
        <el-form-item label="用户名" class="login" prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" class="login" prop="password">
          <el-input show-password v-model="loginForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="姓名" class="login" prop="name">
          <el-input v-model="loginForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" class="login" prop="mobile">
          <el-input v-model="loginForm.mobile" maxlength="11" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="Code" class="login">
          <el-input placeholder="短信验证码" v-model="loginForm.Code"></el-input>
          <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
            <span v-show="show">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </button>
        </el-form-item>
        <!-- <el-form-item label="验证码" class="login" prop="inputCode">
          <el-input v-model="inputCode" placeholder="请输入验证码"></el-input>
          <input type="button" v-model="code" @click="createCode"
            style="width: 27%; border-color: #DCDFE6; border-radius: 0px 4px 4px 0px; height: 41px;position: absolute;right: -1px;bottom: -1px;"
            title="点击更换验证码" />
        </el-form-item> -->
        <div class="login" style="margin-top: 20px;">
          <el-button @click="handleLogin">去注册</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import request from "../api/request";
export default {
  data() {
    // const codevalue = (rule, value, callback) => {
    //   if (this.inputCode.toUpperCase() === "" || this.inputCode.toUpperCase() === null) {
    //     callback(new Error('请输入验证码'))

    //   } else if (this.inputCode.toUpperCase() != this.code.toUpperCase()) {
    //     callback(new Error('请输入正确的验证码'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      loginForm: {
        username: "",
        password: "",
        name: "",
        mobile: "",
        Code: "",
      },
      count: 0,
      captchaInput: "",
      captchaSrc: "",
      code: "",
      show: true,
      inputCode: "",
      timer: "",
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, { pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}$/, message: '密码应为包含大写字母、小写字母、数字、特殊符号在内的8-20位密码' }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        },
        {
          pattern: /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/,
          message: '请输入真实姓名'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        // inputCode: [{
        //   required: true,
        //   validator: codevalue,
        //   trigger: 'blur'
        // }],
        Code: [{
          required: true,
          message: '请输入短信验证码',
          trigger: 'blur'
        }],
      },
    };
  },
  mounted() {
    // 初始化验证码
    // request.default.withCredentials = true;
    this.createCode();
    if (localStorage.getItem('timecount')) {
      this.count = localStorage.getItem('timecount');
      this.show = false;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= 60) {
          this.count--;
          localStorage.setItem('timecount', this.count);
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          localStorage.removeItem('timecount');
        }
      }, 1000);
    }
  },
  methods: {
    //在全局定义验证码
    // open1(string) {
    //   this.$message(string);
    // },
    // open2(string) {
    //   this.$message({
    //     message: string,
    //     type: "success",
    //   });
    // },

    // open3(string) {
    //   this.$message({
    //     message: string,
    //     type: "warning",
    //   });
    // },

    // open4(string) {
    //   this.$message.error(string);
    // },
    createCode() {
      this.code = "";
      var codeLength = 4; //验证码的长度
      var random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ); //随机数
      for (var i = 0; i < codeLength; i++) {
        //循环操作
        var index = Math.floor(Math.random() * 36); //取得随机数的索引（0~35）
        this.code += random[index]; //根据索引取得随机数加到code上
      }
    },
    // 处理登录
    handleLogin() {
      this.$refs.form.validate((valid) => {
        // console.log(valid);
        if (valid) {
          this.isdisabled = true;
          request
            .post("/api/login/Register", this.loginForm)
            .then((res) => {
              // console.log(res)
              // 请求成功，更新items数据
              if (res.data.Status == 0) {
                return this.$confirm(res.data.Msg);
              } else {
                this.$message({
                  message: '注册成功',
                  type: "success",
                });
                this.$router.push("/login");
              }
            })
            .catch((error) => {
              // 请求失败，处理错误
              console.error("Error fetching data:", error);
            })
        }
      })
    },
    // 获取短信验证码
    getCode() {
      //axios请求
      // console.log(this.loginForm.mobile);
      // 验证码倒计时
      if (!this.loginForm.mobile||!(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/).test(this.loginForm.mobile)) {
        this.$message({
          message: '手机号无效',
          type: "warning",
        });
      } else if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
            localStorage.setItem('timecount', this.count);
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            localStorage.removeItem('timecount', this.count);
          }
        }, 1000);
        this.getCodeNumber(this.loginForm.mobile);
      }
    },
    getCodeNumber(phone) {
      request
        .post("/api/Sms/Send", {
          "keyword": phone
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          // 请求失败，处理错误
          console.error("Error fetching data:", error);
        })
    },
    //   this.inputCode = this.inputCode.toUpperCase(); //取得输入的验证码并转化为大写
    //   if (this.inputCode.length <= 0) {
    //     //若输入的验证码长度为0
    //     this.open3("请输入验证码！"); //则弹出请输入验证码
    //   } else if (this.inputCode != this.code) {
    //     //若输入的验证码与产生的验证码不一致时
    //     this.open3("验证码输入错误！@_@"); //则弹出验证码输入错误
    //     this.createCode(); //刷新验证码
    //   } else {

    //   }
    //   // 在此处添加登录逻辑
    //   //console.log("用户名:", this.loginForm.username);
    //   //console.log("密码:", this.loginForm.password);
    // },
  },
};
</script>
<style scoped>
.bb {
  position: relative;
  width: 100%;
  height: 97vh;
  /* 设置高度为浏览器窗口高度 */
  overflow: hidden;
  /* 防止内容溢出 */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/eqitong/login-bj.png");
  background-size: cover;
  background-position: center;
}

.bj {
  width: 480px;
  height: 550px;
  background-color: white;
  position: absolute;
  top: 10%;
  right: 2%;
  border-radius: 3rem;
}

.login {
  margin: 10px auto;
  width: 400px;
}

span {
  font-size: 16px;
}

input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  margin-top: 3px;
  border: 1px solid gray;
}


button {
  width: 102%;
  height: 32px;
  background-color: #028dfc;
  color: white;
  border: 0;
}

a {
  text-decoration: none;
  color: black;
  float: right;
  font-size: 16px;
  margin-top: 8px;
  margin-right: -15px;
}

/* 短信验证码css */
.pr {
  position: relative;
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
</style>