<template>
  <div class="bb">
    <div class="background"></div>
    <div class="bj">
      <el-form ref="form" :rules="rules" :model="loginForm">
        <el-form-item label="手机号" class="login" prop="Mobile">
          <el-input v-model="loginForm.Mobile" maxlength="11" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="Code" class="login">
          <el-input placeholder="短信验证码" v-model="loginForm.Code"></el-input>
          <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
            <span v-show="show">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </button>
        </el-form-item>
        <el-form-item label="新密码" class="login" prop="NewPassword">
          <el-input show-password v-model="loginForm.NewPassword" placeholder="请输入新密码"></el-input>
        </el-form-item>

        <el-form-item label="确认密码" class="login" prop="ConfirmPassword">
          <el-input show-password v-model="loginForm.ConfirmPassword" placeholder="请输入确认密码"></el-input>
        </el-form-item>
        <el-form-item label="账号类型" class="login" prop="UserType">
          <el-select v-model="loginForm.UserType" placeholder="请选择账号类型" style="width: 100%;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="login" style="margin-top: 20px;">
          <el-button @click="handleLogin">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import request from "../api/request";
export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      // console.log(value)
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.NewPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      options: [{
        value: '0',
        label: '普通账号'
      }, {
        value: '1',
        label: '企业账号'
      }],
      loginForm: {
        Mobile: "",
        Code: "",
        NewPassword: '',
        UserType: '',
        ConfirmPassword: '',
      },
      count: 0,
      show: true,
      timer: "",
      passCode: true,
      rules: {
        Mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        Code: [{
          required: true,
          message: '请输入短信验证码',
          trigger: 'blur'
        }], NewPassword: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, { pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}$/, message: '密码应为包含大写字母、小写字母、数字、特殊符号在内的8-20位密码' }],
        ConfirmPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        UserType: [{
          required: true,
          message: '请选择账号类型',
          trigger: 'blur'
        }],
      },
    };
  },
  mounted() {
    if (localStorage.getItem('Retrievetimecount')) {
      this.count = localStorage.getItem('Retrievetimecount');
      this.show = false;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= 60) {
          this.count--;
          localStorage.setItem('Retrievetimecount', this.count);
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          localStorage.removeItem('Retrievetimecount');
        }
      }, 1000);
    }
  },
  methods: {
    // 处理登录
    handleLogin() {
      this.$refs.form.validate((valid) => {
        // console.log(valid);
        // console.log(this.loginForm);
        if (valid) {
          request
            .post("/api/login/ForgetPwd", {
              Mobile: this.loginForm.Mobile,
              NewPassword: this.loginForm.NewPassword,
              Code: this.loginForm.Code,
              UserType: this.loginForm.UserType,
            })
            .then((res) => {
              // console.log(res)
              if (res.data.Status == 1) {
                this.$message({
                  message: '修改成功',
                  type: "success",
                });
                this.$router.push('/login');
              } else {
                this.$message({
                  message: res.data.Msg,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              // 请求失败，处理错误
              console.error("Error fetching data:", error);
            })
        }
      })
    },
    // 获取短信验证码
    getCode() {
      //axios请求
      // console.log(this.loginForm.Mobile);
      // 验证码倒计时
      if (!this.loginForm.Mobile||!(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/).test(this.loginForm.Mobile)) {
        this.$message({
          message: '手机号无效',
          type: "warning",
        });
      } else if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
            localStorage.setItem('Retrievetimecount', this.count);
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            localStorage.removeItem('Retrievetimecount', this.count);
          }
        }, 1000);
        this.getCodeNumber(this.loginForm.Mobile);
      }
    },
    getCodeNumber(phone) {
      request
        .post("/api/Sms/Send", {
          "keyword": phone
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          // 请求失败，处理错误
          console.error("Error fetching data:", error);
        })
    },
  },
};
</script>
<style scoped>
.bb {
  position: relative;
  width: 100%;
  height: 97vh;
  /* 设置高度为浏览器窗口高度 */
  overflow: hidden;
  /* 防止内容溢出 */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/eqitong/login-bj.png");
  background-size: cover;
  background-position: center;
}

.bj {
  width: 480px;
  height: 510px;
  padding: 20px 0px;
  background-color: white;
  position: absolute;
  top: 10%;
  right: 2%;
  border-radius: 3rem;
}

.login {
  margin: 10px auto;
  width: 400px;
}

span {
  font-size: 16px;
}

input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  margin-top: 3px;
  border: 1px solid gray;
}

button {
  width: 102%;
  height: 32px;
  background-color: #028dfc;
  color: white;
  border: 0;
}

a {
  text-decoration: none;
  color: black;
  float: right;
  font-size: 16px;
  margin-top: 8px;
  margin-right: -15px;
}

/* 短信验证码css */
.pr {
  position: relative;
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
</style>