import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../view/index.vue'
import windowRate from '../view/windowRate.vue'
import login from '../view/login.vue'
import RetrievePassword from '../view/RetrievePassword.vue'
import activity from '../view/activity/activity.vue'
import myActivity from '../view/PersonalCenter/myActivity.vue'
import projectPublicity from '../view/project/projectPublicity.vue'
import digitalMapNeed from '../view/declare/digitalMapNeed.vue'
import digitalMapOffer from '../view/declare/digitalMapOffer.vue'
import policySearchDetail from '../view/PolicyCalculation/policySearchDetail.vue'
import DeclarationParticulars from '../view/project/DeclarationParticulars.vue'
import projectPublicityDetail from '../view/project/projectPublicityDetail.vue'
import projectApply from '../view/project/projectApply.vue'
import activityInfo from '../view/activity/activityInfo.vue'
import custService from '../view/custService/custService.vue'
import demandsadd from '../view/demands/demandsAdd'
import rapidActuarial from '../view/PolicyCalculation/rapidActuarial'
import personalcenter from "../view/PersonalCenter/PersonalCenter";
import professionals from "../view/professionals/profesSionals";
import profesdetails from "../view/professionals/profesDetails";
import PolicySearch from "../view/PolicyCalculation/PolicySearch";
import policyIndex from '../view/PolicyCalculation/policyIndex';
import PolicySubscription from '../view/PolicyCalculation/PolicySubscription';
import declareToday from '../view/PolicyCalculation/declareToday';
import PolicyDetails from "../view/PolicyCalculation/PolicyDetails";
import PolicyCalculation from "../view/PolicyCalculation/PolicyCalculation";
import policyInterpretation from "../view/PolicyCalculation/policyInterpretation";
import policyInterpretationDeteils from "../view/PolicyCalculation/policyInterpretationDeteils";
import DemandsDetails from "../view/PersonalCenter/DemandsDetails";
import freeToEnjoy from "../view/project/freeToEnjoy.vue"
import freeToEnjoyDetail from "../view/project/freeToEnjoyDetail.vue"
import ProjectReport from "../view/project/ProjectReport.vue"
import declare from "../view/declare/declare"
import project from "../view/project/project"
import about from "../view/about/about"
import register from '../view/register.vue'
import companySelfTest from '../view/companySelfTest/companySelfTest.vue'
import searchList from '../view/searchList/searchList.vue'
import newspecial from '../view/newspecial/newspecial.vue'
import newspecialindex from '../view/newspecial/newspecialindex.vue'
import digitalMapSell from '../view/declare/digitalMapSell.vue'
Vue.use(VueRouter)
let originPush = VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },{
    path: '/RetrievePassword',
    name: 'RetrievePassword',
    component: RetrievePassword
  },{
    path: '/windowRate/:id',
    name: 'windowRate',
    component: windowRate
  },
  {
    path: '/login/:uuid?',
    name: 'login',
    component: login
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/myActivity',
    name: 'myActivity',
    component: myActivity
  },
  {
    path: '/policySearchDetail/id=:id',
    name: 'policySearchDetail',
    component: policySearchDetail
  },
  {
    path: '/activityInfo',
    name: 'activityInfo',
    component: activityInfo
  },
  {
    path: '/rapidActuarial',
    name: 'rapidActuarial',
    component: rapidActuarial
  },
  {
    path: '/custService',
    name: 'custService',
    component: custService
  },
  {
    path: '/searchList',
    name: 'searchList',
    component: searchList
  },
  {
    path: '/companySelfTest',
    name: 'companySelfTest',
    component: companySelfTest
  },
  {
    path: '/project',
    name: 'project',
    component: project,
    children: [
      {
        path: 'projectApply',
        name: 'projectApply',
        component: projectApply
      },
      {
        path: 'freeToEnjoy',
        name: 'freeToEnjoy',
        component: freeToEnjoy
      },
      {
        path: 'freeToEnjoyDetail',
        name: 'freeToEnjoyDetail',
        component: freeToEnjoyDetail
      }, {
        path: 'projectPublicity',
        name: 'projectPublicity',
        component: projectPublicity
      },
      {
        path: 'projectPublicityDetail/id=:id',
        name: 'projectPublicityDetail',
        component: projectPublicityDetail
      }, {
        path: 'DeclarationParticulars/:id/:projectid',
        name: 'DeclarationParticulars',
        component: DeclarationParticulars,
      }, {
        path: 'ProjectReport/:id',
        name: 'ProjectReport',
        component: ProjectReport
      }
    ]
  }, {
    path: '/declare',
    name: 'declare',
    component: declare,
    children: [
      {
        path: 'digitalMapOffer',
        name: 'digitalMapOffer',
        component: digitalMapOffer
      },
      {
        path: 'digitalMapNeed',
        name: 'digitalMapNeed',
        component: digitalMapNeed
      }, {
        path: 'digitalMapSell',
        name: 'digitalMapSell',
        component: digitalMapSell
      }

    ]

  },
  {
    path: '/professionals',
    name: 'professionals',
    component: professionals,

  },
  {
    path: '/personalcenter/:ids',
    name: 'personalcenter',
    component: personalcenter,

  }, {
    path: '/DemandsDetails',
    name: 'DemandsDetails',
    component: DemandsDetails,

  },
  {
    path: '/demandsadd',
    name: 'demands',
    component: demandsadd
  },
  {
    path: '/profesdetails/:id',
    name: 'profesdetails',
    component: profesdetails
  },
  {
    path: '/PolicyCalculation',
    name: 'PolicyCalculation',
    component: PolicyCalculation,
    children: [
      {
        path: 'PolicySearch',
        name: 'PolicySearch',
        component: PolicySearch,
      }, {
        path: 'PolicyDetails/:id',
        name: 'PolicyDetails',
        component: PolicyDetails,
      },
      {
        path: 'declareToday',
        name: 'declareToday',
        component: declareToday
      }, {
        path: 'policyinterpretation',
        name: 'policyinterpretation',
        component: policyInterpretation,
      }, {
        path: 'policyInterpretationDeteils/:id',
        name: 'policyInterpretationDeteils',
        component: policyInterpretationDeteils,
      }, {
        path: 'policyIndex',
        name: 'policyIndex',
        component: policyIndex
      }, {
        path: 'rapidActuarial',
        name: 'rapidActuarial',
        component: rapidActuarial
      }, {
        path: 'policySearchDetail/:id',
        name: 'policySearchDetail',
        component: policySearchDetail
      },{
        path: 'PolicySubscription',
        name: 'PolicySubscription',
        component: PolicySubscription
      }
	  
	  
	  
    ],
	 
  },
  {
    path: '/newspecial',
    name: 'newspecial',
    component: newspecial
  },
  {
    path: '/newspecialindex',
    name: 'newspecialindex',
    component: newspecialindex
  }
  // {
  //   path: '/login',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
