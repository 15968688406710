<template>
  <div>
    <div class="content">
      <div class="content_left">
        <MapContainer v-on:selectqyList="search" :mapkey="'d4d411414b7cbb8bfaccac15bab75334'"></MapContainer>
      </div>
      <div class="content_right">
        <div class="user_skills">
          <el-table :data="DemandList.tableData" border>
            <el-table-column fixed prop="title" label="标题">
            </el-table-column>
            <el-table-column label="详情">
              <template #default="scope">
                <span class="digitaDetails" :title="scope.row.demand">{{ scope.row.demand }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="qy" label="企业名称">
            </el-table-column>
            <el-table-column prop="mobile" label="联系电话">
            </el-table-column>
            <el-table-column prop="add_time" label="添加时间" width="100">
            </el-table-column>
            <el-table-column label="是否出租">
              <template #default="scope">
                <span v-if="scope.row.isCheck==0">未出租</span>
                <span v-if="scope.row.isCheck==1">已出租</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="
          width: 100%;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
            <el-pagination background layout="prev, pager, next" :pageSize="pageSize" :total="DemandList.total"
              @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>

<script>
import siteFooter from "@/components/siteFooter";
import MapContainer from "@/view/declare/components/MapContainer.vue";
import request from '@/api/request'
export default {
  name: 'digitalMapOffer',
  props: ['message'],
  components: {
    'siteFooter': siteFooter,
    'MapContainer': MapContainer,
  },
  data() {
    return {
      DemandList: {
        tableData: [],
        total: 0
      },
      AreaName: '',
      pageSize: 5,
      bgcolor: 'background-color: transparent;'
    };
  },
  created() {
    this.actuarialList();
  },
  methods: {
    search(name) {
      this.AreaName = name;
      this.actuarialList(this.AreaName, 1);
    },
    // onSearch() {
    //   this.$nextTick(() => {
    //     this.actuarialList(this.$parent.AreaName, 1);
    //   });
    // },
    async actuarialList(area, pageIndex) {
      request.post("/api/Map/GetMapList",
        {
          gxlx: "厂房出租",
          area: area,
          pageSize: this.pageSize,
          pageIndex: pageIndex,
        }
      )
        .then((result) => {  //代表成功获取的信息
          // console.log(result)
          result.data.Data.data.forEach(item => {
            item.add_time = this.formatDayTime(item.add_time);
          });
          this.DemandList.tableData = result.data.Data.data;
          this.DemandList.total = result.data.Data.total;
        })
    },
    //页码切换
    handleCurrentChange(val) {
      this.actuarialList(this.AreaName, val);
    },
    formatDayTime(v) {
      return v.replace(/[a-zA-Z]/g, " ");
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  width: 100%;
  min-height: 593px;
  margin: 0px auto;
  padding: 20px 0px;
  min-width: 1202px;
  display: flex;
  background-color: #E3EBF8;
}

.content_left {
  width: 40%;
}

.content_right {
  width: 60%;
}

.user_skills {
  width: 90%;
  margin: 20px auto;
}

.user_skills /deep/ .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

.user_skills /deep/ .el-table tr {
  background-color: transparent !important;
}

.user_skills /deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

.digitaDetails {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*3表示只显示3行*/
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
</style>