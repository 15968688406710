<template>
  <div>
    <div style="margin:20px auto;"><!---->
      <div style="width:950px;margin:0px auto;background-color:#ffffff;padding:30px 0px;">
        <div class="filter-wrapper">
          <input type="text" v-model="titleValue" placeholder="请输入申报关键词" />
          <div class="global-search__btn" @click="onSearch">
            <i class="icon-search"></i>
          </div>
        </div>
        <!-- 申报列表 -->
        <el-table :data="ProjectPublicity.tableData">
          <el-table-column align="center" label="公示标题" prop="title" />
          <el-table-column align="center" label="申报政策" prop="zcname" />
          <el-table-column align="center" label="发布部门" prop="publicity_department" />
          <el-table-column align="center" label="公示时间" prop="begin_time" />
          <el-table-column align="center" label="浏览量" prop="click" />
          <el-table-column align="center" label="操作" width="200">
            <template #default="scope">
              <el-button link type="primary" @click="projectPublicityDetail(scope.row.id)">
                查看名单
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="
          width: 900px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
          <el-pagination background layout="prev, pager, next" :total="ProjectPublicity.total"
            @current-change="handleCurrentChangeactivity">
          </el-pagination>
        </div>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>

<script>
import request from '@/api/request';
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      titleValue: '',
      ProjectPublicity: {
        tableData: [],
        total: 0
      },
      pageSize: 10
    };
  },

  created() {
    this.actuarialList()
  },
  methods: {
    projectPublicityDetail(id) {
      this.$router.push({ name: 'projectPublicityDetail', params: { id } });
    },
    onSearch() {
      this.actuarialList(this.titleValue, 1);
    },
    async actuarialList(keyword, pageIndex) {
      request.post("/api/Project/GetPublicList",
        {
          keyword: keyword,
          pageSize: this.pageSize,
          pageIndex: pageIndex
        }
      )
        .then((result) => {  //代表成功获取的信息
          // console.log(result)
          result.data.Data.data.forEach(item => {
            item.begin_time = this.formatDayTime(item.begin_time);
          });
          this.ProjectPublicity.tableData = result.data.Data.data;
          this.ProjectPublicity.total = result.data.Data.total;
        })
    },
    //页码切换
    handleCurrentChangeactivity(val) {
      this.actuarialList(this.titleValue, val);
    },
    formatDayTime(v) {
      return v.replace(/[a-zA-Z]/g, " ");
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

.filter-wrapper input {
  border: 1px solid #cecece;
  width: 60%;
  border-radius: 1.25rem;
  height: 2.3rem;
  float: left;
  padding-left: 10px;
}

.filter-wrapper {
  width: 95%;
  margin: 0px auto;
  border-radius: 0;
  padding: 10px 0px 30px 0px;
}

.global-search__btn {
  position: relative;
  left: -43px;
  cursor: pointer;
  width: 4.6875rem;
  height: 2.5rem;
  background: #028dfb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
}

.global-search__btn .icon-search {
  height: 1.5625rem;
  width: 1.5625rem;
  background-size: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACUklEQVRIibWWyWtUQRDGfzO4kBAXTIQouBJEk4hHCZ7cIl7EkydjvEk86dU/IeYoiqDoyWNAVARFPLghigpC4kkRM2bUS1wyGtR80tAPKu3rt8Txgx66p+rV11Wv+utXkUQEa4ABYBfQDawAGsA74DEwCtwCZmMBUuEIg9Eu6YKkn8rHmKT+lBjRERq2S5ooQGQxK2lYUqUsoSP7FgR7IemkpG2SVkraKOmgpMuSZgLfs2UIOyTVzMPTko6aXbekZNAl6UFAOlSU8GJA1uf/3yTpuqTfku6lBFgs6aZ59qukzjzC9UGDHPHGfh8gQT0SZJmkN8ZvOI/wlHF+4g09PlOHhqQRSRsyAg2YGJNZDeR+7hjnE95w25D1Ze3YlNZWozfmWwW2mPN5H2gBdvv1GeBRgeM8Azw3680xR0e43KzfAz88yUfgfAkNqZt5R8xpAfDFZ+XQ5sQH2FGCKMFCM5+OObkMJ8y6ex5ECXrNvJZF+MysD/wDWZef/wpizoWk/aa7XFeuLSPGflwxMa7lncOqV/0EDyUtKkF2OJC3vUWkbZ9X/QRXJS0pQHYoEPHPRaQtGaeDnb6VNCipNeVBd3uMKh1jWaT2xq8A54BjwWtu+Cb44I9PD7Aup4nG/ZdC/S9Lyi6OBzKVh6kymcZqvcqr/utIMHe73JC0xwcdL0qa9RGVwJVwK7AU+A5MAk+BKePTCdyNaOjc8s7jzMVGoUybSZhH+tJ1fJGSlkVWeYeqzWbz72on8CrF1tbskobltZL5SdLq/1FSi1ZgEGgHLgG1P/CqcmvAyTXiAAAAAElFTkSuQmCC);
}
</style>