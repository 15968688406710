<template>
  <div>
    <demands-header></demands-header>
    <el-button type="text" @click="dialogTableVisible1 = true"
      >打开嵌套表格的 Dialog</el-button
    >
    <el-dialog
      title="计算中..."
      :visible.sync="dialogTableVisible1"
      @open="openDialog1"
      width="540px"
    >
      <div style="width: 400px; height: 400px; margin: 0 auto">
        <img
          style="width: 400px; height: 400px"
          src="../../images/testing.gif"
        />
      </div>
      <div style="text-align: center">
        <span>系统正在智能匹配检测，请稍侯...</span>
      </div>
    </el-dialog>

    <el-dialog
      title="测评结果"
      :visible.sync="dialogTableVisible2"
      @open="handleDialogOpen"
      @close="handleDialogClose"
      width="540px"
    >
      <div
        ref="radarChart"
        style="width: 300px; height: 300px; margin: 0 auto"
      ></div>

      <el-table :data="suggestData">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column property="name" label="姓名"></el-table-column>
      </el-table>
    </el-dialog>

    <site-footer></site-footer>
  </div>
</template>

<script>
import demandsHeader from "@/components/demandsHeader.vue";
import siteFooter from "@/components/siteFooter";
import * as echarts from "echarts";

export default {
  components: {
    demandsHeader: demandsHeader,
    siteFooter: siteFooter,
  },
  data() {
    return {
      chartData: {
        // 这里放置雷达图的数据
        indicatorData: [
          { name: "创新性", max: 6500 },
          { name: "专业化", max: 16000 },
          { name: "成长性", max: 30000 },
        ],
        seriesData: [
          {
            type: "radar",
            data: [
              {
                value: [3000, 8000, 10000],
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "blue",
                      offset: 0,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      },
      suggestData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      dialogTableVisible1: false,
      dialogTableVisible2: false,
    };
  },

  methods: {
    openDialog1() {
      setTimeout(() => {
        this.dialogTableVisible1 = false;
        this.dialogTableVisible2 = true;
      }, 2000);
    },

    handleDialogOpen() {
      this.$nextTick(() => {
        // 等待下一个 DOM 更新周期确保 el-dialog 可见后再初始化雷达图
        const chartContainer = this.$refs.radarChart;
        const myChart = echarts.init(chartContainer);
        const { indicatorData, seriesData } = this.chartData;

        myChart.setOption({
          radar: {
            indicator: indicatorData,
          },
          series: seriesData,
        });
      });
    },
    handleDialogClose() {
      const chartContainer = this.$refs.radarChart;
      const myChart = echarts.init(chartContainer);
      myChart.dispose();
    },
  },
};
</script>

<style scoped>
.link {
  color: #028dfb;
  text-align: right;
  display: block;
  font-size: 115%;
}
.a {
  outline: 0;
  text-decoration: none;
}
</style>