<template>
  <div>
    <div>
      <div class="layout-default is-home" style="width: 1200px">
        <div>
          <div class="ai-qa-page">
            <div class="ai-qa-page-main" style="z-index: 2001">
              <div
                aria-modal="true"
                class=""
                
              >
                <div class="">
                  <div class="ai-qa-content">
                    <div class="ai-qa-content__chat">
                      <div class="ai-qa-current-date" v-html="formatDate"></div>
                      <div class="ai-qa-customer">
                        <div
                          class="ai-qa-customer__container flex-column-center"
                        >
                          <img src="../../img/custService/kf.05491b4.png" />
                          <div
                            class="item-text"
                            style="color: rgb(51, 51, 51); font-weight: 600"
                          >
                            智能政务助理
                          </div>
                          <div class="item-text">智能助理</div>
                          <div class="customer-info"></div>
                        </div>
                      </div>
                      <div ref="chatRecord" id="chatRecord" class="chat-record">
                        <div class="message-wrapper position-left">
                          <div class="c2c-layout position-left">
                            <div class="col-1">
                              <img src="../../img/custService/kf.05491b4.png" />
                            </div>
                            <div class="col-2">
                              <div class="content-wrapper">
                                <div class="chat-bubble">
                                  <div style="display: flex">
                                    <div
                                      class="message-content message-received"
                                    >
                                      <div class="username">小通</div>
                                      <div class="text-box">
                                        <div>
                                          您好！我是E企通平台小通，很高兴为您服务！请问有什么可以帮您？
                                        </div>
                                        <div class="showMore"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="qa-shortcut-key">
                          <div class="guess-you">
                            <div class="guess-you__title">
                              <span class="text-special">猜</span
                              >你<br />想<span class="text-special">问</span>
                            </div>
                            <div class="guess-you__content">
                              <div class="guess-item">奖励</div>
                              <div class="guess-item">科技小巨人</div>
                              <div class="guess-item">绿色供应链</div>
                            </div>
                            <!-- <div class="guess-you__toggle">
                              <i class="el-icon-refresh-right"></i>
                            </div> -->
                          </div>
                          <div class="qa-keyword">
                            <img src="../../img/custService/政策.png" />
                            <div>政策</div>
                          </div>
                          <div class="qa-keyword">
                            <img src="../../img/custService/申报.png" />
                            <div>申报</div>
                          </div>
                          <div class="qa-keyword">
                            <img src="../../img/custService/诉求.png" />
                            <div>诉求</div>
                          </div>
                        </div>
                        <div
                          class="message-wrapper position-right"
                          v-for="(item, index) in questionList"
                          :key="item.id"
                        >
                          <div class="c2c-layout position-right">
                            <div class="col-1">
                              <img :src="defaultMy" class="avatar" />
                            </div>
                            <div class="col-2">
                              <div class="content-wrapper">
                                <div class="chat-bubble">
                                  <div style="display: flex">
                                    <div class="message-content message-send">
                                      <div
                                        class="username"
                                        style="text-align: right"
                                      >
                                        我
                                      </div>
                                      <div class="text-box right-text-box">
                                        {{ item }}
                                        <div class="showMore"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="message-wrapper position-left"
                            v-if="
                              answerList &&
                              answerList[index] &&
                              answerList[index].length > 0 &&
                              !answerListFlag[index]
                            "
                          >
                            <div class="c2c-layout position-left">
                              <div class="col-1">
                                <img
                                  src="../../img/custService/kf.05491b4.png"
                                />
                              </div>
                              <div class="col-2">
                                <div class="content-wrapper">
                                  <div class="chat-bubble">
                                    <div style="display: flex">
                                      <div
                                        class="message-content message-received"
                                      >
                                        <div class="username">小通</div>
                                        <div class="text-box">
                                          <div>以下问题是您关心的吗？</div>
                                          <div
                                            class="question"
                                            v-for="item in answerList[index]"
                                            :key="item.id"
                                          >
                                            <div
                                              class="item"
                                              @click="handleItemClick"
                                            >
                                              <span style="font-size: 20px"
                                                >·</span
                                              >
                                              &nbsp;
                                              {{ item.content }}
                                              <input
                                                type="hidden"
                                                :value="item.id"
                                              />
                                              <input
                                                type="hidden"
                                                :value="item.content"
                                              />
                                            </div>
                                          </div>
                                          <div class="showMore"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="message-wrapper position-left"
                            v-else-if="
                              (!answerList ||
                                !answerList[index] ||
                                answerList[index].length === 0) &&
                              (!answerListFlag || !answerListFlag[index])
                            "
                          >
                            <div class="c2c-layout position-left">
                              <div class="col-1">
                                <img
                                  src="../../img/custService/kf.05491b4.png"
                                />
                              </div>
                              <div class="col-2">
                                <div class="content-wrapper">
                                  <div class="chat-bubble">
                                    <div style="display: flex">
                                      <div
                                        class="message-content message-received"
                                      >
                                        <div class="username">小通</div>
                                        <div class="text-box">
                                          <div>
                                            不好意思，暂时不能理解您的问题，请转接人工客服：0513-83311717
                                          </div>
                                          <div class="showMore"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="answerListFlag[index]">
                            <div class="message-wrapper position-left">
                              <div class="c2c-layout position-left">
                                <div class="col-1">
                                  <img
                                    src="../../img/custService/kf.05491b4.png"
                                  />
                                </div>
                                <div class="col-2">
                                  <div class="content-wrapper">
                                    <div class="chat-bubble">
                                      <div style="display: flex">
                                        <div
                                          class="message-content message-received"
                                        >
                                          <div class="username">小通</div>
                                          <div class="text-box">
                                            <div>
                                              <p
                                                style="
                                                  text-indent: 43px;
                                                  line-height: 2em;
                                                "
                                                v-html="
                                                  answerList[index].answer
                                                "
                                              ></p>
                                            </div>
                                            <div
                                              style="
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                              "
                                              class="showMore"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="send-box">
                        <div class="send-content-container">
                          <div class="el-textarea">
                            <textarea
                              id="txtKeyword"
                              autocomplete="off"
                              rows="4"
                              placeholder="请输入你要咨询的问题!"
                              class="el-textarea__inner"
                              style="min-height: 32.3333px"
                              v-model="q"
                              @keypress="handleKeyPress"
                            ></textarea>
                          </div>
                          <div class="send-btn">
                            <button
                              id="btnSend"
                              type="button"
                              class="el-button el-tooltip el-button--primary"
                              aria-describedby="el-tooltip-9951"
                              tabindex="0"
                              @click="btnSend"
                            >
                              <span>发送</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ai-qa-content__tool">
                      <div class="common-problem">
                        <div class="tool-title">常见问题</div>
                        <div id="list" class="common-problem__list">
                          <div
                            v-for="(item, index) in commonQuestionList"
                            :key="item.id"
                            class="common-problem-item"
                            @click="handleListClick(item)"
                          >
                            <div :style="getStyle(index)" class="item-seq">
                              {{ index + 1 }}
                            </div>
                            <span id="contentText">{{ item.content }}</span>
                            <input
                              id="contentId"
                              type="hidden"
                              :value="item.id"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="contact-information">
                        <div class="tool-title">全国统一政务服务热线</div>
                        <div class="contact-information-item">12345</div>
                        <div class="tool-title p-t-10">服务热线</div>
                        <div class="contact-information-item">
                          0513-83111717
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultMy from "../../img/custService/defaultMy.png";
import request from "../../api/request";
export default {
  data() {
    return {
      formatDate: "",
      commonQuestionList: [
        {
          id: -1,
          content: "",
        },
      ],
      q: "",
      resultIndex: 0,
      questionList: [],
      answerList: [],
      answerListFlag: [],
      defaultMy: defaultMy,
    };
  },
  mounted() {
    this.formatDate2();
    this.getList();
    this.$nextTick(() => {
      const guessYouContent = document.querySelector(".guess-you__content");
      guessYouContent.addEventListener("click", this.handleGuessClick);
    });
    this.handleOptionsClick();
    this.scrollToBottom();
  },
  updated() {
    // 在组件更新后执行滚动操作
    this.scrollToBottom();
  },
  methods: {
    formatDate2() {
      let date = new Date();
      let y = date.getFullYear(); // 年
      let MM = date.getMonth() + 1; // 月
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate(); // 日
      d = d < 10 ? "0" + d : d;
      let h = date.getHours(); // 时
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes(); // 分
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds(); // 秒
      s = s < 10 ? "0" + s : s;
      this.formatDate =
        y + "年" + MM + "月" + d + "日 " + h + ":" + m + ":" + s;
    },

    getList() {
      // 使用 Vue 的异步请求方法
      request
        .get("/api/QA/GetQuestionList")
        .then((response) => {
          // 请求成功时处理数据
          // console.log(response);
          this.commonQuestionList = response.data.Data;
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },

    getStyle(index) {
      // 根据索引返回对应的样式
      if (index === 0) {
        return {
          backgroundColor: "rgb(225, 20, 20)",
          display: "inline-block",
          textAlign: "center",
          color: "#fff",
          width: "1.5625rem",
          height: "1.0625rem",
          lineHeight: "1.0625rem",
          borderRadius: "0.125rem",
          marginRight: "0.125rem",
          marginTop: "10px",
        };
      } else if (index === 1) {
        return {
          backgroundColor: "rgb(255, 123, 12)",
          display: "inline-block",
          textAlign: "center",
          color: "#ffffff",
          width: "1.5625rem",
          height: "1.0625rem",
          lineHeight: "1.0625rem",
          borderRadius: "0.125rem",
          marginRight: "0.125rem",
          marginTop: "10px",
        };
      } else if (index === 2) {
        return {
          backgroundColor: "rgb(255, 177, 6)",
          display: "inline-block",
          textAlign: "center",
          color: "#ffffff",
          width: "1.5625rem",
          height: "1.0625rem",
          lineHeight: "1.0625rem",
          borderRadius: "0.125rem",
          marginRight: "0.125rem",
          marginTop: "10px",
        };
      } else {
        return {
          display: "inline-block",
          marginTop: "10px",
          color: "#000"
        };
      }
    },
    scrollToBottom() {
      // 获取 chatRecord 元素的引用
      const chatRecord = this.$refs.chatRecord;

      // 使用 $nextTick 等待 DOM 更新
      this.$nextTick(() => {
        // 执行动画，滚动到 chatRecord 元素的底部
        chatRecord.scrollTop = chatRecord.scrollHeight;
      });
    },
    btnSend() {
      if (this.q === "") return;
      this.questionList.push(this.q);
      request
        .post("/api/QA/GetQuestion", { keyword: this.q })
        .then((response) => {
          // 请求成功时处理数据
          this.answerList.push(response.data.Data);
          this.answerListFlag.push(false);
          // console.log(this.answerList);
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
      this.q = "";
    },

    handleKeyPress(e) {
      if ((e.ctrlKey && e.which == 13) || e.which == 10 || e.which == 13) {
        this.btnSend();
        e.preventDefault(); // 屏蔽 enter 对系统作用，按后增加 \r\n 等换行
        this.q = "";
      }
    },

    handleItemClick(event) {
      const target = event.target.closest(".item");
      if (!target) return; // 如果点击的不是 item 元素，则退出

      const id = target.querySelector("input:first-of-type").value;
      const q = target.querySelector("input:last-of-type").value;
      this.showDetail(id, q);
    },

    showDetail(_id, q) {
      // console.log(_id, q);
      this.questionList.push(q);
      request
        .post("/api/QA/GetAnswer", { id: _id })
        .then((response) => {
          // 请求成功时处理数据
          this.answerList.push(response.data.Data);
          this.answerListFlag.push(true);
          // console.log(this.answerList);
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },
    handleListClick(item) {
      var id = item.id;
      var q = item.content;
      this.showDetail(id, q);
    },
    handleGuessClick(event) {
      const targetDiv = event.target.closest("div");
      if (!targetDiv) return; // 如果点击的不是 div 元素，则返回

      const textContent = targetDiv.textContent.trim();

      const txtKeyword = document.getElementById("txtKeyword");
      const btnSend = document.getElementById("btnSend");

      this.q = textContent;

      this.btnSend();
    },
    handleOptionsClick() {
      const keywordDivs = document.querySelectorAll("div.qa-keyword");

      keywordDivs.forEach((div) => {
        div.addEventListener("click", () => {
          // 获取点击的文本内容
          const textContent = div.textContent.trim();
          this.q = textContent;
          this.btnSend();
        });
      });
    },
  },
};
</script>

<style scoped>
@keyframes load {
  0% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(0.3);
    opacity: 0.5;
  }
}

@font-face {
  font-family: "element-icons";
  src: url(../../fonts/element-icons.313f7da.woff) format("woff")
    url(../../fonts/element-icons.4520188.ttf) format("truetype");
  font-weight: normal;
  font-display: "auto";
  font-style: normal;
}

.layout-default {
  min-width: 64.375rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: linear-gradient(180deg, rgba(34, 97, 201, 0.5) 0%, #fff 75%);
  overflow: hidden;
  overflow-y: auto;
}

.layout-default > * {
  flex-shrink: 0;
}

.layout-default__breadcrumb {
  width: 64.375rem;
  margin: 0 auto;
  padding: 1.25rem 0;
}

.layout-default__main {
  margin-bottom: 3.125rem;
  flex-grow: 1;
}

.layout-default__main ::before {
  content: "";
  display: block;
}

.layout-default.is-home {
  background: transparent;
}

.layout-default.is-home .layout-default__main {
  margin-top: -5.9375rem;
  width: 100%;
}
.base-header-wrapper .base-header.is-home {
  position: relative;
  background-color: transparent;
  box-shadow: unset;
  top: unset;
  left: unset;
}

.base-header-wrapper .base-header.is-home .base-header__main .logo-box ::after {
  content: "";
  background: transparent;
}

.base-header-wrapper
  .base-header.is-home
  .base-header__main
  .menu-box__content {
  justify-content: flex-end;
}

.base-header-wrapper
  .base-header.is-home
  .base-header__main
  .menu-box__item.loginReg,
.base-header-wrapper
  .base-header.is-home
  .base-header__main
  .menu-box
  .el-dropdown-link {
  color: #fff;
}

.ai-qa-page-main {
  border-radius: 0.9375rem;
  overflow: hidden;
}

.ai-qa-page-main .ai-qa-content {
  min-height: 31.25rem;
  display: flex;
  position: relative;
}

.ai-qa-page-main .ai-qa-content .close-btn {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
}

.ai-qa-page-main .ai-qa-content__chat {
  width: 53.125rem;
  background: #f2f4f5;
  padding: 1.25rem 0;
}

.ai-qa-page-main .ai-qa-content__chat .ai-qa-current-date {
  text-align: center;
}

.ai-qa-page-main .ai-qa-content__chat .ai-qa-customer__container img {
  width: 4.125rem;
  z-index: 99;
}

.ai-qa-page-main .ai-qa-content__chat .ai-qa-customer__container .item-text {
  z-index: 99;
}

.ai-qa-page-main
  .ai-qa-content__chat
  .ai-qa-customer__container
  .customer-info {
  position: absolute;
  bottom: 0;
  height: 78%;
  width: 100%;
  background: #ffffff;
  border-radius: 0.625rem;
  z-index: 0;
}

.ai-qa-page-main .ai-qa-content__chat .chat-record {
  height: 26.875rem;
  overflow-y: scroll;
  padding: 0 1.25rem;
}

.ai-qa-page-main .ai-qa-content__chat .chat-record ::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}

.ai-qa-page-main .ai-qa-content__chat .chat-record ::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.1);
}

.ai-qa-page-main .ai-qa-content__chat .chat-record ::-webkit-scrollbar-track {
  border-radius: 0.625rem;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key {
  margin: 0 5rem;
  display: flex;
  justify-content: space-around;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key .guess-you {
  width: 185px;
  height: 90px;
  background-color: #ffffff;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  padding: 0.625rem 0.3125rem 0.625rem 1.875rem;
  position: relative;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key .guess-you__title {
  font-size: 1.125rem;
  font-weight: 550;
  letter-spacing: 0.1875rem;
}

.ai-qa-page-main
  .ai-qa-content__chat
  .qa-shortcut-key
  .guess-you__title
  .text-special {
  color: #e6af6d;
  font-weight: 550;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key .guess-you__content {
  flex: 1;
  height: 100%;
  margin-left: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ai-qa-page-main
  .ai-qa-content__chat
  .qa-shortcut-key
  .guess-you__content
  .guess-item {
  border-bottom: 0.0625rem solid #eeeeee;
  padding: 0.3125rem 0;
  cursor: pointer;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key .guess-you__toggle {
  position: absolute;
  top: 0;
  right: 0.3125rem;
  cursor: pointer;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key .guess-you__toggle .go {
  transform: rotate(360deg);
  transition: all 0.5s;
}

.ai-qa-page-main .ai-qa-content__chat .send-box {
  padding: 0.9375rem 0.9375rem 0;
  border-top: 0.0625rem solid #dcdfe6;
}

.ai-qa-page-main .ai-qa-content__tool {
  flex: 1;
  overflow: hidden;
  padding-top: 1.875rem;
}

.ai-qa-page-main .ai-qa-content__tool .tool-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.ai-qa-page-main .ai-qa-content__tool .common-problem__list {
  padding: 1.25rem 0.9375rem;
}

.ai-qa-page-main
  .ai-qa-content__tool
  .common-problem__list
  .common-problem-item {
  font-size: 1rem;
  color: #333333;
  margin-bottom: 1.25rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ai-qa-page-main
  .ai-qa-content__tool
  .common-problem__list
  .common-problem-item
  .item-seq {
  display: inline-block;
  text-align: center;
  color: #ffffff;
  width: 1.5625rem;
  height: 1.0625rem;
  line-height: 1.0625rem;
  border-radius: 0.125rem;
  margin-right: 0.125rem;
}

.ai-qa-page-main
  .ai-qa-content__tool
  .common-problem__list
  .common-problem-more {
  color: #999999;
  text-align: center;
  cursor: pointer;
}

.ai-qa-page-main .ai-qa-content__tool .contact-information-item {
  font-size: 1rem;
  color: #333333;
  padding: 0 0.9375rem;
  text-align: center;
}

.ai-qa-page-main .ai-qa-content__tool .contact-information-item ::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAydJREFUWEftll+IVFUcxz/fmTQffAkDJVYIkh6qh2QxBEHvuBC0L20PDVqaO3e2bV9KKUKIqPUlJaSsWMO1uWtggUYJESwF2z1TiIYGRWhIEkRKECGBQWXsfGOmnWHbdp1ZZ0dfuo/3nnO+n/P9/bviBj+6wfr8D9CyA9FBRyzhQtiq8wsZtqYAUclFxPPA7cB4iNV73QB6Et81CWcwuyTGDScRO0JBry0UxJwOrE+8MiPGZc6msfJVwVziV2z6Fi9i7SeP6eeFgJgTIEr8BiIXCrqnLhQddhdXOIU5EIoa7jTAeczhmUK5xC8B2/5YyqoTef3eLsTVHDCT5MLjCtNFekZ95+RNnBPsTGO9fN0BqoIbEh8RrAuxujoHUPKPyrA7LWj/TJFaT8iSZuHuiVhn24GYMwS5xEctJkNBm/8DMOZXMTsmF3Hb51v1U6cABg37K2L1ZwV906iEt9xHhmOC3Wms59oRr+69aieMEp+2KJcLeqYBkDgFlrOU+0Jev3UUIJd40GZPxqz+dEA/VMWikt+0uD9jetOiznUUoCaY+DTmo3o/iMZ8L+YEcCrEWt9xgA2JNwveBZ4NsfZWBTcm3liBCcRwKGhXOxBNp2HNhTFvx+yzyJcLem8qFMOIF4EDIdZQI0dK7kdsw5QrGd6fnsCzgbYEUBtEJb9u8SSLWRm26EKtIZX8kESCOSkxbDGE6a+ObeBWYI3hQ8EHIdbbbQFM3fo7xKqbKyz7eECXauE45DsqZi+mD/i1OrpDUftq0IkftNiE2QR8KRhNY41OB2nZgWkWX0LcIlibxvqi8X7MK4AVoaCvZt40d8jdVHjU8AjwrcxQvYLmDdCoDOjGbAlFvTOfJIwS/2vIXRPAlL1HDQ9XK4ErjIQn9EszkNoPDfT+ZdYcL+py007Y7MAo8R5gp+B7xMiSy4yMP6U/Z9sX/dNBu1XhgXRAx+trrtmB+gHV+FYqDAoGga8NRzLmIlkupv2aqFVL4oLgBZuny0UdaysJ53KlAWJ6EV2IM1mTbzau23ZgNqCeg15OlmXNxNvOgWY50sr3jjjQivCCJeF8xGZb+zeQ9z4w7hmydwAAAABJRU5ErkJggg==);
  vertical-align: middle;
  margin-right: 0.3125rem;
}
.ai-qa-page-main {
  border-radius: 0.9375rem;
  overflow: hidden;
}

.ai-qa-page-main .ai-qa-content {
  min-height: 31.25rem;
  display: flex;
  position: relative;
}

.ai-qa-page-main .ai-qa-content__chat {
  width: 53.125rem;
  background: #f2f4f5;
  padding: 1.25rem 0;
}

.ai-qa-page-main .ai-qa-content__chat .ai-qa-customer .flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.ai-qa-page-main .ai-qa-content__chat .ai-qa-customer__container {
  position: relative;
  width: 17.5rem;
  height: 9.0625rem;
  margin: 1.25rem auto;
}

.ai-qa-page-main .ai-qa-content__chat .qa-shortcut-key .qa-keyword {
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 0.625rem;
  padding: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.ai-qa-page-main .ai-qa-content__tool {
  flex: 1;
  overflow: hidden;
  padding-top: 1.875rem;
}

.ai-qa-page-main .ai-qa-content__tool .tool-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.ai-qa-page-main .ai-qa-content__tool .common-problem__list {
  padding: 1.25rem 0.9375rem;
}

.ai-qa-page-main
  .ai-qa-content__tool
  .common-problem__list
  .common-problem-item {
  font-size: 1rem;
  color: #333333;
  margin-bottom: 1.25rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ai-qa-page-main
  .ai-qa-content__tool
  .common-problem__list
  .common-problem-item
  .item-seq {
  display: inline-block;
  text-align: center;
  color: #ffffff;
  width: 1.5625rem;
  height: 1.0625rem;
  line-height: 1.0625rem;
  border-radius: 0.125rem;
  margin-right: 0.125rem;
}

.ai-qa-page-main
  .ai-qa-content__tool
  .common-problem__list
  .common-problem-more {
  color: #999999;
  text-align: center;
  cursor: pointer;
}

.ai-qa-page-main .ai-qa-content__tool .contact-information-item {
  font-size: 1rem;
  color: #333333;
  padding: 0 0.9375rem;
  text-align: center;
}

.ai-qa-page-main .ai-qa-content__tool .contact-information-item ::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAydJREFUWEftll+IVFUcxz/fmTQffAkDJVYIkh6qh2QxBEHvuBC0L20PDVqaO3e2bV9KKUKIqPUlJaSsWMO1uWtggUYJESwF2z1TiIYGRWhIEkRKECGBQWXsfGOmnWHbdp1ZZ0dfuo/3nnO+n/P9/bviBj+6wfr8D9CyA9FBRyzhQtiq8wsZtqYAUclFxPPA7cB4iNV73QB6Et81CWcwuyTGDScRO0JBry0UxJwOrE+8MiPGZc6msfJVwVziV2z6Fi9i7SeP6eeFgJgTIEr8BiIXCrqnLhQddhdXOIU5EIoa7jTAeczhmUK5xC8B2/5YyqoTef3eLsTVHDCT5MLjCtNFekZ95+RNnBPsTGO9fN0BqoIbEh8RrAuxujoHUPKPyrA7LWj/TJFaT8iSZuHuiVhn24GYMwS5xEctJkNBm/8DMOZXMTsmF3Hb51v1U6cABg37K2L1ZwV906iEt9xHhmOC3Wms59oRr+69aieMEp+2KJcLeqYBkDgFlrOU+0Jev3UUIJd40GZPxqz+dEA/VMWikt+0uD9jetOiznUUoCaY+DTmo3o/iMZ8L+YEcCrEWt9xgA2JNwveBZ4NsfZWBTcm3liBCcRwKGhXOxBNp2HNhTFvx+yzyJcLem8qFMOIF4EDIdZQI0dK7kdsw5QrGd6fnsCzgbYEUBtEJb9u8SSLWRm26EKtIZX8kESCOSkxbDGE6a+ObeBWYI3hQ8EHIdbbbQFM3fo7xKqbKyz7eECXauE45DsqZi+mD/i1OrpDUftq0IkftNiE2QR8KRhNY41OB2nZgWkWX0LcIlibxvqi8X7MK4AVoaCvZt40d8jdVHjU8AjwrcxQvYLmDdCoDOjGbAlFvTOfJIwS/2vIXRPAlL1HDQ9XK4ErjIQn9EszkNoPDfT+ZdYcL+py007Y7MAo8R5gp+B7xMiSy4yMP6U/Z9sX/dNBu1XhgXRAx+trrtmB+gHV+FYqDAoGga8NRzLmIlkupv2aqFVL4oLgBZuny0UdaysJ53KlAWJ6EV2IM1mTbzau23ZgNqCeg15OlmXNxNvOgWY50sr3jjjQivCCJeF8xGZb+zeQ9z4w7hmydwAAAABJRU5ErkJggg==);
  vertical-align: middle;
  margin-right: 0.3125rem;
}
.username {
  font-size: 0.875rem;
  color: #666;
  line-height: 1.5625rem;
}
.text-box {
  font-size: 0.875rem;
  margin-top: 0.3125rem;
  display: inline-block;
  padding: 0.4375rem 1.125rem;
  line-height: 1.75rem;
  overflow: hidden;
  background: #f8f8f8;
  border-radius: 0 0.625rem 0.625rem 0.625rem;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
}

.right-text-box {
  background: #0066d9;
  border-radius: 0.625rem 0 0.25rem 0.25rem;
}
.message-wrapper {
  margin: 1.25rem 0;
}
.message-wrapper .content-wrapper {
  display: flex;
  align-items: center;
}
.group-layout.position-right,
.c2c-layout.position-right,
.system-layout.position-right {
  flex-direction: row-reverse;
}

.group-layout.position-right .col-2,
.c2c-layout.position-right .col-2,
.system-layout.position-right .col-2 {
  align-items: flex-end;
}

.group-layout,
.c2c-layout,
.system-layout {
  display: flex;
}

.group-layout .col-1 img,
.c2c-layout .col-1 img,
.system-layout .col-1 img {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
}

.group-layout .group-member-avatar,
.c2c-layout .group-member-avatar,
.system-layout .group-member-avatar {
  cursor: pointer;
}

.el-avatar {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background: #c0c4cc;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.875rem;
}

.el-avatar > img {
  display: block;
  height: 100%;
  vertical-align: middle;
}

.el-avatar--circle {
  border-radius: 50%;
}

.el-avatar--square {
  border-radius: 0.25rem;
}

.el-avatar--icon {
  font-size: 1.125rem;
}

.el-avatar--large {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.el-avatar--medium {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.el-avatar--small {
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
}

.group-layout .col-2,
.c2c-layout .col-2,
.system-layout .col-2 {
  display: flex;
  flex-direction: column;
}

.group-layout .col-3,
.c2c-layout .col-3,
.system-layout .col-3 {
  width: 1.875rem;
}

.group-layout.position-center,
.c2c-layout.position-center,
.system-layout.position-center {
  justify-content: center;
}

.c2c-layout .col-2 .base {
  margin-top: 0.1875rem;
}
.el-col-1 {
  width: 4.16667%;
}
.group-layout .col-1 img,
.c2c-layout .col-1 img,
.system-layout .col-1 img {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
}
.el-col-2 {
  width: 8.33333%;
}
.group-layout .col-2,
.c2c-layout .col-2,
.system-layout .col-2 {
  display: flex;
  flex-direction: column;
}
.group-layout.position-left .col-2,
.c2c-layout.position-left .col-2,
.system-layout.position-left .col-2 {
  align-items: flex-start;
}

.c2c-layout .col-2 .base {
  margin-top: 0.1875rem;
}

.group-layout .col-2 .chat-bubble {
  margin-top: 0.3125rem;
  outline: none;
}
.chat-bubble .message-status {
  display: flex;
  min-width: 1.5625rem;
  margin-right: 0.625rem;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  color: #6e7981;
}

.chat-bubble .message-content {
  outline: none;
  font-size: 0.875rem;
  position: relative;
  max-width: 37.5rem;
  word-wrap: break-word;
  word-break: break-all;
}

.chat-bubble .message-content span {
  white-space: pre-wrap;
  margin: 0;
}

.chat-bubble .message-content img {
  vertical-align: bottom;
}

.chat-bubble .message-received {
  margin-left: 0.9375rem;
  border-radius: 0 0.25rem 0.25rem 0.25rem;
}

.chat-bubble .message-received ::before {
  left: -0.625rem;
  transform: scaleX(-1);
  color: #fff;
}

.chat-bubble .message-received.new {
  transform: scale(0);
  transform-origin: top left;
  animation: bounce-data-v-c8264dbe 500ms linear both;
}

.chat-bubble .message-send {
  margin-right: 0.9375rem;
  border-radius: 0.25rem 0 0.25rem 0.25rem;
  color: #fff;
}

.chat-bubble .message-send ::before {
  right: -0.625rem;
  color: #5cadff;
}

.chat-bubble .message-send.new {
  transform: scale(0);
  transform-origin: top right;
  animation: bounce-data-v-c8264dbe 500ms linear both;
}

.chat-bubble .el-dropdown {
  vertical-align: top;
  display: flex;
  outline: none;
  border: none;
}

.chat-bubble .el-dropdown .focusing {
  outline: none;
  border: none;
}

.chat-bubble .el-dropdown + .el-dropdown {
  margin-left: 0.9375rem;
}

.chat-bubble .el-icon-arrow-down {
  font-size: 0.75rem;
}

.showMore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showMore .open {
  color: #999999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showMore img {
  margin-left: 0.5rem;
  width: 0.625rem;
  height: 0.3125rem;
}

.showMore .img {
  transform: rotate(180deg);
}

.el-icon-question:before {
  content: "\e7a4";
}

.question {
  width: 100%;
  padding: 0 0.625rem 0;
  overflow: hidden;
}

.question .item {
  line-height: 1.875rem;
  margin: 0.5rem 0;
  color: #0066d9;
  cursor: pointer;
}

.send-content-container {
  background: transparent;
}

.send-content-container textarea {
  resize: none;
}

.send-content-container .send-btn {
  text-align: right;
  margin-top: 0.9375rem;
}

.el-textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 0.875rem;
}

.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 0.3125rem 0.9375rem;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 0.0625rem solid #dcdfe6;
  border-radius: 0.25rem;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.el-textarea__inner::placeholder {
  color: #c0c4cc;
}

.el-textarea__inner:hover {
  border-color: #c0c4cc;
}

.el-textarea__inner:focus {
  outline: none;
  border-color: #028dfb;
}

.el-textarea.is-disabled .el-textarea__inner {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}

.el-textarea.is-disabled .el-textarea__inner::placeholder {
  color: #c0c4cc;
}

.el-textarea.is-exceed .el-textarea__inner {
  border-color: #f56c6c;
}

.el-textarea.is-disabled .el-textarea__inner {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}

.el-textarea.is-disabled .el-textarea__inner::placeholder {
  color: #c0c4cc;
}

.el-textarea.is-exceed .el-textarea__inner {
  border-color: #f56c6c;
}
</style>