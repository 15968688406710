<template>
  <div class="page">
    <div class="policy-box" :style="bgcStyle[this.index]" v-if="policy">
      <div class="title">{{ policy.Title }}</div>
      <div class="little">
        <div v-for="item in policy.Children" :key="item.Id" class="policy-detail-box">
          <div class="detail-title">
            <span style="display: inline-block; padding-top: 30px">{{
              item.Title
            }}</span>
          </div>
          <el-table :data="item.Children" stripe style="width: 100%; overflow: hidden" max-height="280">
            <el-table-column prop="Title" label="项目名称" min-width="75%">
            </el-table-column>
            <el-table-column prop="Amount" label="奖励金额" min-width="25%">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import request from "@/api/request";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      title: "",
      policy: null,
      bgcStyle: [
        "background: linear-gradient(to bottom, #2e8ff2, #57bff8)",
        "background: linear-gradient(to bottom, #2DB3F1, #63DEF2)",
        "background: linear-gradient(to bottom, #2AC983, #53E5D1)",
      ],
      index: null,
    };
  },
  created() {
    this.initAllList();
  },
  methods: {
    initAllList() {
      const id = this.$route.query.id;
      const index = this.$route.query.index;
      this.index = index;
      request
        .post("/api/Policy/GetTrial", { id: id })
        .then((response) => {
          // console.log(response)
          // 请求成功时处理数据
          this.policy = response.data.Data;
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
}

.policy-box {
  width: 80%;
  border-radius: 30px;
  margin: 20px auto 20px auto;
  /* background: linear-gradient(to bottom, #2e8ff2, #57bff8); */
}

.little {
  display: flex;
  /* 将 .little 元素设置为 flex 容器 */
  flex-wrap: wrap;
  /* 允许子元素换行排列 */
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: calc(10% - 37.5px);
  padding-right: 20px;
}

.policy-detail-box {
  width: 30%;
  height: 360px;
  border-radius: 20px;
  background: white;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* 添加阴影效果 */
  overflow: hidden;
  position: relative;
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.title {
  font-size: 40px;
  color: white;
  text-align: center;
  /* 文字居中 */
  padding-top: 20px;
}

.detail-title {
  background: #d5efff;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  color: #2d8ef1;
  text-align: center;
}

/* .el-table--scrollable-y ::-webkit-scrollbar {
  display: none; 
} */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 0px;
  /*滚动条的背景区域的圆角*/
  background-color: white;
  /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: #e3e3e3;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /*滚动条的圆角*/
  background-color: #8b8b8b;
  /*滚动条的背景颜色*/
}</style>
