<template>
    <div>
        <demandsHeader></demandsHeader>
        <professionalsBanner></professionalsBanner>
        <RouterView />
    </div>
</template>
<script>
import demandsHeader from "@/components/demandsHeader";
import professionalsBanner from "@/view/PolicyCalculation/components/pageBanner";
import { RouterView } from "vue-router";
export default {
    name: "PolicyCalculation",
    components: {
        'demandsHeader': demandsHeader,
        'professionalsBanner': professionalsBanner,
    },

}
</script>