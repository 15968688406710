<template>
  <div>
    <div>
      <div class="content">
        <div>
          <div>
            <div>
              <template>
                <div>
                  <p v-html="content"></p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>

<script>
import request from '@/api/request';
import siteFooter from "@/components/siteFooter";

export default {
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      content: '',
    };
  },
  created() {
    this.actuarialList(this.$route.params.id)
  },
  methods: {
    async actuarialList(routerId) {
      request.post("/api/Project/GetPublicDetail",
        {
          id: this.$route.params.id,
        }
      )
        .then((result) => {
          // console.log(result)
          this.content = result.data.Data.content;
        })
    },
  },
};
</script>
<style>
.content {
  background-color: #ffffff;
  width: 700px;
  margin: 20px auto;
  padding: 30px 20px;
  z-index: 99;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
</style>