<template>
    <div v-if="visible" class="float-window" @mouseover="clearFdAd" @mouseout="starFdAd" :style="{
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`
    }">
        <div class="float-window-close" @click.prevent="close">
            <i class="el-icon-circle-close" style="font-size: 20px;color: #ffffff;"></i>
        </div>
        <div @click="baywindowsViewDetails">
            <img :src="image" :bayid="bayid" :bayzd="bayzd" :baytype="baytype" alt="">
            <div class="baywindowstitle">{{bayzd}}</div>
        </div>
    </div>
</template>
   
<script>
export default {
    name: 'bayWindow',
    props: {
        image: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        speed: {
            type: Number,
            default: 1000 / 60
        },
        bayid:{
            type: String,
            required: true
        },
        bayzd:{
            type: String,
            required: true
        },
        baytype:{
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            top: 0,
            left: 0,
            maxX: 0,
            maxY: 0,
            directionX: -1,
            directionY: -1
        }
    },
    mounted() {
        this.setup()
        this.top = window.innerHeight - this.height
        this.left = window.innerWidth - this.width
        this.visible = true
        window.addEventListener('resize', this.setup)
        this.start()
    },
    destroyed() {
        this.close()
    },
    methods: {
        baywindowsViewDetails(){
            // console.log(this.$props.baytype)
            if(this.$props.baytype==7){
                this.$router.push("/PolicyCalculation/PolicyDetails/" + this.$props.bayid);
            }else if(this.$props.baytype==9){
                this.$router.push("/PolicyCalculation/policyInterpretationDeteils/" + this.$props.bayid);
            }
        },
        starFdAd() {
            this.timer = setInterval(this.move, this.speed)
        },
        clearFdAd() {
            clearInterval(this.timer)
        },
        /**
         * 配置
         */
        setup() {
            this.maxX = window.innerWidth - this.width // X轴边界
            this.maxY = window.innerHeight - this.height // Y轴边界
        },
        /**
         * 开始移动
         */
        start() {
            this.timer = setInterval(this.move, this.speed)
        },
        /**
         * 停止移动
         */
        stop() {
            clearInterval(this.timer)
        },
        /**
         * 移动飘窗
         */
        move() {
            const rect = this.$el.getBoundingClientRect()
            if (rect.x >= this.maxX) {
                this.directionX = -1
            } else if (rect.x <= 0) {
                this.directionX = 1
            }
            if (rect.y >= this.maxY) {
                this.directionY = -1
            } else if (rect.y <= 0) {
                this.directionY = 1
            }
            this.left += this.directionX
            this.top += this.directionY
        },
        /**
         * 关闭飘窗
         */
        close() {
            this.visible = false
            window.removeEventListener('resize', this.setup)
            clearInterval(this.timer)
        }
    }
}
</script>
   
<style scoped>
.float-window {
    display: block;
    transform: translateZ(0);
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999999999999999;
}

.float-window-close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
}


img {
    width: 100%;
    display: block;
}

.baywindowstitle{
    position: absolute;
    top: 39px;
    width: 217px;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>