<template>
    <div>
        <demandsHeader></demandsHeader>
        <pageBanner></pageBanner>
        <div class="content">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="诉求标题" label-width="155px" class="formitem" align-items="center" prop="title">
                    <el-input class="el-select_input" v-model="form.title" placeholder="请输入诉求标题"></el-input>
                </el-form-item>
                <el-form-item label="诉求内容" label-width="155px" class="formitem" align-items="center" prop="content">
                    <el-input type="textarea" class="el-select_input" v-model="form.content"
                        style="background-color: #F5F5F5;" :rows="5" placeholder="请输入诉求内容"></el-input>
                </el-form-item>
                <el-form-item label="诉求上报人" label-width="155px" class="formitem" align-items="center" prop="name">
                    <el-input class="el-select_input" placeholder="请输入诉求上报人" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="上报人联系手机" label-width="155px" class="formitem" align-items="center" prop="mobile">
                    <el-input class="el-select_input" placeholder="请输入上报人联系手机" v-model="form.mobile"></el-input>
                </el-form-item>
                <el-form-item label="短信验证码" label-width="155px" prop="Code" class="formitem">
                    <el-input class="el-select_input" style="width: 100%; margin-left: 0px;" placeholder="短信验证码" v-model="form.Code"></el-input>
                    <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                        <span v-show="show">获取验证码</span>
                        <span v-show="!show" class="count">{{ count }} s</span>
                    </button>
                </el-form-item>
                <el-button type="primary" style="font-size: 16px; padding: 15px 80px;" :disabled="isdisabled"
                    @click="SubmitAClaim">提交诉求</el-button>
            </el-form>
        </div>
    </div>
</template>
<script>
import request from '@/api/request'
import demandsHeader from "@/components/demandsHeader";
import pageBanner from "@/view/demands/components/pageBanner";
export default {
    name: "demandsAdd",
    components: {
        'demandsHeader': demandsHeader,
        'pageBanner': pageBanner,
    },
    data() {
        return {
            form: {
                // demandEnterprise: '',
                // sclaimtitleubdistricttown: '',
                title: '',
                content: '',
                name: '',
                mobile: '',
                Code: ''
            },
            count: 0,
            show: true,
            isdisabled: false,
            rules: {
                title: [{
                    required: true,
                    message: '请输入诉求标题',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请输入诉求内容',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: '请输入诉求上报人',
                    trigger: 'blur'
                }],
                mobile: [{
                    required: true,
                    message: '请输入上报人联系手机',
                    trigger: 'blur'
                }, {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    message: "请输入正确的手机号码",
                    trigger: "blur"
                }],
                Code: [{
                    required: true,
                    message: '请输入短信验证码',
                    trigger: 'blur'
                }],
            }
        }
    },
    created() {
        if (!localStorage.getItem("USERID")) {
            this.$message({
                message: '请先登录系统',
                type: "warning",
            });
            this.$router.push('/login');
        } else if (localStorage.getItem("QYID") == 0) {
            this.$confirm('请先关联企业', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
            }).then(() => {
                this.$router.push('/personalcenter/0');
            }).catch(() => {
                this.$router.push('/');
            });
        } else if (localStorage.getItem('demandstimecount')) {
            this.count = localStorage.getItem('demandstimecount');
            this.show = false;
            this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 60) {
                    this.count--;
                    localStorage.setItem('demandstimecount', this.count);
                } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                    localStorage.removeItem('demandstimecount');
                }
            }, 1000);
        }
    },
    methods: {
        SubmitAClaim() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.isdisabled = true;
                    let url = "/api/Demands/Apply";
                    let reqObj = this.form;
                    request.post(url, reqObj).then((res) => {
                        // console.log(res);
                        this.isdisabled = false;
                        if (res.data.Status == 1) {
                            this.$confirm('提交成功', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'success'
                            }).then(() => {
                                this.$router.push('/personalcenter/5');
                            }).catch(() => {
                                location.reload();
                            });
                        }
                        else {
                            return this.$confirm('提交失败，请重新提交');
                        }
                    });
                } else {
                    return this.$confirm('请完善信息后提交诉求');
                }
            })
        },
        // 获取短信验证码
        getCode() {
            //axios请求
            // console.log(this.form.mobile);
            // 验证码倒计时
            if (!this.form.mobile||!(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/).test(this.form.mobile)) {
                this.$message({
                    message: '手机号无效',
                    type: "warning",
                });
            } else if (!this.timer) {
                this.count = 60;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= 60) {
                        this.count--;
                        localStorage.setItem('demandstimecount', this.count);
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        localStorage.removeItem('demandstimecount', this.count);
                    }
                }, 1000);
                this.getCodeNumber(this.form.mobile);
            }
        },
        getCodeNumber(phone) {
            request
                .post("/api/Sms/Send", {
                    "keyword": phone
                })
                .then((res) => {
                    // console.log(res)
                })
                .catch((error) => {
                    // 请求失败，处理错误
                    console.error("Error fetching data:", error);
                })
        },
    }
}
</script>
<style scoped>
@import url('../../../public/layui/css/layui.css');

* {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.content {
    background-color: #ffffff;
    width: 700px;
    margin: 20px auto;
    padding: 30px 20px;
    z-index: 99;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.formitem {
    margin: 20px 0px;
    font-size: 17px;
    color: #606266;
    text-align: left;
    justify-content: space-between;
}

/deep/ .el-input__inner {
    background-color: #F5F5F5;
}

/deep/ .el-textarea__inner {
    background-color: #F5F5F5;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
</style>